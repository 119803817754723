import {AuthenticationContext} from "./context";
import React, {useState} from "react";
import {getAuthenticationStorage, updateAuthenticationStorage} from "./helper";
import moment from "moment";
import {apiRefreshUser} from "../../api/user/api";
import {useAppContext} from "../app/context";

export const AuthenticationProvider: React.FC<any> = ({children}) => {
  const authenticationStorage = getAuthenticationStorage();
  const appContext = useAppContext();
  const [accessToken, setAccessToken] = useState(authenticationStorage.accessToken as string|undefined);
  const [isAuthenticated, setIsAuthenticated] = useState(
    (accessToken && ((authenticationStorage.tokenExpireDate?.unix() ?? 0) - moment.now()) > 0) as boolean
  );

  const forceReauthenticate = (): void => {
    setIsAuthenticated(false);
  };

  const getAccessToken = async(): Promise<string | undefined> => {
    if (
      (!accessToken || ((authenticationStorage.tokenExpireDate?.unix() ?? 0) - moment.now()) < 0) &&
      authenticationStorage.refreshToken
    ) {
      if (appContext?.isLoading) {
        return undefined;
      }

      appContext?.setIsLoading(true);
      await apiRefreshUser({refresh_token: authenticationStorage.refreshToken}).then((result) => {
        updateAuthenticationStorage(result.access_token, moment.unix(moment.now()).add(
          {seconds: result.expires_in - 30}
        ), result.refresh_token);

        setAccessToken(result.access_token);
        if (!isAuthenticated) {
          setIsAuthenticated(true);
        }
        appContext?.setIsLoading(false);
      }).catch(() => {
        setIsAuthenticated(false);
        appContext?.setIsLoading(false);
      });
    }

    if (accessToken && !isAuthenticated) {
      setIsAuthenticated(true);
    } else if (!accessToken && isAuthenticated) {
      setIsAuthenticated(false);
    }

    return accessToken;
  };

  return (
    <AuthenticationContext.Provider value={{
      isAuthenticated,
      getAccessToken,
      forceReauthenticate
    }}>
      {children}
    </AuthenticationContext.Provider>
  );
}