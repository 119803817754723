import React, {useEffect, useState} from 'react';
import {useAppContext, useConfirmDialogContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import AddCircle from '@mui/icons-material/AddCircle';
import {
  IconButton,
  MenuItem,
} from '@mui/material';
import moment from "moment/moment";
import {MenuButton} from "../../components/layout/MenuButton";
import {LoadingRow} from "../../components/layout/LoadingRow";
import {ErrorHelper} from "../../helper/errors";
import {toast} from "react-toastify";
import {ServiceInterface} from "../../services/service/interface";
import {apiDeleteService, apiGetServices} from "../../api/services/api";
import {CreateServiceDialog} from "./CreateServiceDialog";
import {EditServiceDialog} from "./EditServiceDialog";
import {MenuItemLink} from "../../components/layout/MenuItemLink";
import {Table, TableBody, TableCell, TableRow, TableHead} from "../../components/layout/Table";

export default function ServiceScene() {
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [currentEditService, setCurrentEditService] = useState<ServiceInterface|null>(null);
  const services = useQuery(['services'], apiGetServices);
  const [openServiceCreate, setOpenServiceCreate] = useState(false);
  const [openServiceEdit, setOpenServiceEdit] = useState(false);
  const confirmDialog = useConfirmDialogContext();

  useEffect(() => {
    appContext?.setTitle(t("SERVICES"));
  }, [appContext, t]);

  const handleEdit = (row: ServiceInterface) => {
    setCurrentEditService(row);
    setOpenServiceEdit(true);
  };

  const handleDelete = (row: ServiceInterface) => {
    confirmDialog?.confirm(
      t("DELETE_SERVICE_QUESTION"),
      t("DELETE_SERVICE_QUESTION_EXPLANATION"),
      () => () => {
        apiDeleteService({id: row.id}).then(() => {
          toast.success(t("SERVICE_DELETED"));
          services.refetch();
        }).catch((result) => {
          ErrorHelper.toastLaraFail(result);
        })
      }
    );
  };

  return (
    <>
      <h1>
        {t("SERVICES")}
        <IconButton aria-label="create" color="success" onClick={() => {setOpenServiceCreate(true)}}>
          <AddCircle />
        </IconButton>
      </h1>
      <Table sx={{ minWidth: 650 }} aria-label="service table">
        <TableHead>
          <TableRow>
            <TableCell>{t("NAME")}</TableCell>
            <TableCell align="right">{t("TYPE")}</TableCell>
            <TableCell align="right">{t("CHARGE_INTERVAL")}</TableCell>
            <TableCell align="right">{t("BASE_PRICE")}</TableCell>
            <TableCell align="right">{t("PRICE_INCL_TAX")}</TableCell>
            <TableCell align="right">{t("TAX_PERCENTAGE")}</TableCell>
            <TableCell align="right">{t("CREATED_AT")}</TableCell>
            <TableCell align="right">{t("UPDATED_AT")}</TableCell>
            <TableCell align="right">{t("ACTIONS")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.isLoading ? (
            <LoadingRow colspan={7} />
          ) : (services.data?.items.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="right">{row.type}</TableCell>
              <TableCell align="right">{t(row.charge_interval.toUpperCase())}</TableCell>
              <TableCell align="right">€{row.base_price}</TableCell>
              <TableCell align="right">{row.price_incl_tax ? t("YES") : t("NO")}</TableCell>
              <TableCell align="right">{row.tax_percentage}%</TableCell>
              <TableCell align="right">{moment.unix(row.created_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">{moment.unix(row.updated_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">
                <MenuButton>
                  <MenuItemLink key={'services-' + row.id} to={"/services/" + row.id + "/variables"}>
                    {t("VARIABLES")}
                  </MenuItemLink>
                  <MenuItem key={'edit-' + row.id} onClick={() => handleEdit(row)}>
                    {t("EDIT")}
                  </MenuItem>
                  <MenuItem key={'delete-' + row.id} onClick={() => handleDelete(row)}>
                    {t("DELETE")}
                  </MenuItem>
                </MenuButton>
              </TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
      <CreateServiceDialog open={openServiceCreate} setOpen={setOpenServiceCreate} onSubmit={() => services.refetch()}/>
      <EditServiceDialog open={openServiceEdit} setOpen={setOpenServiceEdit} service={currentEditService} onSubmit={() => services.refetch()}/>
    </>
  );
}
