import {getAuthApiInstance} from "../api";
import {getLedgerEntriesResult, getLedgerEntryResult} from "./results";
import {
  createLedgerEntryParams,
  getLedgerEntriesParams,
  getLedgerEntryParams,
  updateLedgerEntryParams
} from "./params";
import {RequestCriteria, UrlHelper} from "../../helper/url";

/**
 * Ledger entries
 */
export const apiGetLedgerEntries = (params: getLedgerEntriesParams): Promise<getLedgerEntriesResult> => {
  let criteria: Array<RequestCriteria> = [];
  if (params.customer_id) {
    criteria.push({field: 'customer_id', comp: 'eq', value: params.customer_id});
  }

  if (params.product_id) {
    criteria.push({field: 'product_id', comp: 'eq', value: params.product_id});
  }

  if (params.name) {
    criteria.push({field: 'name', comp: 'like', value: params.name});
  }

  if (typeof params.include_processed === 'undefined' || !params.include_processed) {
    criteria.push({field: 'processed_at', comp: 'is_null'});
  }

  return getAuthApiInstance().get(`ledgers${UrlHelper.parseSearchCriteria(criteria, params.page, params.limit, 'updated_at', 'desc')}`).then((result) => {
    return result.data as getLedgerEntriesResult;
  });
}

export const apiGetLedgerEntry = (params: getLedgerEntryParams): Promise<getLedgerEntryResult> => {
  return getAuthApiInstance().get("ledgers/" + params.id).then((result) => {
    return result.data as getLedgerEntryResult;
  });
}

export const apiCreateLedgerEntry = (params: createLedgerEntryParams): Promise<getLedgerEntryResult> => {
  return getAuthApiInstance().put("ledgers", params).then((result) => {
    return result.data as getLedgerEntryResult;
  });
}

export const apiUpdateLedgerEntry = (params: updateLedgerEntryParams): Promise<getLedgerEntryResult> => {
  const {id, ...restParams} = params;
  return getAuthApiInstance().post("ledgers/" + id, restParams).then((result) => {
    return result.data as getLedgerEntryResult;
  });
}

export const apiDeleteLedgerEntry = (params: getLedgerEntryParams): Promise<void> => {
  return getAuthApiInstance().delete("ledgers/" + params.id);
}
