import {FC} from "react";
import {SelectInput} from "./SelectInput";
import {useTranslation} from "react-i18next";

export interface Props {
  onChange: (value: string) => void,
  value?: string,
  label: string,
  identifier: string,
}

export const ChargeIntervalSelect: FC<Props> = ({onChange, value, label, identifier}) => {
  const { t } = useTranslation();
  const options = [
    {
      value: "daily",
      label: t("DAILY")
    },
    {
      value: "weekly",
      label: t("WEEKLY")
    },
    {
      value: "monthly",
      label: t("MONTHLY")
    },
    {
      value: "annually",
      label: t("ANNUALLY")
    }
  ];

  return (
    <SelectInput identifier={identifier} label={label} onChange={onChange} value={value} options={options}/>
  );
}
