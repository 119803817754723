import {AuthenticationStorageInterface} from "./interface";
import moment, {Moment} from "moment/moment";
import {StorageHelper} from "../storage/helper";
import {
  ACCESS_TOKEN_STORAGE_KEY,
  EXPIRES_AT_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY
} from "./constant";

export const getAuthenticationStorage = (): AuthenticationStorageInterface => {
  const refreshToken = StorageHelper.getItem(REFRESH_TOKEN_STORAGE_KEY) as string|undefined;
  const accessToken = StorageHelper.getItem(ACCESS_TOKEN_STORAGE_KEY) as string|undefined;
  let tokenExpireDate = StorageHelper.getItem(EXPIRES_AT_STORAGE_KEY) as Moment|number|undefined;
  if (typeof tokenExpireDate === "number") {
    tokenExpireDate = moment.unix(tokenExpireDate);
  }

  return {
    accessToken,
    tokenExpireDate,
    refreshToken
  };
}

export const updateAuthenticationStorage = (
  accessToken?: string,
  tokenExpiresAt?: Moment,
  refreshToken?: string
) => {
  if (accessToken) {
    StorageHelper.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
  } else {
    StorageHelper.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  }

  if (tokenExpiresAt) {
    StorageHelper.setItem(EXPIRES_AT_STORAGE_KEY, tokenExpiresAt.unix());
  } else {
    StorageHelper.removeItem(EXPIRES_AT_STORAGE_KEY);
  }

  if (refreshToken) {
    StorageHelper.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
  } else {
    StorageHelper.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  }
};