import React, {useEffect, useState} from 'react';
import {useAppContext, useConfirmDialogContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import AddCircle from '@mui/icons-material/AddCircle';
import {
  IconButton,
  MenuItem,
} from '@mui/material';
import moment from "moment/moment";
import {MenuButton} from "../../components/layout/MenuButton";
import {LoadingRow} from "../../components/layout/LoadingRow";
import {ErrorHelper} from "../../helper/errors";
import {toast} from "react-toastify";
import {EditProductBundleDialog} from "./EditProductBundleDialog";
import {apiDeleteProductBundle, apiGetProductBundles} from "../../api/product_bundles/api";
import {ProductBundleInterface} from "../../services/product_bundles/interface";
import {CreateProductBundleDialog} from "./CreateProductBundleDialog";
import {Table, TableCell, TableRow, TableBody, TableHead} from "../../components/layout/Table";
import {MenuItemLink} from "../../components/layout/MenuItemLink";

export default function ProductBundleScene() {
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [currentEditProductBundle, setCurrentEditProductBundle] = useState<ProductBundleInterface|null>(null);
  const productBundles = useQuery(['product_bundles'], apiGetProductBundles);
  const [openProductBundleCreate, setOpenProductBundleCreate] = useState(false);
  const [openProductBundleEdit, setOpenProductBundleEdit] = useState(false);
  const confirmDialog = useConfirmDialogContext();

  useEffect(() => {
    appContext?.setTitle(t("PRODUCT_BUNDLES"));
  }, [appContext, t]);

  const handleEdit = (row: ProductBundleInterface) => {
    setCurrentEditProductBundle(row);
    setOpenProductBundleEdit(true);
  };

  const handleDelete = (row: ProductBundleInterface) => {
    confirmDialog?.confirm(
      t("DELETE_PRODUCT_BUNDLE_QUESTION"),
      t("DELETE_PRODUCT_BUNDLE_QUESTION_EXPLANATION"),
      () => () => {
        apiDeleteProductBundle({id: row.id}).then(() => {
          toast.success(t("PRODUCT_BUNDLE_DELETED"));
          productBundles.refetch();
        }).catch((result) => {
          ErrorHelper.toastLaraFail(result);
        })
      }
    );
  };

  return (
    <>
      <h1>
        {t("PRODUCT_BUNDLES")}
        <IconButton aria-label="create" color="success" onClick={() => {setOpenProductBundleCreate(true)}}>
          <AddCircle />
        </IconButton>
      </h1>
      <Table sx={{ minWidth: 650 }} aria-label="product_bundles table">
        <TableHead>
          <TableRow>
            <TableCell align={"left"}>{t("NAME")}</TableCell>
            <TableCell align="right">{t("BASE_PRICE")}</TableCell>
            <TableCell align="right">{t("PRICE_INCL_TAX")}</TableCell>
            <TableCell align="right">{t("TAX_PERCENTAGE")}</TableCell>
            <TableCell align="right">{t("CREATED_AT")}</TableCell>
            <TableCell align="right">{t("UPDATED_AT")}</TableCell>
            <TableCell align="right">{t("ACTIONS")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productBundles.isLoading ? (
            <LoadingRow colspan={7} />
          ) : (productBundles.data?.items.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="right">€{row.base_price}</TableCell>
              <TableCell align="right">{row.price_incl_tax ? t("YES") : t("NO")}</TableCell>
              <TableCell align="right">{row.tax_percentage}%</TableCell>
              <TableCell align="right">{moment.unix(row.created_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">{moment.unix(row.updated_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">
                <MenuButton>
                  <MenuItem key={'edit-' + row.id} onClick={() => handleEdit(row)}>
                    {t("EDIT")}
                  </MenuItem>
                  <MenuItemLink key={'products-' + row.id} to={"/product_bundles/" + row.id + "/products"}>
                    {t("PRODUCTS")}
                  </MenuItemLink>
                  <MenuItem key={'delete-' + row.id} onClick={() => handleDelete(row)}>
                    {t("DELETE")}
                  </MenuItem>
                </MenuButton>
              </TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
      <CreateProductBundleDialog open={openProductBundleCreate} setOpen={setOpenProductBundleCreate} onSubmit={() => productBundles.refetch()}/>
      <EditProductBundleDialog open={openProductBundleEdit} setOpen={setOpenProductBundleEdit} productBundle={currentEditProductBundle} onSubmit={() => productBundles.refetch()}/>
    </>
  );
}
