import React, {FC, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup, TextField,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {CustomersSelect} from "../../components/form/CustomersSelect";
import {apiCreateInvoice} from "../../api/invoices/api";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {Moment} from "moment";

export interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}
export const CreateInvoiceDialog: FC<Props> = ({open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [customerId, setCustomerId] = useState("");
  const [startPeriod, setStartPeriod] = useState<null|Moment>(null);
  const [endPeriod, setEndPeriod] = useState<null|Moment>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const resetForm = () => {
    setCustomerId("");
    setStartPeriod(null);
    setEndPeriod(null);
  };

  const handleSubmit = () => {
    apiCreateInvoice({
      customer_id: customerId,
      start_period: startPeriod?.format() ?? undefined,
      end_period: endPeriod?.format() ?? undefined,
    }).then(() => {
      if (onSubmit) {
        onSubmit();
      }

      toast.success(t("INVOICE_CREATED"));
      resetForm();
      handleClose();
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  const handleChangeStartPeriod = (newValue: Moment | null) => {
    setStartPeriod(newValue);
  };

  const handleChangeEndPeriod = (newValue: Moment | null) => {
    setEndPeriod(newValue);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("CREATE_INVOICE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("CREATE_INVOICE_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <CustomersSelect onChange={setCustomerId} label={t("CUSTOMER")} identifier={"customer"} value={customerId} />
            <MobileDatePicker
                label={t("START_PERIOD")}
                inputFormat="DD/MM/YYYY"
                value={startPeriod}
                onChange={handleChangeStartPeriod}
                renderInput={(params) => <TextField {...params} />}
            />
            <MobileDatePicker
                label={t("END_PERIOD")}
                inputFormat="DD/MM/YYYY"
                value={endPeriod}
                onChange={handleChangeEndPeriod}
                renderInput={(params) => <TextField {...params} />}
            />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
