import {FC} from "react";
import {SelectInput} from "./SelectInput";
import {useTranslation} from "react-i18next";

export interface Props {
  onChange: (value: string) => void,
  value?: string,
  label: string,
  identifier: string,
}

export const CountrySelect: FC<Props> = ({onChange, value, label, identifier}) => {
  const { t } = useTranslation();
  const options = [
    { label: t("COUNTRY_AF"), value: "AF" },
    { label: t("COUNTRY_AX"), value: "AX" },
    { label: t("COUNTRY_AL"), value: "AL" },
    { label: t("COUNTRY_DZ"), value: "DZ" },
    { label: t("COUNTRY_AS"), value: "AS" },
    { label: t("COUNTRY_AD"), value: "AD" },
    { label: t("COUNTRY_AO"), value: "AO" },
    { label: t("COUNTRY_AI"), value: "AI" },
    { label: t("COUNTRY_AQ"), value: "AQ" },
    { label: t("COUNTRY_AG"), value: "AG" },
    { label: t("COUNTRY_AR"), value: "AR" },
    { label: t("COUNTRY_AM"), value: "AM" },
    { label: t("COUNTRY_AW"), value: "AW" },
    { label: t("COUNTRY_AU"), value: "AU" },
    { label: t("COUNTRY_AT"), value: "AT" },
    { label: t("COUNTRY_AZ"), value: "AZ" },
    { label: t("COUNTRY_BS"), value: "BS" },
    { label: t("COUNTRY_BH"), value: "BH" },
    { label: t("COUNTRY_BD"), value: "BD" },
    { label: t("COUNTRY_BB"), value: "BB" },
    { label: t("COUNTRY_BY"), value: "BY" },
    { label: t("COUNTRY_BE"), value: "BE" },
    { label: t("COUNTRY_BZ"), value: "BZ" },
    { label: t("COUNTRY_BJ"), value: "BJ" },
    { label: t("COUNTRY_BM"), value: "BM" },
    { label: t("COUNTRY_BT"), value: "BT" },
    { label: t("COUNTRY_BO"), value: "BO" },
    { label: t("COUNTRY_BQ"), value: "BQ" },
    { label: t("COUNTRY_BA"), value: "BA" },
    { label: t("COUNTRY_BW"), value: "BW" },
    { label: t("COUNTRY_BV"), value: "BV" },
    { label: t("COUNTRY_BR"), value: "BR" },
    { label: t("COUNTRY_IO"), value: "IO" },
    { label: t("COUNTRY_BN"), value: "BN" },
    { label: t("COUNTRY_BG"), value: "BG" },
    { label: t("COUNTRY_BF"), value: "BF" },
    { label: t("COUNTRY_BI"), value: "BI" },
    { label: t("COUNTRY_KH"), value: "KH" },
    { label: t("COUNTRY_CM"), value: "CM" },
    { label: t("COUNTRY_CA"), value: "CA" },
    { label: t("COUNTRY_CV"), value: "CV" },
    { label: t("COUNTRY_KY"), value: "KY" },
    { label: t("COUNTRY_CF"), value: "CF" },
    { label: t("COUNTRY_TD"), value: "TD" },
    { label: t("COUNTRY_CL"), value: "CL" },
    { label: t("COUNTRY_CN"), value: "CN" },
    { label: t("COUNTRY_CX"), value: "CX" },
    { label: t("COUNTRY_CC"), value: "CC" },
    { label: t("COUNTRY_CO"), value: "CO" },
    { label: t("COUNTRY_KM"), value: "KM" },
    { label: t("COUNTRY_CG"), value: "CG" },
    { label: t("COUNTRY_CD"), value: "CD" },
    { label: t("COUNTRY_CK"), value: "CK" },
    { label: t("COUNTRY_CR"), value: "CR" },
    { label: t("COUNTRY_CI"), value: "CI" },
    { label: t("COUNTRY_CW"), value: "CW" },
    { label: t("COUNTRY_HR"), value: "HR" },
    { label: t("COUNTRY_CU"), value: "CU" },
    { label: t("COUNTRY_CY"), value: "CY" },
    { label: t("COUNTRY_CZ"), value: "CZ" },
    { label: t("COUNTRY_DK"), value: "DK" },
    { label: t("COUNTRY_DJ"), value: "DJ" },
    { label: t("COUNTRY_DM"), value: "DM" },
    { label: t("COUNTRY_DO"), value: "DO" },
    { label: t("COUNTRY_EC"), value: "EC" },
    { label: t("COUNTRY_EG"), value: "EG" },
    { label: t("COUNTRY_SV"), value: "SV" },
    { label: t("COUNTRY_GQ"), value: "GQ" },
    { label: t("COUNTRY_ER"), value: "ER" },
    { label: t("COUNTRY_EE"), value: "EE" },
    { label: t("COUNTRY_ET"), value: "ET" },
    { label: t("COUNTRY_FK"), value: "FK" },
    { label: t("COUNTRY_FO"), value: "FO" },
    { label: t("COUNTRY_FJ"), value: "FJ" },
    { label: t("COUNTRY_FI"), value: "FI" },
    { label: t("COUNTRY_FR"), value: "FR" },
    { label: t("COUNTRY_GF"), value: "GF" },
    { label: t("COUNTRY_PF"), value: "PF" },
    { label: t("COUNTRY_TF"), value: "TF" },
    { label: t("COUNTRY_GA"), value: "GA" },
    { label: t("COUNTRY_GM"), value: "GM" },
    { label: t("COUNTRY_GE"), value: "GE" },
    { label: t("COUNTRY_DE"), value: "DE" },
    { label: t("COUNTRY_GH"), value: "GH" },
    { label: t("COUNTRY_GI"), value: "GI" },
    { label: t("COUNTRY_GR"), value: "GR" },
    { label: t("COUNTRY_GL"), value: "GL" },
    { label: t("COUNTRY_GD"), value: "GD" },
    { label: t("COUNTRY_GP"), value: "GP" },
    { label: t("COUNTRY_GU"), value: "GU" },
    { label: t("COUNTRY_GT"), value: "GT" },
    { label: t("COUNTRY_GG"), value: "GG" },
    { label: t("COUNTRY_GN"), value: "GN" },
    { label: t("COUNTRY_GW"), value: "GW" },
    { label: t("COUNTRY_GY"), value: "GY" },
    { label: t("COUNTRY_HT"), value: "HT" },
    { label: t("COUNTRY_HM"), value: "HM" },
    { label: t("COUNTRY_VA"), value: "VA" },
    { label: t("COUNTRY_HN"), value: "HN" },
    { label: t("COUNTRY_HK"), value: "HK" },
    { label: t("COUNTRY_HU"), value: "HU" },
    { label: t("COUNTRY_IS"), value: "IS" },
    { label: t("COUNTRY_IN"), value: "IN" },
    { label: t("COUNTRY_ID"), value: "ID" },
    { label: t("COUNTRY_IR"), value: "IR" },
    { label: t("COUNTRY_IQ"), value: "IQ" },
    { label: t("COUNTRY_IE"), value: "IE" },
    { label: t("COUNTRY_IM"), value: "IM" },
    { label: t("COUNTRY_IL"), value: "IL" },
    { label: t("COUNTRY_IT"), value: "IT" },
    { label: t("COUNTRY_JM"), value: "JM" },
    { label: t("COUNTRY_JP"), value: "JP" },
    { label: t("COUNTRY_JE"), value: "JE" },
    { label: t("COUNTRY_JO"), value: "JO" },
    { label: t("COUNTRY_KZ"), value: "KZ" },
    { label: t("COUNTRY_KE"), value: "KE" },
    { label: t("COUNTRY_KI"), value: "KI" },
    { label: t("COUNTRY_KP"), value: "KP" },
    { label: t("COUNTRY_KR"), value: "KR" },
    { label: t("COUNTRY_KW"), value: "KW" },
    { label: t("COUNTRY_KG"), value: "KG" },
    { label: t("COUNTRY_LA"), value: "LA" },
    { label: t("COUNTRY_LV"), value: "LV" },
    { label: t("COUNTRY_LB"), value: "LB" },
    { label: t("COUNTRY_LS"), value: "LS" },
    { label: t("COUNTRY_LR"), value: "LR" },
    { label: t("COUNTRY_LY"), value: "LY" },
    { label: t("COUNTRY_LI"), value: "LI" },
    { label: t("COUNTRY_LT"), value: "LT" },
    { label: t("COUNTRY_LU"), value: "LU" },
    { label: t("COUNTRY_MO"), value: "MO" },
    { label: t("COUNTRY_MK"), value: "MK" },
    { label: t("COUNTRY_MG"), value: "MG" },
    { label: t("COUNTRY_MW"), value: "MW" },
    { label: t("COUNTRY_MY"), value: "MY" },
    { label: t("COUNTRY_MV"), value: "MV" },
    { label: t("COUNTRY_ML"), value: "ML" },
    { label: t("COUNTRY_MT"), value: "MT" },
    { label: t("COUNTRY_MH"), value: "MH" },
    { label: t("COUNTRY_MQ"), value: "MQ" },
    { label: t("COUNTRY_MR"), value: "MR" },
    { label: t("COUNTRY_MU"), value: "MU" },
    { label: t("COUNTRY_YT"), value: "YT" },
    { label: t("COUNTRY_MX"), value: "MX" },
    { label: t("COUNTRY_FM"), value: "FM" },
    { label: t("COUNTRY_MD"), value: "MD" },
    { label: t("COUNTRY_MC"), value: "MC" },
    { label: t("COUNTRY_MN"), value: "MN" },
    { label: t("COUNTRY_ME"), value: "ME" },
    { label: t("COUNTRY_MS"), value: "MS" },
    { label: t("COUNTRY_MA"), value: "MA" },
    { label: t("COUNTRY_MZ"), value: "MZ" },
    { label: t("COUNTRY_MM"), value: "MM" },
    { label: t("COUNTRY_NA"), value: "NA" },
    { label: t("COUNTRY_NR"), value: "NR" },
    { label: t("COUNTRY_NP"), value: "NP" },
    { label: t("COUNTRY_NL"), value: "NL" },
    { label: t("COUNTRY_NC"), value: "NC" },
    { label: t("COUNTRY_NZ"), value: "NZ" },
    { label: t("COUNTRY_NI"), value: "NI" },
    { label: t("COUNTRY_NE"), value: "NE" },
    { label: t("COUNTRY_NG"), value: "NG" },
    { label: t("COUNTRY_NU"), value: "NU" },
    { label: t("COUNTRY_NF"), value: "NF" },
    { label: t("COUNTRY_MP"), value: "MP" },
    { label: t("COUNTRY_NO"), value: "NO" },
    { label: t("COUNTRY_OM"), value: "OM" },
    { label: t("COUNTRY_PK"), value: "PK" },
    { label: t("COUNTRY_PW"), value: "PW" },
    { label: t("COUNTRY_PS"), value: "PS" },
    { label: t("COUNTRY_PA"), value: "PA" },
    { label: t("COUNTRY_PG"), value: "PG" },
    { label: t("COUNTRY_PY"), value: "PY" },
    { label: t("COUNTRY_PE"), value: "PE" },
    { label: t("COUNTRY_PH"), value: "PH" },
    { label: t("COUNTRY_PN"), value: "PN" },
    { label: t("COUNTRY_PL"), value: "PL" },
    { label: t("COUNTRY_PT"), value: "PT" },
    { label: t("COUNTRY_PR"), value: "PR" },
    { label: t("COUNTRY_QA"), value: "QA" },
    { label: t("COUNTRY_RE"), value: "RE" },
    { label: t("COUNTRY_RO"), value: "RO" },
    { label: t("COUNTRY_RU"), value: "RU" },
    { label: t("COUNTRY_RW"), value: "RW" },
    { label: t("COUNTRY_BL"), value: "BL" },
    { label: t("COUNTRY_SH"), value: "SH" },
    { label: t("COUNTRY_KN"), value: "KN" },
    { label: t("COUNTRY_LC"), value: "LC" },
    { label: t("COUNTRY_MF"), value: "MF" },
    { label: t("COUNTRY_PM"), value: "PM" },
    { label: t("COUNTRY_VC"), value: "VC" },
    { label: t("COUNTRY_WS"), value: "WS" },
    { label: t("COUNTRY_SM"), value: "SM" },
    { label: t("COUNTRY_ST"), value: "ST" },
    { label: t("COUNTRY_SA"), value: "SA" },
    { label: t("COUNTRY_SN"), value: "SN" },
    { label: t("COUNTRY_RS"), value: "RS" },
    { label: t("COUNTRY_SC"), value: "SC" },
    { label: t("COUNTRY_SL"), value: "SL" },
    { label: t("COUNTRY_SG"), value: "SG" },
    { label: t("COUNTRY_SX"), value: "SX" },
    { label: t("COUNTRY_SK"), value: "SK" },
    { label: t("COUNTRY_SI"), value: "SI" },
    { label: t("COUNTRY_SB"), value: "SB" },
    { label: t("COUNTRY_SO"), value: "SO" },
    { label: t("COUNTRY_ZA"), value: "ZA" },
    { label: t("COUNTRY_SS"), value: "SS" },
    { label: t("COUNTRY_GS"), value: "GS" },
    { label: t("COUNTRY_ES"), value: "ES" },
    { label: t("COUNTRY_LK"), value: "LK" },
    { label: t("COUNTRY_SD"), value: "SD" },
    { label: t("COUNTRY_SR"), value: "SR" },
    { label: t("COUNTRY_SJ"), value: "SJ" },
    { label: t("COUNTRY_SZ"), value: "SZ" },
    { label: t("COUNTRY_SE"), value: "SE" },
    { label: t("COUNTRY_CH"), value: "CH" },
    { label: t("COUNTRY_SY"), value: "SY" },
    { label: t("COUNTRY_TW"), value: "TW" },
    { label: t("COUNTRY_TJ"), value: "TJ" },
    { label: t("COUNTRY_TZ"), value: "TZ" },
    { label: t("COUNTRY_TH"), value: "TH" },
    { label: t("COUNTRY_TL"), value: "TL" },
    { label: t("COUNTRY_TG"), value: "TG" },
    { label: t("COUNTRY_TK"), value: "TK" },
    { label: t("COUNTRY_TO"), value: "TO" },
    { label: t("COUNTRY_TT"), value: "TT" },
    { label: t("COUNTRY_TN"), value: "TN" },
    { label: t("COUNTRY_TR"), value: "TR" },
    { label: t("COUNTRY_TM"), value: "TM" },
    { label: t("COUNTRY_TC"), value: "TC" },
    { label: t("COUNTRY_TV"), value: "TV" },
    { label: t("COUNTRY_UG"), value: "UG" },
    { label: t("COUNTRY_UA"), value: "UA" },
    { label: t("COUNTRY_AE"), value: "AE" },
    { label: t("COUNTRY_GB"), value: "GB" },
    { label: t("COUNTRY_US"), value: "US" },
    { label: t("COUNTRY_UM"), value: "UM" },
    { label: t("COUNTRY_UY"), value: "UY" },
    { label: t("COUNTRY_UZ"), value: "UZ" },
    { label: t("COUNTRY_VU"), value: "VU" },
    { label: t("COUNTRY_VE"), value: "VE" },
    { label: t("COUNTRY_VN"), value: "VN" },
    { label: t("COUNTRY_VG"), value: "VG" },
    { label: t("COUNTRY_VI"), value: "VI" },
    { label: t("COUNTRY_WF"), value: "WF" },
    { label: t("COUNTRY_EH"), value: "EH" },
    { label: t("COUNTRY_YE"), value: "YE" },
    { label: t("COUNTRY_ZM"), value: "ZM" },
    { label: t("COUNTRY_ZW"), value: "ZW" },
  ];

  return (
    <SelectInput identifier={identifier} label={label} onChange={onChange} value={value} options={options}/>
  );
}
