import React, {FC, useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {CustomerServiceInterface} from "../../services/customer/interface";
import {apiConfigureCustomerService} from "../../api/customers/api";

export interface Props {
  customerService: CustomerServiceInterface,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}

export const ConfigureCustomerServiceDialog: FC<Props> = ({customerService, open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [variableItems, setVariableItems] = useState<Array<{id: string, label: string, value: number|undefined}>>([]);

  useEffect(() => {
    let newItems: Array<{id: string, label: string, value: number|undefined}> = [];
    customerService?.service.variables.forEach((row) => {
      newItems.push({
        id: row.id,
        label: row.name,
        value: row.service_variable_values[0]?.value
      });
    });
    setVariableItems(newItems);
  }, [customerService]);

  const updateItem = (index: number, value: number) => {
    let newItems = variableItems;
    newItems[index].value = value;
    setVariableItems(newItems);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    let newItems: Array<{service_variable_id: string, value: number}> = [];
    variableItems.forEach((row) => {
      newItems.push({
        service_variable_id: row.id,
        value: row.value ?? 0,
      });
    });

    if (newItems.length > 0) {
      apiConfigureCustomerService({
        customer_id: customerService.customer_id,
        service_id: customerService.service_id,
        items: newItems
      }).then(() => {
        toast.success(t("CUSTOMER_SERVICE_CONFIGURED"));

        handleClose();
        if (onSubmit) {
          onSubmit();
        }
      }).catch((result) => {
        ErrorHelper.toastLaraFail(result);
      });
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("CONFIGURE_CUSTOMER_SERVICE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("CONFIGURE_CUSTOMER_SERVICE_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            {variableItems.map((row, index) => (
              <TextField key={row.id} label={row.label} type={"number"} value={row.value} onChange={e => updateItem(index, parseInt(e.target.value))} />
            ))}
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
