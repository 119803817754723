import React, {FC, useEffect, useState} from 'react';
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControlLabel,
  FormGroup, InputAdornment,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {ProductInterface} from "../../services/product/interface";
import {apiUpdateProduct} from "../../api/products/api";

export interface Props {
  product: ProductInterface|null,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}

export const EditProductDialog: FC<Props> = ({product, open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [name, setName] = useState(product?.name ?? "");
  const [price, setPrice] = useState(product?.base_price ?? null);
  const [priceInclTax, setPriceInclTax] = useState(product?.price_incl_tax ?? false);
  const [taxPercentage, setTaxPercentage] = useState(product?.tax_percentage ?? 21);

  useEffect(() => {
    setName(product?.name ?? "");
    setPrice(product?.base_price ?? null);
    setPriceInclTax(product?.price_incl_tax ?? false);
    setTaxPercentage(product?.tax_percentage ?? 21);
  }, [product]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    apiUpdateProduct({
      id: product?.id ?? "",
      name,
      base_price: price ?? undefined,
      price_incl_tax: priceInclTax,
      tax_percentage: taxPercentage,
    }).then(() => {
      toast.success(t("PRODUCT_EDITED"));
      handleClose();
      if (onSubmit) {
        onSubmit();
      }
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("EDIT_PRODUCT")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("EDIT_PRODUCT_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <TextField label={t("NAME")} type={"text"} value={name} onChange={e => setName(e.target.value)} />
            <TextField InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
            }} label={t("PRICE")} type={"number"} value={price} onChange={e => setPrice(parseFloat(e.target.value))} />
            <TextField InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} label={t("TAX_PERCENTAGE")} type={"number"} value={taxPercentage} onChange={e => setTaxPercentage(parseFloat(e.target.value))} />
            <FormControlLabel control={<Checkbox
              checked={priceInclTax}
              onChange={e => setPriceInclTax(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t("PRICE_INCL_TAX")} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
