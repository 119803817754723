import React, {useEffect, useState} from 'react';
import {useAppContext} from "../../services/app/context";
import {LoadingRow} from "../../components/layout/LoadingRow";
import {MenuButton} from "../../components/layout/MenuButton";
import {MenuItemLink} from "../../components/layout/MenuItemLink";
import {Pager} from "../../components/layout/Pager";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {getCurrentMonth, getCurrentYear} from "../../helper/constants";
import {MonthSelect} from "../../components/form/MonthSelect";
import {YearSelect} from "../../components/form/YearSelect";
import {Grid} from "../../components/layout/Grid";
import {Table, TableRow, TableHead, TableCell, TableBody} from "../../components/layout/Table";
import {apiGetCustomersUsagePaginated} from "../../api/customers/api";
import {EuroSign} from "../../components/layout/EuroSign";
import {TextField} from "@mui/material";

export default function OverviewScene() {
  const appContext = useAppContext();

  const {t} = useTranslation();

  const customers = useQuery(['customers_usage'], () => {return apiGetCustomersUsagePaginated({
    month: Number(month) + 1 + "",
    year,
    ...filters
  });});

  const [month, setMonth] = useState(getCurrentMonth() + "")
  const [year, setYear] = useState(getCurrentYear() + "")
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({limit: 50, page: 1});

  const setFilter = async (key: string, value?: string|number) => {
    if (value === "") {
      value = undefined;
    }

    await setFilters(
      Object.assign(Object.assign({}, filters), {[key]: value})
    );

    customers.refetch();
  };

  const handlePageChange = (page: number) => {
    setFilter('page', page);
    setTimeout(() => {
      customers.refetch();
    }, 0);
  };

  useEffect(() => {
    appContext?.setTitle(t("MONTH_OVERVIEW"));
  }, [appContext]);

  useEffect(() => {
    setFilter('month', month);
  }, [month]);

  useEffect(() => {
    setFilter('year', year);
  }, [year]);

  useEffect(() => {
    setFilter('name', search);
  }, [search]);

  const selectMonth = (month: string | Array<string>) => {
    const val = Array.isArray(month) ? month[0] : month;
    setMonth(val);
  };

  const selectYear = (year: string | Array<string>) => {
    const val = Array.isArray(year) ? year[0] : year;
    setYear(val);
  };


  function renderHeader() {
    return (
      <Grid  alignItems="center" container spacing={2}>
        <Grid item xs={6}>
          <h1>{t("MONTH_OVERVIEW")}</h1>
        </Grid>
        <Grid item xs={2}>
          <TextField label={t("NAME")} type={"text"} value={search} onChange={e => {setSearch(e.target.value);}} />
        </Grid>
        <Grid item xs={2}>
          <MonthSelect value={month + ""} onChange={selectMonth} identifier={"month"}/>
        </Grid>
        <Grid  item xs={2}>
          <YearSelect value={year + ""} onChange={selectYear} identifier={"year"}/>
        </Grid>
      </Grid>
    );
  }

  function displayPrice(value: number): number {
   if (!value) return 0;

   return Math.round(value * 10) / 10
  }

  return (
    <>
      {renderHeader()}
      <Table title={t("MONTH_OVERVIEW")}>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t("NAME")}</TableCell>
            <TableCell>{t("AMOUNT_OF_USERS")}</TableCell>
            <TableCell>{t("AMOUNT_OF_INTEGRATIONS")}</TableCell>
            <TableCell>{t("LEDGER_ENTRIES_TOTAL")} (<EuroSign/>)</TableCell>
            <TableCell>{t("SUPPORT_SERVICE_TOTAL")} (<EuroSign/>)</TableCell>
            <TableCell>{t("ACTIONS")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.isLoading ? (
            <LoadingRow colspan={7} />
          ) : (customers.data?.items.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell>{row.amount_of_users ? row.amount_of_users : 0} (<EuroSign/>{displayPrice(row.total_users_costs)})</TableCell>
              <TableCell>{row.amount_of_integrations ? row.amount_of_integrations : 0} (<EuroSign/>{displayPrice(row.total_integrations_costs)})</TableCell>
              <TableCell><EuroSign/>{displayPrice(row.total_micro_transactions_costs)}</TableCell>
              <TableCell><EuroSign/>{displayPrice(row.total_support_service_costs)}</TableCell>
              <TableCell>
                <MenuButton>
                  <MenuItemLink key={'services-' + row.id} to={"/customers/" + row.id + "/services"}>
                    {t("SERVICES")}
                  </MenuItemLink>
                </MenuButton>
              </TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
      {!customers.isLoading && (
        <Pager currentPage={filters.page} totalPages={customers.data?.metadata.total_pages} setCurrentPage={handlePageChange} totalItems={customers.data?.metadata.total} itemsPerPage={customers.data?.metadata.count}/>
      )}
    </>
  );
}
