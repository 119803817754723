import React, {useEffect, useState} from 'react';
import {useAppContext, useConfirmDialogContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import AddCircle from '@mui/icons-material/AddCircle';
import {
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {MenuButton} from "../../components/layout/MenuButton";
import {LoadingRow} from "../../components/layout/LoadingRow";
import {ErrorHelper} from "../../helper/errors";
import {toast} from "react-toastify";
import {CustomerServiceInterface} from "../../services/customer/interface";
import {
  apiDisconnectCustomerService,
  apiGetCustomer,
  apiGetCustomerServices
} from "../../api/customers/api";
import {useParams} from "react-router-dom";
import {ConnectCustomerServiceDialog} from "./ConnectCustomerServiceDialog";
import {ConfigureCustomerServiceDialog} from "./ConfigureCustomerServiceDialog";

export default function CustomerServiceScene() {
  let { customerId } = useParams();
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [currentEditCustomerService, setCurrentEditCustomerService] = useState<CustomerServiceInterface|null>(null);
  const [currentConfigureCustomerService, setCurrentConfigureCustomerService] = useState<CustomerServiceInterface|null>(null);
  const customer = useQuery(['customer'], () => {return apiGetCustomer({id: customerId as string});});
  const customerServices = useQuery(['customerServices'], () => {return apiGetCustomerServices(customerId as string);});
  const [openCustomerServiceEdit, setOpenCustomerServiceEdit] = useState(false);
  const [openCustomerServiceConfigure, setOpenCustomerServiceConfigure] = useState(false);
  const confirmDialog = useConfirmDialogContext();

  useEffect(() => {
    appContext?.setTitle(t("CUSTOMER_SERVICES"));
  }, [appContext, t, customer]);

  const handleEdit = (row: CustomerServiceInterface) => {
    setCurrentEditCustomerService(row);
    setOpenCustomerServiceEdit(true);
  };

  const handleConfigure = (row: CustomerServiceInterface) => {
    setCurrentConfigureCustomerService(row);
    setOpenCustomerServiceConfigure(true);
  };

  const handleDelete = (row: CustomerServiceInterface) => {
    confirmDialog?.confirm(
      t("DISCONNECT_CUSTOMER_SERVICE_QUESTION"),
      t("DISCONNECT_CUSTOMER_SERVICE_QUESTION_EXPLANATION"),
      () => () => {
        apiDisconnectCustomerService({customer_id: row.customer_id, service_id: row.service_id}).then(() => {
          toast.success(t("CUSTOMER_SERVICE_DISCONNECTED"));
          customerServices.refetch();
        }).catch((result) => {
          ErrorHelper.toastLaraFail(result);
        })
      }
    );
  };

  return (
    <>
      <h1>
        {t("CUSTOMER_SERVICES_FOR") + " " + customer.data?.name}
        <IconButton aria-label="create" color="success" onClick={() => {setCurrentEditCustomerService(null); setOpenCustomerServiceEdit(true);}}>
          <AddCircle />
        </IconButton>
      </h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customer_service table">
          <TableHead>
            <TableRow>
              <TableCell>{t("NAME")}</TableCell>
              <TableCell align="right">{t("EXTERNAL_REFERENCE")}</TableCell>
              <TableCell align="right">{t("QUANTITY")}</TableCell>
              <TableCell align="right">{t("DISCOUNT")}</TableCell>
              <TableCell align="right">{t("DISCOUNT_INCL_TAX")}</TableCell>
              <TableCell align="right">{t("DISCOUNT_TYPE")}</TableCell>
              <TableCell align="right">{t("ACTIONS")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerServices.isLoading ? (
              <LoadingRow colspan={7} />
            ) : (customerServices.data?.items.map((row) => (
              <TableRow
                key={row.service_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.service.name}</TableCell>
                <TableCell align="right">{row.external_reference}</TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="right">{row.discount}</TableCell>
                <TableCell align="right">{row.discount_incl_tax ? t("YES") : t("NO")}</TableCell>
                <TableCell align="right">{t(row.discount_type.toUpperCase())}</TableCell>
                <TableCell align="right">
                  <MenuButton>
                    <MenuItem key={'configure-' + row.service_id} onClick={() => handleConfigure(row)}>
                      {t("CONFIGURE")}
                    </MenuItem>
                    <MenuItem key={'edit-' + row.service_id} onClick={() => handleEdit(row)}>
                      {t("EDIT")}
                    </MenuItem>
                    <MenuItem key={'delete-' + row.service_id} onClick={() => handleDelete(row)}>
                      {t("DELETE")}
                    </MenuItem>
                  </MenuButton>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConnectCustomerServiceDialog customerId={customerId as string} open={openCustomerServiceEdit} setOpen={setOpenCustomerServiceEdit} customerService={currentEditCustomerService} onSubmit={() => customerServices.refetch()}/>
      <ConfigureCustomerServiceDialog open={openCustomerServiceConfigure} setOpen={setOpenCustomerServiceConfigure} customerService={currentConfigureCustomerService as CustomerServiceInterface} onSubmit={() => customerServices.refetch()}/>
    </>
  );
}
