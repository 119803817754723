import React, {useEffect, useState} from 'react';
import {useAppContext, useConfirmDialogContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import moment from "moment/moment";
import {MenuButton} from "../../components/layout/MenuButton";
import {LoadingRow} from "../../components/layout/LoadingRow";
import {ErrorHelper} from "../../helper/errors";
import {toast} from "react-toastify";
import {CustomerInterface} from "../../services/customer/interface";
import {apiDeleteCustomer, apiGetCustomersPaginated} from "../../api/customers/api";
import {CreateCustomerDialog} from "./CreateCustomerDialog";
import {EditCustomerDialog} from "./EditCustomerDialog";
import {MenuItemLink} from "../../components/layout/MenuItemLink";
import {Pager} from "../../components/layout/Pager";
import {CreateButton} from "../../components/layout/CreateButton";
import {Table, TableRow, TableHead, TableCell, TableBody} from "../../components/layout/Table";
import {MenuItem} from "@mui/material";


export default function CustomerScene() {
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [currentEditCustomer, setCurrentEditCustomer] = useState<CustomerInterface|null>(null);
  const customers = useQuery(['customers'], () => {return apiGetCustomersPaginated(currentPage);});
  const [openCustomerCreate, setOpenCustomerCreate] = useState(false);
  const [openCustomerEdit, setOpenCustomerEdit] = useState(false);
  const confirmDialog = useConfirmDialogContext();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    appContext?.setTitle(t("CUSTOMERS"));
  }, [appContext, t]);

  const handleEdit = (row: CustomerInterface) => {
    setCurrentEditCustomer(row);
    setOpenCustomerEdit(true);
  };

  const handleDelete = (row: CustomerInterface) => {
    confirmDialog?.confirm(
      t("DELETE_CUSTOMER_QUESTION"),
      t("DELETE_CUSTOMER_QUESTION_EXPLANATION"),
      () => () => {
        apiDeleteCustomer({id: row.id}).then(() => {
          toast.success(t("CUSTOMER_DELETED"));
          customers.refetch();
        }).catch((result) => {
          ErrorHelper.toastLaraFail(result);
        })
      }
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setTimeout(() => {
      customers.refetch();
    }, 0);
  };

  return (
    <>
      <h1>
        {t("CUSTOMERS")}
        <CreateButton onClick={() => {setOpenCustomerCreate(true)}} />
      </h1>
      <Table title={"customers table"}>
        <TableHead>
          <TableRow>
            <TableCell>{t("NAME")}</TableCell>
            <TableCell align="right">{t("ADDRESS")}</TableCell>
            <TableCell align="right">{t("HOUSE_NUMBER")}</TableCell>
            <TableCell align="right">{t("POSTAL_CODE")}</TableCell>
            <TableCell align="right">{t("CITY")}</TableCell>
            <TableCell align="right">{t("COUNTRY_CODE")}</TableCell>
            <TableCell align="right">{t("KVK")}</TableCell>
            <TableCell align="right">{t("TAX_NUMBER")}</TableCell>
            <TableCell align="right">{t("PHONE_NUMBER")}</TableCell>
            <TableCell align="right">{t("EMAIL")}</TableCell>
            <TableCell align="right">{t("CREATED_AT")}</TableCell>
            <TableCell align="right">{t("UPDATED_AT")}</TableCell>
            <TableCell align="right">{t("ACTIONS")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.isLoading ? (
            <LoadingRow colspan={7} />
          ) : (customers.data?.items.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="right">{row.address}</TableCell>
              <TableCell align="right">{row.house_number}</TableCell>
              <TableCell align="right">{row.postal_code}</TableCell>
              <TableCell align="right">{row.city}</TableCell>
              <TableCell align="right">{row.country_code}</TableCell>
              <TableCell align="right">{row.kvk}</TableCell>
              <TableCell align="right">{row.tax_number}</TableCell>
              <TableCell align="right">{row.phone_number}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{moment.unix(row.created_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">{moment.unix(row.updated_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">
                <MenuButton>
                  <MenuItemLink key={'services-' + row.id} to={"/customers/" + row.id + "/services"}>
                    {t("SERVICES")}
                  </MenuItemLink>
                  <MenuItem key={'edit-' + row.id} onClick={() => handleEdit(row)}>
                    {t("EDIT")}
                  </MenuItem>
                  <MenuItem key={'delete-' + row.id} onClick={() => handleDelete(row)}>
                    {t("DELETE")}
                  </MenuItem>
                </MenuButton>
              </TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
      {!customers.isLoading && (
        <Pager currentPage={currentPage} totalPages={customers.data?.metadata.total_pages} setCurrentPage={handlePageChange} totalItems={customers.data?.metadata.total} itemsPerPage={customers.data?.metadata.count}/>
      )}
      <CreateCustomerDialog open={openCustomerCreate} setOpen={setOpenCustomerCreate} onSubmit={() => customers.refetch()}/>
      <EditCustomerDialog open={openCustomerEdit} setOpen={setOpenCustomerEdit} customer={currentEditCustomer} onSubmit={() => customers.refetch()}/>
    </>
  );
}
