export const Countries = {
  COUNTRY_AF: "Afghanistan",
  COUNTRY_AX: "Åland-eilanden",
  COUNTRY_AL: "Albanië",
  COUNTRY_DZ: "Algerije",
  COUNTRY_AS: "Amerikaans Samoa",
  COUNTRY_AD: "Andorra",
  COUNTRY_AO: "Angola",
  COUNTRY_AI: "Paling",
  COUNTRY_AQ: "Antarctica",
  COUNTRY_AG: "Antigua en Barbuda",
  COUNTRY_AR: "Argentinië",
  COUNTRY_AM: "Armenië",
  COUNTRY_AW: "Aruba",
  COUNTRY_AU: "Australië",
  COUNTRY_AT: "Oostenrijk",
  COUNTRY_AZ: "Azerbeidzjan",
  COUNTRY_BS: "Bahama's",
  COUNTRY_BH: "Bahrein",
  COUNTRY_BD: "Bangladesh",
  COUNTRY_BB: "Baarden",
  COUNTRY_BY: "Wit-Rusland",
  COUNTRY_BE: "België",
  COUNTRY_BZ: "Belize",
  COUNTRY_BJ: "Benin",
  COUNTRY_BM: "Bermuda",
  COUNTRY_BT: "Bhutan",
  COUNTRY_BO: "Bolivia",
  COUNTRY_BQ: "Bonaire, Sint Eustatius en Saba",
  COUNTRY_BA: "Bosnië en Herzegovina",
  COUNTRY_BV: "Bouvet-eiland",
  COUNTRY_BR: "Brazilië",
  COUNTRY_IO: "Brits Territorium in de Indische Oceaan",
  COUNTRY_BN: "Brunei Darussalam",
  COUNTRY_BG: "Bulgarije",
  COUNTRY_BF: "Burkina Faso",
  COUNTRY_BI: "Burundi",
  COUNTRY_KH: "Cambodja",
  COUNTRY_CM: "Kameroen",
  COUNTRY_CA: "Canada",
  COUNTRY_CV: "Kaap Groen",
  COUNTRY_KY: "Kaaimaneilanden",
  COUNTRY_CF: "Centraal-Afrikaanse Republiek",
  COUNTRY_TD: "Tsjaad",
  COUNTRY_CL: "Chili",
  COUNTRY_CN: "China",
  COUNTRY_CX: "Kersteiland",
  COUNTRY_CC: "Cocos (Keelingeilanden",
  COUNTRY_CO: "Colombia",
  COUNTRY_KM: "Comoren",
  COUNTRY_CG: "Kongo",
  COUNTRY_CD: "De Republiek Congo",
  COUNTRY_CK: "Cookeilanden",
  COUNTRY_CR: "Costa Rica",
  COUNTRY_CI: "Ivoorkust",
  COUNTRY_CW: "Curaçao",
  COUNTRY_HR: "Kroatië",
  COUNTRY_CU: "Cuba",
  COUNTRY_CY: "Cyprus",
  COUNTRY_CZ: "Tsjechië",
  COUNTRY_DK: "Denemarken",
  COUNTRY_DJ: "Djibouti",
  COUNTRY_DM: "Dominica",
  COUNTRY_DO: "Dominicaanse Republiek",
  COUNTRY_EC: "Ecuador",
  COUNTRY_EG: "Egypte",
  COUNTRY_SV: "De Verlosser",
  COUNTRY_GQ: "Equatoriaal-Guinea",
  COUNTRY_ER: "Eritrea",
  COUNTRY_JA: "Estland",
  COUNTRY_ET: "Ethiopië",
  COUNTRY_FK: "Falklandeilanden (Falkland",
  COUNTRY_FO: "Faeröer Eilanden",
  COUNTRY_FJ: "Fiji",
  COUNTRY_FI: "Finland",
  COUNTRY_FR: "Frankrijk",
  COUNTRY_GF: "Frans Guyana",
  COUNTRY_PF: "Frans Polynesië",
  COUNTRY_TF: "Franse Zuidelijke Gebieden",
  COUNTRY_GA: "Gabon",
  COUNTRY_GM: "Gambia",
  COUNTRY_GE: "Georgië",
  COUNTRY_DE: "Duitsland",
  COUNTRY_GH: "Ghana",
  COUNTRY_GI: "Gibraltar",
  COUNTRY_GR: "Griekenland",
  COUNTRY_GL: "Groenland",
  COUNTRY_GD: "Granaat",
  COUNTRY_GP: "Guadeloupe",
  COUNTRY_GU: "Guam",
  COUNTRY_GT: "Guatemala",
  COUNTRY_GG: "Guernsey",
  COUNTRY_GN: "Guinee",
  COUNTRY_GW: "Guinee-Bissau",
  COUNTRY_GY: "Guyana",
  COUNTRY_HT: "Haïti",
  COUNTRY_HM: "Heard- en McDonaldeilanden",
  COUNTRY_VA: "Heilige Stoel (staat Vaticaanstad",
  COUNTRY_HN: "Honduras",
  COUNTRY_HK: "Hongkong",
  COUNTRY_HU: "Hongarije",
  COUNTRY_IS: "IJsland",
  COUNTRY_IN: "India",
  COUNTRY_ID: "Indonesië",
  COUNTRY_IR: "Iran",
  COUNTRY_IQ: "Irak",
  COUNTRY_IE: "Ierland",
  COUNTRY_IM: "Island of Man",
  COUNTRY_IL: "Israël",
  COUNTRY_IT: "Italië",
  COUNTRY_JM: "Jamaica",
  COUNTRY_JP: "Japan",
  COUNTRY_JE: "Jersey",
  COUNTRY_JO: "Jordanië",
  COUNTRY_KZ: "Kazachstan",
  COUNTRY_KE: "Invoegen",
  COUNTRY_KI: "Kiribati",
  COUNTRY_KP: "Democratische Volksrepubliek Korea",
  COUNTRY_KR: "Republiek Korea",
  COUNTRY_KW: "Koeweit",
  COUNTRY_KG: "Kirgizië",
  COUNTRY_LA: "Lao Democratische Volksrepubliek",
  COUNTRY_LV: "Letland",
  COUNTRY_LB: "Libanon",
  COUNTRY_LS: "Lesotho",
  COUNTRY_LR: "Liberia",
  COUNTRY_LY: "Libië",
  COUNTRY_LI: "Liechtenstein",
  COUNTRY_LT: "Litouwen",
  COUNTRY_LU: "Luxemburg",
  COUNTRY_MO: "Macau",
  COUNTRY_MK: "Macedonië",
  COUNTRY_MG: "Madagaskar",
  COUNTRY_MW: "Malawi",
  COUNTRY_MY: "Maleisië",
  COUNTRY_MV: "Malediven",
  COUNTRY_ML: "Mali",
  COUNTRY_MT: "Mout",
  COUNTRY_MH: "Marshalleilanden",
  COUNTRY_MQ: "Martinique",
  COUNTRY_MR: "Mauritanië",
  COUNTRY_MU: "Mauritius",
  COUNTRY_YT: "Mayotte",
  COUNTRY_MX: "Mexico",
  COUNTRY_FM: "Micronesië",
  COUNTRY_MD: "Moldavië",
  COUNTRY_MC: "Monaco",
  COUNTRY_MN: "Mongolië",
  COUNTRY_ME: "Montenegro",
  COUNTRY_MS: "Montserrat",
  COUNTRY_MA: "Marokko",
  COUNTRY_MZ: "Mozambique",
  COUNTRY_MM: "Myanmar",
  COUNTRY_NA: "Namibië",
  COUNTRY_NR: "Nauru",
  COUNTRY_NP: "Nepal",
  COUNTRY_NL: "Nederland",
  COUNTRY_NC: "Nieuw-Caledonië",
  COUNTRY_NZ: "Nieuw-Zeeland",
  COUNTRY_NI: "Nicaragua",
  COUNTRY_NE: "Niger",
  COUNTRY_NG: "Nigeria",
  COUNTRY_NU: "Neen",
  COUNTRY_NF: "Norfolkeiland",
  COUNTRY_MP: "Noordelijke Marianen",
  COUNTRY_NO: "Noorwegen",
  COUNTRY_OM: "Oman",
  COUNTRY_PK: "Pakistan",
  COUNTRY_PW: "Paleis",
  COUNTRY_PS: "Palestina",
  COUNTRY_PA: "Panama",
  COUNTRY_PG: "Papoea-Nieuw-Guinea",
  COUNTRY_PY: "Paraguay",
  COUNTRY_PE: "Peru",
  COUNTRY_PH: "Filipijnen",
  COUNTRY_PN: "Pitcairn",
  COUNTRY_PL: "Polen",
  COUNTRY_PT: "Portugal",
  COUNTRY_PR: "Puerto Rico",
  COUNTRY_QA: "Qatar",
  COUNTRY_RE: "Reünie",
  COUNTRY_RO: "Roemenië",
  COUNTRY_RU: "Russische Federatie",
  COUNTRY_RW: "Rwanda",
  COUNTRY_BL: "Sint-Bartholomeus",
  COUNTRY_SH: "Sint-Helena",
  COUNTRY_KN: "Saint Kitts en Nevis",
  COUNTRY_LC: "Sint-Lucia",
  COUNTRY_MF: "Sint Maarten",
  COUNTRY_PM: "St. Peter en Miquelon",
  COUNTRY_VC: "St. Vincent en de Grenadines",
  COUNTRY_WS: "Samoa",
  COUNTRY_SM: "St. Marino",
  COUNTRY_ST: "Sao Tomé en Principe",
  COUNTRY_SA: "Saoedi-Arabië",
  COUNTRY_SN: "Senegal",
  COUNTRY_RS: "Servië",
  COUNTRY_SC: "Seychellen",
  COUNTRY_SL: "Sierra Leone",
  COUNTRY_SG: "Singapore",
  COUNTRY_SX: "Sint Maarten",
  COUNTRY_SK: "Slowakije",
  COUNTRY_IF: "Slovenië",
  COUNTRY_SB: "Salomonseilanden",
  COUNTRY_SO: "Somalië",
  COUNTRY_ZA: "Zuid-Afrika",
  COUNTRY_SS: "Zuid-Soedan",
  COUNTRY_GS: "Zuid-Georgië en de Zuidelijke Sandwicheilanden",
  COUNTRY_ES: "Spanje",
  COUNTRY_LK: "Sri Lanka",
  COUNTRY_SD: "Soedan",
  COUNTRY_SR: "Suriname",
  COUNTRY_SJ: "Svalbard en het zuiden",
  COUNTRY_SZ: "Swaziland",
  COUNTRY_SE: "Zweden",
  COUNTRY_CH: "Zwitserland",
  COUNTRY_SY: "Syrische Arabische Republiek",
  COUNTRY_TW: "Taiwan, provincie China",
  COUNTRY_TJ: "Tadzjikistan",
  COUNTRY_TZ: "Tanzania, Verenigde Republiek",
  COUNTRY_TH: "Thailand",
  COUNTRY_TL: "Timor-Leste",
  COUNTRY_TG: "Togo",
  COUNTRY_TK: "TK",
  COUNTRY_TO: "Bouw",
  COUNTRY_TT: "Trinidad en Tobago",
  COUNTRY_TN: "Tunesië",
  COUNTRY_TR: "Turkije",
  COUNTRY_TM: "Turkmenistan",
  COUNTRY_TC: "Turks- en Caicoseilanden",
  COUNTRY_TV: "Tuvalu",
  COUNTRY_UG: "Oeganda",
  COUNTRY_UA: "Oekraïne",
  COUNTRY_AE: "Verenigde Arabische Emiraten",
  COUNTRY_GB: "Verenigd Koninkrijk",
  COUNTRY_VS: "Verenigde Staten",
  COUNTRY_UM: "Verenigde Staten kleine afgelegen eilanden",
  COUNTRY_UY: "Uruguay",
  COUNTRY_UZ: "Oezbekistan",
  COUNTRY_VU: "Vanuatu",
  COUNTRY_VE: "Venezuela",
  COUNTRY_VN: "Vietnam",
  COUNTRY_VG: "Maagdeneilanden, Brits",
  COUNTRY_VI: "Maagdeneilanden, V.S.",
  COUNTRY_WF: "Wallis en Futuna",
  COUNTRY_EH: "Westelijke Sahara",
  COUNTRY_YE: "Jemen",
  COUNTRY_ZM: "Zambia",
  COUNTRY_ZW: "Zimbabwe"
};