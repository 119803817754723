export const Countries = {
  COUNTRY_AF: "Afghanistan",
  COUNTRY_AX: "Åland Islands",
  COUNTRY_AL: "Albania",
  COUNTRY_DZ: "Algeria",
  COUNTRY_AS: "American Samoa",
  COUNTRY_AD: "Andorra",
  COUNTRY_AO: "Angola",
  COUNTRY_AI: "Anguilla",
  COUNTRY_AQ: "Antarctica",
  COUNTRY_AG: "Antigua and Barbuda",
  COUNTRY_AR: "Argentina",
  COUNTRY_AM: "Armenia",
  COUNTRY_AW: "Aruba",
  COUNTRY_AU: "Australia",
  COUNTRY_AT: "Austria",
  COUNTRY_AZ: "Azerbaijan",
  COUNTRY_BS: "Bahamas",
  COUNTRY_BH: "Bahrain",
  COUNTRY_BD: "Bangladesh",
  COUNTRY_BB: "Barbados",
  COUNTRY_BY: "Belarus",
  COUNTRY_BE: "Belgium",
  COUNTRY_BZ: "Belize",
  COUNTRY_BJ: "Benin",
  COUNTRY_BM: "Bermuda",
  COUNTRY_BT: "Bhutan",
  COUNTRY_BO: "Bolivia",
  COUNTRY_BQ: "Bonaire, Sint Eustatius and Saba",
  COUNTRY_BA: "Bosnia and Herzegovina",
  COUNTRY_BW: "Botswana",
  COUNTRY_BV: "Bouvet Island",
  COUNTRY_BR: "Brazil",
  COUNTRY_IO: "British Indian Ocean Territory",
  COUNTRY_BN: "Brunei Darussalam",
  COUNTRY_BG: "Bulgaria",
  COUNTRY_BF: "Burkina Faso",
  COUNTRY_BI: "Burundi",
  COUNTRY_KH: "Cambodia",
  COUNTRY_CM: "Cameroon",
  COUNTRY_CA: "Canada",
  COUNTRY_CV: "Cape Verde",
  COUNTRY_KY: "Cayman Islands",
  COUNTRY_CF: "Central African Republic",
  COUNTRY_TD: "Chad",
  COUNTRY_CL: "Chile",
  COUNTRY_CN: "China",
  COUNTRY_CX: "Christmas Island",
  COUNTRY_CC: "Cocos (Keeling Islands",
  COUNTRY_CO: "Colombia",
  COUNTRY_KM: "Comoros",
  COUNTRY_CG: "Congo",
  COUNTRY_CD: "The Democratic Republic of The Congo",
  COUNTRY_CK: "Cook Islands",
  COUNTRY_CR: "Costa Rica",
  COUNTRY_CI: "Cote D'ivoire",
  COUNTRY_CW: "Curaçao",
  COUNTRY_HR: "Croatia",
  COUNTRY_CU: "Cuba",
  COUNTRY_CY: "Cyprus",
  COUNTRY_CZ: "Czech Republic",
  COUNTRY_DK: "Denmark",
  COUNTRY_DJ: "Djibouti",
  COUNTRY_DM: "Dominica",
  COUNTRY_DO: "Dominican Republic",
  COUNTRY_EC: "Ecuador",
  COUNTRY_EG: "Egypt",
  COUNTRY_SV: "El Salvador",
  COUNTRY_GQ: "Equatorial Guinea",
  COUNTRY_ER: "Eritrea",
  COUNTRY_EE: "Estonia",
  COUNTRY_ET: "Ethiopia",
  COUNTRY_FK: "Falkland Islands (Malvinas",
  COUNTRY_FO: "Faroe Islands",
  COUNTRY_FJ: "Fiji",
  COUNTRY_FI: "Finland",
  COUNTRY_FR: "France",
  COUNTRY_GF: "French Guiana",
  COUNTRY_PF: "French Polynesia",
  COUNTRY_TF: "French Southern Territories",
  COUNTRY_GA: "Gabon",
  COUNTRY_GM: "Gambia",
  COUNTRY_GE: "Georgia",
  COUNTRY_DE: "Germany",
  COUNTRY_GH: "Ghana",
  COUNTRY_GI: "Gibraltar",
  COUNTRY_GR: "Greece",
  COUNTRY_GL: "Greenland",
  COUNTRY_GD: "Grenada",
  COUNTRY_GP: "Guadeloupe",
  COUNTRY_GU: "Guam",
  COUNTRY_GT: "Guatemala",
  COUNTRY_GG: "Guernsey",
  COUNTRY_GN: "Guinea",
  COUNTRY_GW: "Guinea-bissau",
  COUNTRY_GY: "Guyana",
  COUNTRY_HT: "Haiti",
  COUNTRY_HM: "Heard Island and Mcdonald Islands",
  COUNTRY_VA: "Holy See (Vatican City State",
  COUNTRY_HN: "Honduras",
  COUNTRY_HK: "Hong Kong",
  COUNTRY_HU: "Hungary",
  COUNTRY_IS: "Iceland",
  COUNTRY_IN: "India",
  COUNTRY_ID: "Indonesia",
  COUNTRY_IR: "Iran",
  COUNTRY_IQ: "Iraq",
  COUNTRY_IE: "Ireland",
  COUNTRY_IM: "Isle of Man",
  COUNTRY_IL: "Israel",
  COUNTRY_IT: "Italy",
  COUNTRY_JM: "Jamaica",
  COUNTRY_JP: "Japan",
  COUNTRY_JE: "Jersey",
  COUNTRY_JO: "Jordan",
  COUNTRY_KZ: "Kazakhstan",
  COUNTRY_KE: "Kenya",
  COUNTRY_KI: "Kiribati",
  COUNTRY_KP: "Democratic People's Republic of Korea",
  COUNTRY_KR: "Republic of Korea",
  COUNTRY_KW: "Kuwait",
  COUNTRY_KG: "Kyrgyzstan",
  COUNTRY_LA: "Lao People\"s Democratic Republic",
  COUNTRY_LV: "Latvia",
  COUNTRY_LB: "Lebanon",
  COUNTRY_LS: "Lesotho",
  COUNTRY_LR: "Liberia",
  COUNTRY_LY: "Libya",
  COUNTRY_LI: "Liechtenstein",
  COUNTRY_LT: "Lithuania",
  COUNTRY_LU: "Luxembourg",
  COUNTRY_MO: "Macao",
  COUNTRY_MK: "Macedonia",
  COUNTRY_MG: "Madagascar",
  COUNTRY_MW: "Malawi",
  COUNTRY_MY: "Malaysia",
  COUNTRY_MV: "Maldives",
  COUNTRY_ML: "Mali",
  COUNTRY_MT: "Malta",
  COUNTRY_MH: "Marshall Islands",
  COUNTRY_MQ: "Martinique",
  COUNTRY_MR: "Mauritania",
  COUNTRY_MU: "Mauritius",
  COUNTRY_YT: "Mayotte",
  COUNTRY_MX: "Mexico",
  COUNTRY_FM: "Micronesia",
  COUNTRY_MD: "Moldova",
  COUNTRY_MC: "Monaco",
  COUNTRY_MN: "Mongolia",
  COUNTRY_ME: "Montenegro",
  COUNTRY_MS: "Montserrat",
  COUNTRY_MA: "Morocco",
  COUNTRY_MZ: "Mozambique",
  COUNTRY_MM: "Myanmar",
  COUNTRY_NA: "Namibia",
  COUNTRY_NR: "Nauru",
  COUNTRY_NP: "Nepal",
  COUNTRY_NL: "Netherlands",
  COUNTRY_NC: "New Caledonia",
  COUNTRY_NZ: "New Zealand",
  COUNTRY_NI: "Nicaragua",
  COUNTRY_NE: "Niger",
  COUNTRY_NG: "Nigeria",
  COUNTRY_NU: "Niue",
  COUNTRY_NF: "Norfolk Island",
  COUNTRY_MP: "Northern Mariana Islands",
  COUNTRY_NO: "Norway",
  COUNTRY_OM: "Oman",
  COUNTRY_PK: "Pakistan",
  COUNTRY_PW: "Palau",
  COUNTRY_PS: "Palestinia",
  COUNTRY_PA: "Panama",
  COUNTRY_PG: "Papua New Guinea",
  COUNTRY_PY: "Paraguay",
  COUNTRY_PE: "Peru",
  COUNTRY_PH: "Philippines",
  COUNTRY_PN: "Pitcairn",
  COUNTRY_PL: "Poland",
  COUNTRY_PT: "Portugal",
  COUNTRY_PR: "Puerto Rico",
  COUNTRY_QA: "Qatar",
  COUNTRY_RE: "Reunion",
  COUNTRY_RO: "Romania",
  COUNTRY_RU: "Russian Federation",
  COUNTRY_RW: "Rwanda",
  COUNTRY_BL: "Saint Barthélemy",
  COUNTRY_SH: "Saint Helena",
  COUNTRY_KN: "Saint Kitts and Nevis",
  COUNTRY_LC: "Saint Lucia",
  COUNTRY_MF: "Saint Martin",
  COUNTRY_PM: "Saint Pierre and Miquelon",
  COUNTRY_VC: "Saint Vincent and The Grenadines",
  COUNTRY_WS: "Samoa",
  COUNTRY_SM: "San Marino",
  COUNTRY_ST: "Sao Tome and Principe",
  COUNTRY_SA: "Saudi Arabia",
  COUNTRY_SN: "Senegal",
  COUNTRY_RS: "Serbia",
  COUNTRY_SC: "Seychelles",
  COUNTRY_SL: "Sierra Leone",
  COUNTRY_SG: "Singapore",
  COUNTRY_SX: "Sint Maarten",
  COUNTRY_SK: "Slovakia",
  COUNTRY_SI: "Slovenia",
  COUNTRY_SB: "Solomon Islands",
  COUNTRY_SO: "Somalia",
  COUNTRY_ZA: "South Africa",
  COUNTRY_SS: "South Sudan",
  COUNTRY_GS: "South Georgia and The South Sandwich Islands",
  COUNTRY_ES: "Spain",
  COUNTRY_LK: "Sri Lanka",
  COUNTRY_SD: "Sudan",
  COUNTRY_SR: "Suriname",
  COUNTRY_SJ: "Svalbard and Jan Mayen",
  COUNTRY_SZ: "Swaziland",
  COUNTRY_SE: "Sweden",
  COUNTRY_CH: "Switzerland",
  COUNTRY_SY: "Syrian Arab Republic",
  COUNTRY_TW: "Taiwan, Province of China",
  COUNTRY_TJ: "Tajikistan",
  COUNTRY_TZ: "Tanzania, United Republic of",
  COUNTRY_TH: "Thailand",
  COUNTRY_TL: "Timor-leste",
  COUNTRY_TG: "Togo",
  COUNTRY_TK: "Tokelau",
  COUNTRY_TO: "Tonga",
  COUNTRY_TT: "Trinidad and Tobago",
  COUNTRY_TN: "Tunisia",
  COUNTRY_TR: "Turkey",
  COUNTRY_TM: "Turkmenistan",
  COUNTRY_TC: "Turks and Caicos Islands",
  COUNTRY_TV: "Tuvalu",
  COUNTRY_UG: "Uganda",
  COUNTRY_UA: "Ukraine",
  COUNTRY_AE: "United Arab Emirates",
  COUNTRY_GB: "United Kingdom",
  COUNTRY_US: "United States",
  COUNTRY_UM: "United States Minor Outlying Islands",
  COUNTRY_UY: "Uruguay",
  COUNTRY_UZ: "Uzbekistan",
  COUNTRY_VU: "Vanuatu",
  COUNTRY_VE: "Venezuela",
  COUNTRY_VN: "Vietnam",
  COUNTRY_VG: "Virgin Islands, British",
  COUNTRY_VI: "Virgin Islands, U.S.",
  COUNTRY_WF: "Wallis and Futuna",
  COUNTRY_EH: "Western Sahara",
  COUNTRY_YE: "Yemen",
  COUNTRY_ZM: "Zambia",
  COUNTRY_ZW: "Zimbabwe"
};