import React, {FC, useEffect, useState} from 'react';
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControlLabel,
  FormGroup, InputAdornment,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {ServiceInterface} from "../../services/service/interface";
import {apiUpdateService} from "../../api/services/api";
import {ChargeIntervalSelect} from "../../components/form/ChargeIntervalSelect";

export interface Props {
  service: ServiceInterface|null,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}

export const EditServiceDialog: FC<Props> = ({service, open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [name, setName] = useState(service?.name ?? "");
  const [price, setPrice] = useState(service?.base_price ?? null);
  const [priceInclTax, setPriceInclTax] = useState(service?.price_incl_tax ?? false);
  const [taxPercentage, setTaxPercentage] = useState(service?.tax_percentage ?? 21);
  const [chargeInterval, setChargeInterval] = useState(service?.charge_interval ?? "monthly");
  const [type, setType] = useState(service?.type ?? "");

  useEffect(() => {
    setName(service?.name ?? "");
    setPrice(service?.base_price ?? null);
    setPriceInclTax(service?.price_incl_tax ?? false);
    setTaxPercentage(service?.tax_percentage ?? 21);
    setChargeInterval(service?.charge_interval ?? "monthly");
    setType(service?.type ?? "");
  }, [service]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    apiUpdateService({
      id: service?.id ?? "",
      name,
      type,
      base_price: price ?? undefined,
      price_incl_tax: priceInclTax,
      tax_percentage: taxPercentage,
      charge_interval: chargeInterval,
    }).then(() => {
      toast.success(t("SERVICE_EDITED"));
      handleClose();
      if (onSubmit) {
        onSubmit();
      }
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("EDIT_SERVICE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("EDIT_SERVICE_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <TextField label={t("NAME")} type={"text"} value={name} onChange={e => setName(e.target.value)} />
            <TextField label={t("TYPE")} type={"text"} value={type} onChange={e => setType(e.target.value)} />
            <ChargeIntervalSelect label={t("CHARGE_INTERVAL")} identifier={"charge_interval"} value={chargeInterval} onChange={setChargeInterval} />
            <TextField InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
            }} label={t("PRICE")} type={"number"} value={price} onChange={e => setPrice(parseFloat(e.target.value))} />
            <TextField InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} label={t("TAX_PERCENTAGE")} type={"number"} value={taxPercentage} onChange={e => setTaxPercentage(parseFloat(e.target.value))} />
            <FormControlLabel control={<Checkbox
              checked={priceInclTax}
              onChange={e => setPriceInclTax(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t("PRICE_INCL_TAX")} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
