import {getAuthApiInstance} from "../api";
import {getPaymentResult, getPaymentsResult} from "./results";
import {createPaymentParams, getPaymentParams, getPaymentsParams, updatePaymentParams} from "./params";
import {RequestCriteria, UrlHelper} from "../../helper/url";

/**
 * Payments
 */
export const apiGetPayments = (params: getPaymentsParams): Promise<getPaymentsResult> => {
  let criteria: Array<RequestCriteria> = [];
  if (params.invoice_id) {
    criteria.push({field: 'invoice_id', comp: 'eq', value: params.invoice_id});
  }

  return getAuthApiInstance().get(`payments${UrlHelper.parseSearchCriteria(criteria)}`).then((result) => {
    return result.data as getPaymentsResult;
  });
}

export const apiGetPayment = (params: getPaymentParams): Promise<getPaymentResult> => {
  return getAuthApiInstance().get("payments/" + params.id).then((result) => {
    return result.data as getPaymentResult;
  });
}

export const apiCreatePayment = (params: createPaymentParams): Promise<getPaymentResult> => {
  return getAuthApiInstance().put("payments", params).then((result) => {
    return result.data as getPaymentResult;
  });
}

export const apiUpdatePayment = (params: updatePaymentParams): Promise<getPaymentResult> => {
  const {id, ...restParams} = params;
  return getAuthApiInstance().post("payments/" + id, restParams).then((result) => {
    return result.data as getPaymentResult;
  });
}

export const apiDeletePayment = (params: getPaymentParams): Promise<void> => {
  return getAuthApiInstance().delete("payments/" + params.id);
}
