import {getAuthApiInstance} from "../api";
import {getSettingResult, getSettingsResult} from "./results";
import {updateSettingParams} from "./params";

/**
 * Settings
 */
export const apiGetSettings = (): Promise<getSettingsResult> => {
  return getAuthApiInstance().get("settings").then((result) => {
    return result.data as getSettingsResult;
  });
}

export const apiUpdateSetting = (params: updateSettingParams): Promise<getSettingResult> => {
  const {key, ...restParams} = params;
  return getAuthApiInstance().post("settings/" + key, restParams).then((result) => {
    return result.data as getSettingResult;
  });
}
