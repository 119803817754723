import {
  getUserResult,
  getUsersResult,
} from "./results";
import {
  createUserParams,
  getUserParams,
  updateUserParams, validateUserParams,
} from "./params";
import {getAuthApiInstance} from "../api";

/**
 * Users
 */
export const apiGetUsers = (): Promise<getUsersResult> => {
  return getAuthApiInstance().get("users").then((result) => {
    return result.data as getUsersResult;
  });
}

export const apiGetUser = (params: getUserParams): Promise<getUserResult> => {
  return getAuthApiInstance().get("users/" + params.id).then((result) => {
    return result.data as getUserResult;
  });
}

export const apiCreateUser = (params: createUserParams): Promise<getUserResult> => {
  return getAuthApiInstance().put("users", params).then((result) => {
    return result.data as getUserResult;
  });
}

export const apiUpdateUser = (params: updateUserParams): Promise<getUserResult> => {
  const {id, ...restParams} = params;
  return getAuthApiInstance().post("users/" + id, restParams).then((result) => {
    return result.data as getUserResult;
  });
}

export const apiValidateUser = (params: validateUserParams): Promise<void> => {
  const {token, ...restParams} = params;
  return getAuthApiInstance().post("users/validate/" + token, restParams);
}

export const apiDeleteUser = (params: getUserParams): Promise<void> => {
  return getAuthApiInstance().delete("users/" + params.id);
}
