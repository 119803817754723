import React, {useEffect, useState} from 'react';
import {useAppContext, useConfirmDialogContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import AddCircle from '@mui/icons-material/AddCircle';
import {
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import moment from "moment/moment";
import {apiDeleteUser, apiGetUsers} from "../../api/users/api";
import {CreateUserDialog} from "./CreateUserDialog";
import {EditUserDialog} from "./EditUserDialog";
import {UserInterface} from "../../services/user/interface";
import {MenuButton} from "../../components/layout/MenuButton";
import {LoadingRow} from "../../components/layout/LoadingRow";
import {ErrorHelper} from "../../helper/errors";
import {toast} from "react-toastify";

export default function UserScene() {
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [currentEditUser, setCurrentEditUser] = useState<UserInterface|null>(null);
  const users = useQuery(['users'], apiGetUsers);
  const [openUserCreate, setOpenUserCreate] = useState(false);
  const [openUserEdit, setOpenUserEdit] = useState(false);
  const confirmDialog = useConfirmDialogContext();

  useEffect(() => {
    appContext?.setTitle(t("USERS"));
  }, [appContext, t]);

  const handleEdit = (row: UserInterface) => {
    setCurrentEditUser(row);
    setOpenUserEdit(true);
  };

  const handleDelete = (row: UserInterface) => {
    confirmDialog?.confirm(
      t("DELETE_USER_QUESTION"),
      t("DELETE_USER_QUESTION_EXPLANATION"),
      () => () => {
        apiDeleteUser({id: row.id}).then(() => {
          toast.success(t("USER_DELETED"));
          users.refetch();
        }).catch((result) => {
          ErrorHelper.toastLaraFail(result);
        })
      }
    );
  };

  return (
    <>
      <h1>
        {t("USERS")}
        <IconButton aria-label="create" color="success" onClick={() => {setOpenUserCreate(true)}}>
          <AddCircle />
        </IconButton>
      </h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="user table">
          <TableHead>
            <TableRow>
              <TableCell>{t("FIRST_NAME")}</TableCell>
              <TableCell align="right">{t("INSERTION")}</TableCell>
              <TableCell align="right">{t("LAST_NAME")}</TableCell>
              <TableCell align="right">{t("EMAIL")}</TableCell>
              <TableCell align="right">{t("CREATED_AT")}</TableCell>
              <TableCell align="right">{t("UPDATED_AT")}</TableCell>
              <TableCell align="right">{t("ACTIONS")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.isLoading ? (
              <LoadingRow colspan={7} />
            ) : (users.data?.items.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.first_name}</TableCell>
                <TableCell align="right">{row.insertion}</TableCell>
                <TableCell align="right">{row.last_name}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{moment.unix(row.created_at).format("DD-MM-YYYY HH:mm")}</TableCell>
                <TableCell align="right">{moment.unix(row.updated_at).format("DD-MM-YYYY HH:mm")}</TableCell>
                <TableCell align="right">
                  <MenuButton>
                    <MenuItem key={'edit-' + row.id} onClick={() => handleEdit(row)}>
                      {t("EDIT")}
                    </MenuItem>
                    <MenuItem key={'delete-' + row.id} onClick={() => handleDelete(row)}>
                      {t("DELETE")}
                    </MenuItem>
                  </MenuButton>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateUserDialog open={openUserCreate} setOpen={setOpenUserCreate} onSubmit={() => users.refetch()}/>
      <EditUserDialog open={openUserEdit} setOpen={setOpenUserEdit} user={currentEditUser} onSubmit={() => users.refetch()}/>
    </>
  );
}
