export const Settings = {
  provider_name: "Provider name",
  provider_address: "Provider address",
  provider_house_number: "Provider house number",
  provider_postal_code: "Provider postal code",
  provider_city: "Provider city",
  provider_country_code: "Provider country code",
  provider_kvk: "Provider KvK",
  provider_tax_number: "Provider tax number",
  provider_phone_number: "Provider phone number",
  provider_email: "Provider email",
  provider_iban: "Provider IBAN",
  mollie_access_token: "Mollie access token",
  mka_client_id: "MKA client ID",
  mka_client_secret: "MKA client secret",
  zendoc_access_token: "Zendoc access token",
  legalsign_access_token: "Legalsign access token",
  invoice_number_template: "Invoice number template",
  invoice_number_length: "Invoice number length",
  invoice_current_number: "Invoice current number",
}
