import {getAuthApiInstance} from "../api";
import {getProductResult, getProductsResult} from "./results";
import {createProductParams, getProductParams, updateProductParams} from "./params";

/**
 * Products
 */
export const apiGetProducts = (): Promise<getProductsResult> => {
  return getAuthApiInstance().get("products?sort[field]=name&sort[direction]=asc").then((result) => {
    return result.data as getProductsResult;
  });
}

export const apiGetProduct = (params: getProductParams): Promise<getProductResult> => {
  return getAuthApiInstance().get("products/" + params.id).then((result) => {
    return result.data as getProductResult;
  });
}

export const apiCreateProduct = (params: createProductParams): Promise<getProductResult> => {
  return getAuthApiInstance().put("products", params).then((result) => {
    return result.data as getProductResult;
  });
}

export const apiUpdateProduct = (params: updateProductParams): Promise<getProductResult> => {
  const {id, ...restParams} = params;
  return getAuthApiInstance().post("products/" + id, restParams).then((result) => {
    return result.data as getProductResult;
  });
}

export const apiDeleteProduct = (params: getProductParams): Promise<void> => {
  return getAuthApiInstance().delete("products/" + params.id);
}
