import {toast} from "react-toastify";

export class ErrorHelper {
  static toastLaraFail(error: any): void {
    if (error.response.data.message) {
      toast.error(error.response.data.message);
    } else if (error.response?.data && typeof error.response.data === "object") {
      let responseData = error.response.data;
      Object.keys(responseData).map((item: string) => {
        responseData[item].map((entry: string) => {
          toast.error(entry);

          return entry;
        });

        return item;
      });
    }
  }
}