import * as React from "react";
import {TableCell, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";

interface Props {
  colspan: number;
}

export const LoadingRow: React.FC<any> = (props: Props) => {
  const {t} = useTranslation();
  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell colSpan={props.colspan} component="th" scope="row">
        {t("LOADING")}
      </TableCell>
    </TableRow>
  );
}
