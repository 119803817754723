import React, {useEffect, useState} from 'react';
import {useAppContext, useConfirmDialogContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import AddCircle from '@mui/icons-material/AddCircle';
import {
  IconButton,
  MenuItem,
} from '@mui/material';
import moment from "moment/moment";
import {MenuButton} from "../../components/layout/MenuButton";
import {LoadingRow} from "../../components/layout/LoadingRow";
import {ErrorHelper} from "../../helper/errors";
import {toast} from "react-toastify";
import {EditProductDialog} from "./EditProductDialog";
import {apiDeleteProduct, apiGetProducts} from "../../api/products/api";
import {ProductInterface} from "../../services/product/interface";
import {CreateProductDialog} from "./CreateProductDialog";
import {Table, TableCell, TableRow, TableBody, TableHead} from "../../components/layout/Table";

export default function ProductScene() {
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [currentEditProduct, setCurrentEditProduct] = useState<ProductInterface|null>(null);
  const products = useQuery(['products'], apiGetProducts);
  const [openProductCreate, setOpenProductCreate] = useState(false);
  const [openProductEdit, setOpenProductEdit] = useState(false);
  const confirmDialog = useConfirmDialogContext();

  useEffect(() => {
    appContext?.setTitle(t("PRODUCTS"));
  }, [appContext, t]);

  const handleEdit = (row: ProductInterface) => {
    setCurrentEditProduct(row);
    setOpenProductEdit(true);
  };

  const handleDelete = (row: ProductInterface) => {
    confirmDialog?.confirm(
      t("DELETE_PRODUCT_QUESTION"),
      t("DELETE_PRODUCT_QUESTION_EXPLANATION"),
      () => () => {
        apiDeleteProduct({id: row.id}).then(() => {
          toast.success(t("PRODUCT_DELETED"));
          products.refetch();
        }).catch((result) => {
          ErrorHelper.toastLaraFail(result);
        })
      }
    );
  };

  return (
    <>
      <h1>
        {t("PRODUCTS")}
        <IconButton aria-label="create" color="success" onClick={() => {setOpenProductCreate(true)}}>
          <AddCircle />
        </IconButton>
      </h1>
      <Table sx={{ minWidth: 650 }} aria-label="product table">
        <TableHead>
          <TableRow>
            <TableCell align={"left"}>{t("NAME")}</TableCell>
            <TableCell align="right">{t("TYPE")}</TableCell>
            <TableCell align="right">{t("BASE_PRICE")}</TableCell>
            <TableCell align="right">{t("PRICE_INCL_TAX")}</TableCell>
            <TableCell align="right">{t("TAX_PERCENTAGE")}</TableCell>
            <TableCell align="right">{t("CREATED_AT")}</TableCell>
            <TableCell align="right">{t("UPDATED_AT")}</TableCell>
            <TableCell align="right">{t("ACTIONS")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.isLoading ? (
            <LoadingRow colspan={7} />
          ) : (products.data?.items.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="right">{t(row.type.toUpperCase())}</TableCell>
              <TableCell align="right">€{row.base_price}</TableCell>
              <TableCell align="right">{row.price_incl_tax ? t("YES") : t("NO")}</TableCell>
              <TableCell align="right">{row.tax_percentage}%</TableCell>
              <TableCell align="right">{moment.unix(row.created_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">{moment.unix(row.updated_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">
                <MenuButton>
                  <MenuItem key={'edit-' + row.id} onClick={() => handleEdit(row)}>
                    {t("EDIT")}
                  </MenuItem>
                  <MenuItem key={'delete-' + row.id} onClick={() => handleDelete(row)}>
                    {t("DELETE")}
                  </MenuItem>
                </MenuButton>
              </TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
      <CreateProductDialog open={openProductCreate} setOpen={setOpenProductCreate} onSubmit={() => products.refetch()}/>
      <EditProductDialog open={openProductEdit} setOpen={setOpenProductEdit} product={currentEditProduct} onSubmit={() => products.refetch()}/>
    </>
  );
}
