import {useQuery} from "@tanstack/react-query";
import {FC} from "react";
import {SelectInput} from "./SelectInput";
import {FormGroup} from "@mui/material";
import {apiGetProducts} from "../../api/products/api";
import {ProductInterface} from "../../services/product/interface";
import {useTranslation} from "react-i18next";

export interface Props {
  onChange: (value: string) => void,
  value?: string,
  label: string,
  identifier: string,
  optional?: boolean,
  onSelect?: (row: ProductInterface) => void,
}

export const ProductsSelect: FC<Props> = ({onChange, value, label, identifier, optional, onSelect}) => {
  const products = useQuery(['products'], apiGetProducts);
  const {t} = useTranslation();

  const options = (): Array<{value: string, label: string}> => {
    let optionsList: Array<{value: string, label: string}> = [];
    if (optional || typeof optional === "undefined") {
      optionsList.push({value: "", label: t("NONE")});
    }

    products.data?.items.forEach((row) => {
      optionsList.push({value: row.id, label: row.name});
    });

    return optionsList;
  };

  const onChangeWrapper = (input: string): void => {
    onChange(input);
    if (onSelect) {
      products.data?.items.forEach((row) => {
        if (row.id === input) {
          onSelect(row);
        }
      });
    }
  };

  return ( !products.isLoading ?
    <SelectInput identifier={identifier} label={label} onChange={onChangeWrapper} value={value} options={options()}/> : <FormGroup>Loading...</FormGroup>
  );
}
