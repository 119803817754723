import * as React from "react";
import {TableCell, TableRow, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {CardWrapper} from "./Card";
import {FilterBar, Props as FilterBarProps} from "./FilterBar";

interface Props extends FilterBarProps {

}

export const FilterCard: React.FC<any> = (props: React.PropsWithChildren<Props>) => {
  const {t} = useTranslation();
  return (
    <CardWrapper size={"fullCard"}>
      <Typography gutterBottom variant={"caption"}>
        {t("FILTERS")}
      </Typography>
      <FilterBar {...props} />
    </CardWrapper>
  );
}
