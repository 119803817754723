import * as React from "react";
import {MenuItem} from "@mui/material";
import {MenuItemProps} from "@mui/material/MenuItem/MenuItem";
import {Link} from "react-router-dom";

export interface MenuItemLinkProps extends MenuItemProps {
  to: string;
}

export const MenuItemLink: React.FC<any> = (props: MenuItemLinkProps) => {
  const { to, ...restProps } = props;

  return (
    <Link to={to} className={"menuItemLink"}>
      <MenuItem {...restProps}>{props.children}</MenuItem>
    </Link>
  );
}