import React from 'react';
import {Route, Routes} from "react-router-dom";
import Dashboard from "./scenes/Dashboard";
import Login from "./scenes/login/Login";
import {useAuth} from "./services/authentication/context";
import {useAppContext} from "./services/app/context";
import UserScene from "./scenes/user/UserScene";
import SettingsScene from "./scenes/settings/SettingsScene";
import ValidateUserScene from "./scenes/user/ValidateUserScene";
import CustomerScene from "./scenes/customer/CustomerScene";
import SystemSettingsScene from "./scenes/system_settings/SystemSettingsScene";
import ProductScene from "./scenes/product/ProductScene";
import ProductBundleScene from "./scenes/product_bundle/ProductBundleScene";
import ServiceScene from "./scenes/service/ServiceScene";
import CustomerServiceScene from "./scenes/customer/CustomerServiceScene";
import ServiceVariableScene from "./scenes/service/ServiceVariableScene";
import LedgerScene from "./scenes/ledger/LedgerScene";
import InvoiceScene from "./scenes/invoice/InvoiceScene";
import InvoiceDetailScene from "./scenes/invoice/InvoiceDetailScene";
import OverviewScene from "./scenes/overview/OverviewScene";
import ProductBundleProductScene from "./scenes/product_bundle/ProductBundleProductScene";

function AppRouter() {
  const authentication = useAuth();
  const appContext = useAppContext();

  if (!appContext?.isLoading) {
    if (!authentication?.isAuthenticated) {
      authentication?.getAccessToken();

      return (
        <Routes>
          <Route path={"forgot-password"} element={<h1>hi</h1>}/>
          <Route path={"*"} element={<Login/>} />
          <Route path={"validate-user/:token"} element={<ValidateUserScene/>} />
        </Routes>
      );
    }

    return (
      <Routes>
        <Route path={"/"} element={<Dashboard />}>
          <Route path={"/customers"} element={<CustomerScene />}/>
          <Route path={"/ledger"} element={<LedgerScene />}/>
          <Route path={"/invoices"} element={<InvoiceScene />}/>
          <Route path={"/invoices/:invoiceId"} element={<InvoiceDetailScene />}/>
          <Route path={"/customers/:customerId/services"} element={<CustomerServiceScene />}/>
          <Route path={"/products"} element={<ProductScene />}/>
          <Route path={"/product_bundles"} element={<ProductBundleScene />}/>
          <Route path={"/product_bundles/:productBundleId/products"} element={<ProductBundleProductScene />}/>
          <Route path={"/services"} element={<ServiceScene />}/>
          <Route path={"/services/:serviceId/variables"} element={<ServiceVariableScene />}/>
          <Route path={"/users"} element={<UserScene />}/>
          <Route path={"/settings"} element={<SettingsScene />}/>
          <Route path={"/system_settings"} element={<SystemSettingsScene />}/>
          <Route index element={<OverviewScene />}/>
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    );
  }

  return (<></>);
}

export default AppRouter;
