export const General = {
  LOGIN_TITLE: "Inloggen",
  USERNAME: "Gebruikersnaam",
  PASSWORD: "Wachtwoord",
  LOGIN_SUBMIT: "Login",
  FORGOT_PASSWORD: "Wachtwoord vergeten?",
  LOGOUT: "Uitloggen",
  SETTINGS: "Instellingen",
  DASHBOARD: "Dashboard",
  NAME: "Naam",
  DESCRIPTION: "Beschrijving",
  CREATED_AT: "Aangemaakt op",
  UPDATED_AT: "Laatste update",
  ACTIONS: "Acties",
  EDIT: "Bewerken",
  DELETE: "Verwijderen",
  VALUES: "Waarden",
  OPTIONS: "Opties",
  TYPE: "Type",
  VALUE: "Waarde",
  USERS: "Gebruikers",
  FIRST_NAME: "Voornaam",
  INSERTION: "Tussenvoegsel",
  LAST_NAME: "Achternaam",
  EMAIL: "E-mail",
  SUBMIT: "Opslaan",
  NEW_PASSWORD: "Nieuw wachtwoord",
  CONFIRM_PASSWORD: "Wachtwoord bevestigen",
  PASSWORD_UPDATED: "Wachtwoord geüpdatet",
  CANCEL: "Annuleren",
  CREATE_USER: "Gebruiker aanmaken",
  USER_CREATED: "Gebruiker aangemaakt",
  CREATE_USER_EXPLANATION: "Na het aanmaken van een gebruiker, wordt er een e-mail verstuurd om het wachtwoord in te stellen.",
  VALIDATE_TITLE: "Valideren en wachtwoord instellen",
  USER_EDITED: "Gebruiker bewerkt",
  EDIT_USER: "Gebruiker bewerken",
  EDIT_USER_EXPLANATION: "Hier kan je de basis informatie van een gebruiker bewerken.",
  YES: "Ja",
  NO: "Nee",
  DELETE_USER_QUESTION: "Weet je zeker dat je deze gebruiker wil verwijderen?",
  DELETE_USER_QUESTION_EXPLANATION: "Wanneer een gebruiker verwijderd wordt, heeft deze geen toegang meer tot het systeem. Om de gebruiker in toekomst weer toegang te geven, zal het account opnieuw aangemaakt moeten worden.",
  USER_DELETED: "Gebruiker verwijderd",
  CUSTOMERS: "Klanten",
  CUSTOMER: "Klant",
  LEDGER: "Boekingen",
  FOR: "voor",
  ADDRESS: "Adres",
  HOUSE_NUMBER: "Huisnummer",
  POSTAL_CODE: "Postcode",
  CITY: "Woonplaats",
  COUNTRY_CODE: "Landcode",
  KVK: "KvK",
  TAX_NUMBER: "Belastingnummer",
  PHONE_NUMBER: "Telefoonnummer",
  CREATE_CUSTOMER: "Klant aanmaken",
  CREATE_CUSTOMER_EXPLANATION: "Via dit scherm kan je een nieuwe klant aanmaken.",
  CUSTOMER_CREATED: "Klant aangemaakt",
  EDIT_CUSTOMER: "Klant bewerken",
  EDIT_CUSTOMER_EXPLANATION: "Via dit scherm kan je een bestaande klant bewerken.",
  CUSTOMER_EDITED: "Klant bewerkt",
  DELETE_CUSTOMER_QUESTION: "Weet je zeker dat je deze klant wil verwijderen?",
  DELETE_CUSTOMER_QUESTION_EXPLANATION: "Wanneer een klant verwijderd wordt, ontvangt deze geen updates meer van facturen.",
  CUSTOMER_DELETED: "Klant verwijderd",
  SYSTEM_SETTINGS: "Systeem instellingen",
  SETTING_EDITED: "Instelling bewerkt",
  EDIT_SETTING: "Instelling bewerken",
  NOT_SET: "Niet ingesteld",
  LEDGER_ENTRIES: "Boekingen",
  PRODUCTS: "Producten",
  BASE_PRICE: "Basis prijs",
  PRICE_INCL_TAX: "Prijs incl. BTW",
  TAX_PERCENTAGE: "BTW percentage",
  CREATE_PRODUCT: "Product aanmaken",
  CREATE_PRODUCT_EXPLANATION: "Op dit scherm kan je nieuwe producten aanmaken die verkocht kunnen worden aan klanten.",
  PRODUCT_CREATED: "Product aangemaakt",
  PRODUCT_EDITED: "Product bewerkt",
  PRODUCT_DELETED: "Product verwijderd",
  EDIT_PRODUCT: "Product bewerken",
  EDIT_PRODUCT_EXPLANATION: "Op dit scherm kan je een bestaand product aanpassen.",
  DELETE_PRODUCT_QUESTION: "Weet je zeker dat je dit product wil verwijderen?",
  DELETE_PRODUCT_QUESTION_EXPLANATION: "Wanneer je een product verwijderd, dan wordt deze uit het gehele systeem verwijderd, inclusief de referenties en prijzen in de boekingen.",
  SERVICES: "Diensten",
  CREATE_SERVICE: "Dienst aanmaken",
  CREATE_SERVICE_EXPLANATION: "Op dit scherm kan je nieuwe diensten aanmaken die door klanten afgenomen kunnen worden.",
  SERVICE_CREATED: "Dienst aangemaakt",
  SERVICE_EDITED: "Dienst bewerkt",
  SERVICE_DELETED: "Dienst verwijderd",
  EDIT_SERVICE: "Dienst bewerken",
  EDIT_SERVICE_EXPLANATION: "Op dit scherm kan je een bestaande dienst aanpassen.",
  DELETE_SERVICE_QUESTION: "Weet je zeker dat je deze dienst wil verwijderen?",
  DELETE_SERVICE_QUESTION_EXPLANATION: "Wanneer je een dienst verwijderd, dan wordt deze uit het gehele systeem verwijderd, inclusief de referenties en prijzen in de boekingen.",
  CHARGE_INTERVAL: "Betalingstermijn",
  DAILY: "Dagelijks",
  WEEKLY: "Wekelijks",
  MONTHLY: "Maandelijks",
  ANNUALLY: "Jaarlijks",
  CUSTOMER_SERVICES_FOR: "Diensten gekoppeld aan ",
  EXTERNAL_REFERENCE: "Externe referentie",
  QUANTITY: "Aantal",
  DISCOUNT: "Korting",
  DISCOUNT_INCL_TAX: "Korting incl. BTW",
  DISCOUNT_TYPE: "Soort korting",
  CUSTOMER_SERVICES: "Gekoppelde diensten",
  CONNECT_CUSTOMER_SERVICE: "Koppel dienst aan klant",
  CONNECT_CUSTOMER_SERVICE_EXPLANATION: "Hier kan je een nieuwe dienst koppelen aan een klant, deze zal dan op basis van variabelen en producten kosten berekenen aan klanten.",
  SERVICE: "Dienst",
  FIXED_PRICE: "Vaste prijs",
  PERCENTAGE: "Percentage",
  FIXED_PER_QUANTITY: "Vaste prijs per aantal",
  CUSTOMER_SERVICE_CONNECTED: "Dienst gekoppeld aan klant",
  UPDATE_CUSTOMER_SERVICE: "Bewerk dienst koppeling",
  UPDATE_CUSTOMER_SERVICE_EXPLANATION: "Hier kan je een bestaande koppeling van een dienst aan een klant bewerken.",
  CUSTOMER_SERVICE_EDITED: "Koppeling bewerkt",
  DISCONNECT_CUSTOMER_SERVICE_QUESTION: "Weet je zeker dat je deze dienst wil ontkoppelen van de klant?",
  DISCONNECT_CUSTOMER_SERVICE_QUESTION_EXPLANATION: "Zodra een dienst ontkoppelt wordt van een klant, worden de kosten niet langer toegevoegd aan de boekingen.",
  CUSTOMER_SERVICE_DISCONNECTED: "Dienst ontkoppelt",
  VARIABLES: "Variabelen",
  SERVICE_VARIABLES: "Dienst variabelen",
  SERVICE_VARIABLES_FOR: "Dienst variabelen voor",
  CREATE_SERVICE_VARIABLE: "Dienst variabele aanmaken",
  CREATE_SERVICE_VARIABLE_EXPLANATION: "Hier kan je nieuwe dienst variabelen aanmaken die invloed kunnen hebben op de prijs berekening.",
  FIRST_QUANTITY_FREE: "Eerste aantal gratis",
  PRODUCT: "Product",
  SERVICE_VARIABLE_CREATED: "Variabele aangemaakt",
  EDIT_SERVICE_VARIABLE: "Variabele bewerken",
  EDIT_SERVICE_VARIABLE_EXPLANATION: "Hier kan je een bestaande variabele aanpassen.",
  SERVICE_VARIABLE_EDITED: "Variabele bewerkt",
  DELETE_SERVICE_VARIABLE_QUESTION: "Weet je zeker dat je deze variabele wil verwijderen?",
  DELETE_SERVICE_VARIABLE_QUESTION_EXPLANATION: "Wanneer een variabele verwijderd wordt, kunnen berekeningen in de boekingen anders uitvallen.",
  SERVICE_VARIABLE_DELETED: "Variabele verwijderd",
  CONFIGURE: "Configureren",
  CONFIGURE_CUSTOMER_SERVICE: "Configureer dienst",
  CONFIGURE_CUSTOMER_SERVICE_EXPLANATION: "Hier kan je de diensten handmatig configureren, dit heeft invloed op de uiteindelijk kosten die de klant betaald.",
  CUSTOMER_SERVICE_CONFIGURED: "Dienst geconfigureerd",
  NO_ITEMS_FOUND: "Geen items gevonden...",
  CREATE_LEDGER_ENTRY: "Nieuwe boeking toevoegen",
  CREATE_LEDGER_ENTRY_EXPLANATION: "Hier kan je een nieuwe boeking toevoegen voor de volgende factuur van de klant.",
  EDIT_LEDGER_ENTRY: "Boeking bewerken",
  EDIT_LEDGER_ENTRY_EXPLANATION: "Hier kan je een bestaande boeking aanpassen.",
  PRICE_IS_FINAL: "Prijs is totaal",
  IS_DISCOUNT: "Is korting",
  LEDGER_ENTRY_CREATED: "Boeking aangemaakt",
  INCL: "incl.",
  EXCL: "excl.",
  TAX: "BTW",
  PRICE: "Prijs",
  TOTAL_PRICE: "Subtotaal",
  NONE: "Geen",
  FILTERS: "Filters",
  LEDGER_ENTRY_EDITED: "Boeking bewerkt",
  DELETE_LEDGER_ENTRY_QUESTION: "Weet je zeker dat je deze boeking wil verwijderen?",
  DELETE_LEDGER_ENTRY_QUESTION_EXPLANATION: "Wanneer een boeking verwijderd wordt, dan kan wordt deze niet meer doorberekend naar de klant wanneer de factuur gemaakt wordt.",
  LEDGER_ENTRY_DELETED: "Boeking verwijderd",
  INVOICE: "Factuur",
  INVOICES: "Facturen",
  START_PERIOD: "Start van periode",
  END_PERIOD: "Einde van periode",
  CREATE_INVOICE: "Factuur aanmaken",
  CREATE_INVOICE_EXPLANATION: "Via dit formulier kan een periode gegenereerd worden aan de van de boekingen binnen een periode of het resterende wat open staat.",
  INVOICE_CREATED: "Factuur aangemaakt",
  DELETE_INVOICE_QUESTION: "Weet je zeker dat je deze factuur wil verwijderen?",
  DELETE_INVOICE_QUESTION_EXPLANATION: "Wanneer een factuur verwijderd wordt, dan worden de toegevoegde boekingen weer teruggeplaatst in het boeking overzicht.",
  PROVIDER: "Aanbieder",
  TAX_AMOUNT: "Belasting",
  FINAL_PRICE: "Totaal",
  EX_VAT: "Excl. BTW",
  INVOICE_DELETED: "Factuur verwijderd",
  PAID: "Betaald",
  DETAILS: "Details",
  PAYMENTS: "Betalingen",
  INVOICE_LINES: "Factuurregels",
  PAID_AT: "Betaald op",
  CREATE_PAYMENT: "Betaling aanmaken",
  AMOUNT: "Hoeveelheid",
  CREATE_PAYMENT_EXPLANATION: "Hier kan je betalingen toevoegen aan de factuur. Wanneer alle betalingen hun bedrag hoger is of gelijk aan het factuur bedrag, dan wordt de factuur op betaald gezet.",
  PAYMENT_CREATED: "Betaling aangemaakt",
  EDIT_PAYMENT: "Bewerk betaling",
  EDIT_PAYMENT_EXPLANATION: "Hier kan je een bestaande betaling aanpassen.",
  PAYMENT_EDITED: "Betaling bewerkt",
  DELETE_PAYMENT_QUESTION: "Weet je zeker dat je deze betaling wil verwijderen?",
  DELETE_PAYMENT_QUESTION_EXPLANATION: "Wanneer een betaling verwijderd wordt, dan wordt opnieuw bepaald of de factuur wel betaald is.",
  PAYMENT_DELETED: "Betaling verwijderd",
  ON: "op",
  BOTH: "Beide",
  OVERVIEW: "Overzicht",
  AMOUNT_OF_USERS: "Aantal gebruikers",
  AMOUNT_OF_INTEGRATIONS: "Aantal integraties",
  LEDGER_ENTRIES_TOTAL: "Totaal microtransactie kosten",
  MONTH_OVERVIEW: "Maand overzicht",
  JANUARY: "Januari",
  FEBRUARY: "Februari",
  MARCH: "Maart",
  APRIL: "April",
  MAY: "Mei",
  JUNE: "Juni",
  JULY: "Juli",
  AUGUST: "Augustus",
  SEPTEMBER: "September",
  OCTOBER: "Oktober",
  NOVEMBER: "November",
  DECEMBER: "December",
  MONTH: "Maand",
  YEAR: "Jaar",
  LOADING: "Aan het laden...",
  ACCOUNT: "Account",
  MICRO_TRANSACTION: "Microtransactie",
  INTEGRATION: "Integratie",
  SUPPORT_SERVICE: "Meerwerk",
  SUPPORT_SERVICE_TOTAL: "Totaal meerwerk",
  PRODUCT_BUNDLES: "Product bundels",
  BUNDLE: "Bundel",
  CREATE_PRODUCT_BUNDLE: "Product bundel aanmaken",
  CREATE_PRODUCT_BUNDLE_EXPLANATION: "Op dit scherm kan je nieuwe product bundels aanmaken.",
  PRODUCT_BUNDLE_CREATED: "Product bundel aangemaakt",
  PRODUCT_BUNDLE_EDITED: "Product bundel bewerkt",
  PRODUCT_BUNDLE_DELETED: "Product bundel verwijderd",
  EDIT_PRODUCT_BUNDLE: "Product bundel bewerken",
  EDIT_PRODUCT_BUNDLE_EXPLANATION: "Op dit scherm kan je een bestaande product bundel aanpassen.",
  DELETE_PRODUCT_BUNDLE_QUESTION: "Weet je zeker dat je dit product bundel wil verwijderen?",
  DELETE_PRODUCT_BUNDLE_QUESTION_EXPLANATION: "Wanneer je een product bundel verwijderd, dan wordt deze uit het gehele systeem verwijderd.",
  PRODUCTS_FOR: "Producten voor bundel",
  ATTACH_PRODUCT: "Product toevoegen aan bundel",
  ATTACH_PRODUCT_EXPLANATION: "Wanneer je een product toevoegt aan een bundel wordt voor een klant die alle producten heeft automatisch de bundel prijs gerekend op de boekingen.",
  PRODUCT_ADDED: "Product toegevoegd",
  DISCONNECT_PRODUCT_QUESTION: "Weet je zeker dat je dit product uit de bundel wilt verwijderen?",
  DISCONNECT_PRODUCT_EXPLANATION: "Het verwijderen van een product uit een bundel heeft geen effect op reeds aangemaakte boekingen met dit product",
  PRODUCT_DISCONNECTED: "Product verwijderd uit bundel",
  BUNDLE_PRICE: "Bundel prijs",
};
