import React, {FC, useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {apiCreateProductBundle} from "../../api/product_bundles/api";
import {createProductBundleParams} from "../../api/product_bundles/params";

export interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}
export const CreateProductBundleDialog: FC<Props> = ({open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [name, setName] = useState("");
  const [price, setPrice] = useState<null|number>(null);
  const [priceInclTax, setPriceInclTax] = useState(false);
  const [taxPercentage, setTaxPercentage] = useState(21);

  const handleClose = () => {
    setOpen(false);
  };

  const resetForm = () => {
    setName("");
    setPrice(null);
    setPriceInclTax(false);
    setTaxPercentage(21);
  };

  const handleSubmit = () => {
    apiCreateProductBundle({
      name,
      base_price: price ?? undefined,
      price_incl_tax: priceInclTax,
      tax_percentage: taxPercentage,
    } as createProductBundleParams).then(() => {
      if (onSubmit) {
        onSubmit();
      }

      toast.success(t("PRODUCT_BUNDLE_CREATED"));
      resetForm();
      handleClose();
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("CREATE_PRODUCT_BUNDLE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("CREATE_PRODUCT_BUNDLE_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <TextField label={t("NAME")} type={"text"} value={name} onChange={e => setName(e.target.value)} />
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
              label={t("PRICE")} type={"number"} value={price} onChange={e => setPrice(parseFloat(e.target.value))} />
            <TextField InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} label={t("TAX_PERCENTAGE")} type={"number"} value={taxPercentage} onChange={e => setTaxPercentage(parseFloat(e.target.value))} />
            <FormControlLabel control={<Checkbox
              checked={priceInclTax}
              onChange={e => setPriceInclTax(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t("PRICE_INCL_TAX")} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
