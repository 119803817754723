import React, {FC} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import {useTranslation} from "react-i18next";

export interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  onAccept?: () => void,
  onDeny?: () => void,
  title?: string,
  description?: string
}

export const ConfirmDialog: FC<Props> = ({
  open,
  setOpen,
  onAccept,
  onDeny,
  title,
  description
}) => {
  const {t} = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    if (onAccept) {
      onAccept();
    }

    handleClose();
  }

  const handleCancel = () => {
    if (onDeny) {
      onDeny();
    }

    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} onClick={handleAccept}>{t("YES")}</Button>
        <Button onClick={handleCancel}>{t("NO")}</Button>
      </DialogActions>
    </Dialog>
  );
}
