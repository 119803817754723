import {AppContext} from "./context";
import React, {useState} from "react";
import {StorageHelper} from "../storage/helper";
import {IS_LOADING_STORAGE_KEY} from "./constant";

export const AppProvider: React.FC<any> = ({children}) => {
  const [isLoading, setterIsLoading] = useState(StorageHelper.getItem(IS_LOADING_STORAGE_KEY) as boolean)
  const [title, setTitle] = useState("");

  const setIsLoading = (check: boolean): void => {
    StorageHelper.setItem(IS_LOADING_STORAGE_KEY, check);
    setterIsLoading(check);
  }

  return (
    <AppContext.Provider value={{
      isLoading, setIsLoading,
      title, setTitle
    }}>
      {children}
    </AppContext.Provider>
  );
}