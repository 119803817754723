import * as React from "react";

interface Props {
  className?: string;
}

export const CardArea: React.FC<any> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={"cardArea " + props.className ?? ""}>
      {props.children}
    </div>
  );
}