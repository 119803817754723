import * as React from "react";
import {TableCell as TableCellBase, TableCellProps} from "@mui/material";

interface IProps extends TableCellProps{
  align?: "left" | "right"
}

export const TableCell: React.FC<any> = (props: React.PropsWithChildren<IProps>) => {
  return (
    <TableCellBase
      align={props.align ? props.align : "right"}
      {...props}>
        {props.children}
    </TableCellBase>
  );
}
