import * as React from "react";
import {Paper, Table as TableBase, TableContainer, TableProps} from "@mui/material";

interface IProps extends TableProps{
  title: string
}

export const Table: React.FC<any> = (props: React.PropsWithChildren<IProps>) => {
  return (
    <TableContainer component={Paper}>
      <TableBase
        stickyHeader
        sx={{ minWidth: 750 }}
        aria-labelledby={props.title}
        size={"small"}
        {...props}
      >
        {props.children}
      </TableBase>
    </TableContainer>
)
  ;
}
