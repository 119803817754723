import React, {FC, useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup, InputAdornment,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {getPaymentResult} from "../../api/payments/results";
import {Moment} from "moment/moment";
import moment from "moment";
import {apiUpdatePayment} from "../../api/payments/api";
import {MobileDateTimePicker} from "@mui/x-date-pickers";

export interface Props {
  payment: getPaymentResult|null,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}

export const EditPaymentDialog: FC<Props> = ({payment, open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [name, setName] = useState(payment?.name ?? "");
  const [description, setDescription] = useState(payment?.description ?? "");
  const [amount, setAmount] = useState<null|number>(payment?.amount ?? null);
  const [paidAt, setPaidAt] = useState<null|Moment>( payment?.paid_at ? moment.unix(payment?.paid_at) : null);

  useEffect(() => {
    setName(payment?.name ?? "");
    setDescription(payment?.description ?? "");
    setAmount(payment?.amount ?? null);
    setPaidAt(payment?.paid_at ? moment.unix(payment?.paid_at) : null);
  }, [payment]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    apiUpdatePayment({
      id: payment?.id ?? "",
      invoice_id: payment?.invoice_id ?? "",
      name,
      description,
      amount: amount ?? 0,
      paid_at: paidAt ? paidAt?.format() : "now"
    }).then(() => {
      toast.success(t("PAYMENT_EDITED"));
      handleClose();
      if (onSubmit) {
        onSubmit();
      }
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("EDIT_PAYMENT")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("EDIT_PAYMENT_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <TextField label={t("NAME")} type={"text"} value={name} onChange={e => setName(e.target.value)} />
            <TextField label={t("DESCRIPTION")} type={"text"} value={description} onChange={e => setDescription(e.target.value)} />
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
              label={t("AMOUNT")} type={"number"} value={amount} onChange={e => setAmount(parseFloat(e.target.value))} />
            <MobileDateTimePicker
              label={t("PAID_AT")}
              value={paidAt}
              inputFormat="DD/MM/YYYY HH:mm"
              onChange={setPaidAt}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
