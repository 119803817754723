import React, {FC, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup, InputAdornment,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {apiCreatePayment} from "../../api/payments/api";
import {Moment} from "moment";
import {MobileDateTimePicker} from "@mui/x-date-pickers";

export interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
  invoiceId: string,
}
export const CreatePaymentDialog: FC<Props> = ({open, setOpen, onSubmit, invoiceId}) => {
  const {t} = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState<null|number>(null);
  const [paidAt, setPaidAt] = useState<null|Moment>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const resetForm = () => {
    setName("");
    setDescription("");
    setPaidAt(null);
    setAmount(null);
  };

  const handleSubmit = () => {
    apiCreatePayment({
      name,
      invoice_id: invoiceId,
      description,
      amount: amount ?? 0,
      paid_at: paidAt?.format() ?? "now",
    }).then(() => {
      if (onSubmit) {
        onSubmit();
      }

      toast.success(t("PAYMENT_CREATED"));
      resetForm();
      handleClose();
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("CREATE_PAYMENT")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("CREATE_PAYMENT_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <TextField label={t("NAME")} type={"text"} value={name} onChange={e => setName(e.target.value)} />
            <TextField label={t("DESCRIPTION")} type={"text"} value={description} onChange={e => setDescription(e.target.value)} />
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
              label={t("AMOUNT")} type={"number"} value={amount} onChange={e => setAmount(parseFloat(e.target.value))} />
            <MobileDateTimePicker
              label={t("PAID_AT")}
              value={paidAt}
              onChange={setPaidAt}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
