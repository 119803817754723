import React, {useEffect, useState} from 'react';
import {useAppContext, useConfirmDialogContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {apiDeleteLedgerEntry, apiGetLedgerEntries} from "../../api/ledger_entries/api";
import {IconButton, MenuItem, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import {LoadingCard} from "../../components/layout/LoadingCard";
import {CardArea} from "../../components/layout/CardArea";
import {CardWrapper} from "../../components/layout/Card";
import {CreateLedgerEntryDialog} from "./CreateLedgerEntryDialog";
import moment from "moment";
import {MenuButton} from "../../components/layout/MenuButton";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {getLedgerEntryResult} from "../../api/ledger_entries/results";
import {EditLedgerEntryDialog} from "./EditLedgerEntryDialog";
import {Pager} from "../../components/layout/Pager";
import {FilterCard} from "../../components/layout/FilterCard";

export default function LedgerScene() {
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [currentEditEntry, setCurrentEditEntry] = useState<getLedgerEntryResult|null>(null);
  const ledgerEntries = useQuery(['ledger_entries'], () => {return apiGetLedgerEntries(filters);});
  const [openCreate, setOpenCreate] = useState(false);
  const [openEntryEdit, setOpenEntryEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({limit: 50, page: 1});
  const confirmDialog = useConfirmDialogContext();
  const [customer, setCustomer] = useState("");
  const [product, setProduct] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    appContext?.setTitle(t("LEDGER_ENTRIES"));
  }, [appContext, t]);

  const handleEdit = (row: getLedgerEntryResult) => {
    setCurrentEditEntry(row);
    setOpenEntryEdit(true);
  };

  const handleDelete = (row: getLedgerEntryResult) => {
    confirmDialog?.confirm(
      t("DELETE_LEDGER_ENTRY_QUESTION"),
      t("DELETE_LEDGER_ENTRY_QUESTION_EXPLANATION"),
      () => () => {
        apiDeleteLedgerEntry({id: row.id}).then(() => {
          toast.success(t("LEDGER_ENTRY_DELETED"));
          ledgerEntries.refetch();
        }).catch((result) => {
          ErrorHelper.toastLaraFail(result);
        })
      }
    );
  };

  const setFilter = async (key: string, value?: string|number) => {
    if (value === "") {
      value = undefined;
    }

    await setFilters(
      Object.assign(Object.assign({}, filters), {[key]: value})
    );

    ledgerEntries.refetch();
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setFilter('page', page);
  };

  function getHeaderTitle(row: getLedgerEntryResult): string {
    let title =  row.name;

    if (row.month || row.year) {
      title = title + ' '+ row.month + '-' + row.year;
    }

    title = title + ' ' +  t("FOR") + ' ' + row.customer.name
    return title;
  }

  return (
    <>
      <h1>
        {t("LEDGER")}
        <IconButton aria-label="create" color="success" onClick={() => {setOpenCreate(true)}}>
          <AddCircle />
        </IconButton>
      </h1>
      <CardArea>
        <FilterCard setFilter={setFilter} product={product} customer={customer} name={name} setProduct={setProduct} setCustomer={setCustomer} setName={setName} />
        {ledgerEntries.isLoading ? (
          <LoadingCard />
        ) : (ledgerEntries.data?.items && ledgerEntries.data.items.length > 0 ? ledgerEntries.data?.items.map((row) => (
          <CardWrapper size={"fullCard"} key={row.id}>
            <div className={"cardHeader"} >
              <Typography className={"cardTitle"} gutterBottom variant={"h6"}>
                {getHeaderTitle(row)}
              </Typography>
              <MenuButton>
                <MenuItem key={'edit-' + row.id} onClick={() => handleEdit(row)}>
                  {t("EDIT")}
                </MenuItem>
                <MenuItem key={'delete-' + row.id} onClick={() => handleDelete(row)}>
                  {t("DELETE")}
                </MenuItem>
              </MenuButton>
            </div>
            <Table className={"noBorderTable compact"}>
              <TableBody>
                <TableRow>
                  <TableCell><b>{t("NAME")}</b></TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell><b>{t("CUSTOMER")} {t("NAME")}</b></TableCell>
                  <TableCell>{row.customer.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{t("PRODUCT")}</b></TableCell>
                  <TableCell>{row.product?.name ?? 'N/A'}</TableCell>
                  <TableCell><b>{row.is_discount ? t("DISCOUNT") : t("PRICE")}</b></TableCell>
                  <TableCell>€{row.base_price} {row.price_incl_tax ? t("INCL") : t("EXCL")} {row.tax_percentage}% {t("TAX")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{t("QUANTITY")}</b></TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell><b>{t("TOTAL_PRICE")}</b></TableCell>
                  <TableCell>€{row.price_is_final ? row.base_price : (row.base_price * (row.quantity ?? 0))} {row.price_incl_tax ? t("INCL") : t("EXCL")} {row.tax_percentage}% {t("TAX")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{t("BUNDLE")}</b></TableCell>
                  <TableCell>{row.product_bundle?.name ?? 'Nee'}</TableCell>
                  <TableCell><b>{t("BUNDLE_PRICE")}</b></TableCell>
                  <TableCell>€{row.product_bundle?.base_price ?? 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{t("CREATED_AT")}</b></TableCell>
                  <TableCell>{moment.unix(row.created_at).format("DD-MM-YYYY HH:mm")}</TableCell>
                  <TableCell><b>{t("UPDATED_AT")}</b></TableCell>
                  <TableCell>{moment.unix(row.updated_at).format("DD-MM-YYYY HH:mm")}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardWrapper>
        )) : (
          <CardWrapper className={"textCenter"} size={"fullCard"}>
            <Typography gutterBottom variant={"caption"}>
              {t("NO_ITEMS_FOUND")}
            </Typography>
          </CardWrapper>
        ))}
      </CardArea>
      {!ledgerEntries.isLoading && (
        <Pager currentPage={currentPage} totalPages={ledgerEntries.data?.metadata.total_pages} setCurrentPage={handlePageChange} totalItems={ledgerEntries.data?.metadata.total} itemsPerPage={ledgerEntries.data?.metadata.count}/>
      )}
      <CreateLedgerEntryDialog open={openCreate} setOpen={setOpenCreate} onSubmit={() => {ledgerEntries.refetch()}} />
      <EditLedgerEntryDialog entry={currentEditEntry} open={openEntryEdit} setOpen={setOpenEntryEdit} onSubmit={() => {ledgerEntries.refetch()}} />
    </>
  );
}
