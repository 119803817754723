export const Settings = {
  provider_name: "Aanbieder naam",
  provider_address: "Aanbieder adres",
  provider_house_number: "Aanbieder huisnummer",
  provider_postal_code: "Aanbieder postcode",
  provider_city: "Aanbieder woonplaats",
  provider_country_code: "Aanbieder land code",
  provider_kvk: "Aanbieder KvK",
  provider_tax_number: "Aanbieder belastingnummer",
  provider_phone_number: "Aanbieder telefoonnummer",
  provider_email: "Aanbieder e-mail",
  provider_iban: "Aanbieder IBAN",
  mollie_access_token: "Mollie access token",
  mka_client_id: "MKA client ID",
  mka_client_secret: "MKA client secret",
  zendoc_access_token: "Zendoc access token",
  legalsign_access_token: "Legalsign access token",
  invoice_number_template: "Factuurnummer template",
  invoice_number_length: "Factuurnummer lengte",
  invoice_current_number: "Factuurnummer huidig nummer",
}
