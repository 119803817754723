import React, {FC, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {apiCreateServiceVariable} from "../../api/services/api";
import {ProductsSelect} from "../../components/form/ProductsSelect";

export interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
  serviceId: string,
}
export const CreateServiceVariableDialog: FC<Props> = ({open, setOpen, onSubmit, serviceId}) => {
  const {t} = useTranslation();
  const [name, setName] = useState("");
  const [productId, setProductId] = useState("");
  const [firstQuantityFree, setFirstQuantityFree] = useState(0);
  const [type, setType] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const resetForm = () => {
    setName("");
    setProductId("");
    setFirstQuantityFree(0);
    setType("");
  };

  const handleSubmit = () => {
    apiCreateServiceVariable({
      service_id: serviceId,
      name,
      type,
      first_quantity_free: firstQuantityFree,
      product_id: productId.length > 0 ? productId : undefined,
    }).then(() => {
      if (onSubmit) {
        onSubmit();
      }

      toast.success(t("SERVICE_VARIABLE_CREATED"));
      resetForm();
      handleClose();
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  const onChangeProduct = (productId: string | Array<string>) => {
    const val = Array.isArray(productId) ? productId[0] : productId;
    setProductId(val);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("CREATE_SERVICE_VARIABLE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("CREATE_SERVICE_VARIABLE_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <TextField label={t("NAME")} type={"text"} value={name} onChange={e => setName(e.target.value)} />
            <TextField label={t("TYPE")} type={"text"} value={type} onChange={e => setType(e.target.value)} />
            <TextField label={t("FIRST_QUANTITY_FREE")} type={"number"} value={firstQuantityFree} onChange={e => setFirstQuantityFree(parseInt(e.target.value))} />
            <ProductsSelect onChange={onChangeProduct} label={t("PRODUCT")} identifier={"product"} value={productId} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
