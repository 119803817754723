import React, {useState} from 'react';
import {apiLoginUser} from "../../api/user/api";
import {Box, Button, Card, CardContent, Container, Link, TextField, Typography} from "@mui/material";
import './Login.scss';
import {useTranslation} from "react-i18next";
import {
  Link as RouterLink
} from 'react-router-dom';
import {updateAuthenticationStorage} from "../../services/authentication/helper";
import moment from "moment/moment";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    await apiLoginUser({
      username,
      password
    }).then((result) => {
      setError(null);
      updateAuthenticationStorage(result.access_token, moment.unix(moment.now()).add(
        {seconds: result.expires_in - 30}
      ), result.refresh_token);
      window.location.reload();
    }).catch((result) => {
      const error = result.response.data;
      if (error.error) {
        setError(error.message);
      } else {
        setError(t("Unknown error"))
      }
    });
  }

  return(
    <Container className={"loginContainer"} sx={{display: "flex"}}>
      <Box className={"loginBox"}>
        <Card>
          <CardContent>
            <Typography className={"loginTitle"} gutterBottom variant={"h4"}>
              {t("LOGIN_TITLE")}
            </Typography>
            <form onSubmit={handleSubmit} className={"loginForm"}>
              {error && (
                <Typography className={"error"} gutterBottom variant={"caption"}>
                  {t(error)}
                </Typography>
              )}
              <TextField label={t("USERNAME")} type={"text"} onChange={e => setUsername(e.target.value)} />
              <TextField label={t("PASSWORD")} type={"password"} onChange={e => setPassword(e.target.value)} />
              <Button className={"loginSubmit"} variant={"contained"} type={"submit"}>{t("LOGIN_SUBMIT")}</Button>
            </form>
          </CardContent>
        </Card>
        <Box className={"loginBelow"}>
          <Link component={RouterLink} to={"/forgot-password"}>{t("FORGOT_PASSWORD")}</Link>
        </Box>
      </Box>
    </Container>
  )
}