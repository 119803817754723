import * as React from "react";
import {TableBodyProps, TableBody as TableBodyBase} from "@mui/material";

interface IProps extends TableBodyProps {

}

export const TableBody: React.FC<any> = (props: React.PropsWithChildren<IProps>) => {
  return (
    <TableBodyBase className={"customTableBody"} {...props}>
      {props.children}
    </TableBodyBase>
  );
}
