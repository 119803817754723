import {getAuthApiInstance} from "../api";
import {getServiceResult, getServicesResult, getServiceVariableResult, getServiceVariablesResult} from "./results";
import {
  createServiceParams,
  createServiceVariableParams,
  getServiceParams,
  getServiceVariableParams,
  updateServiceParams, updateServiceVariableParams
} from "./params";

/**
 * Services
 */
export const apiGetServices = (): Promise<getServicesResult> => {
  return getAuthApiInstance().get("services?sort[field]=name&sort[direction]=asc").then((result) => {
    return result.data as getServicesResult;
  });
}

export const apiGetService = (params: getServiceParams): Promise<getServiceResult> => {
  return getAuthApiInstance().get("services/" + params.id).then((result) => {
    return result.data as getServiceResult;
  });
}

export const apiCreateService = (params: createServiceParams): Promise<getServiceResult> => {
  return getAuthApiInstance().put("services", params).then((result) => {
    return result.data as getServiceResult;
  });
}

export const apiUpdateService = (params: updateServiceParams): Promise<getServiceResult> => {
  const {id, ...restParams} = params;
  return getAuthApiInstance().post("services/" + id, restParams).then((result) => {
    return result.data as getServiceResult;
  });
}

export const apiDeleteService = (params: getServiceParams): Promise<void> => {
  return getAuthApiInstance().delete("services/" + params.id);
}

/**
 * Service variables
 */
export const apiGetServiceVariables = (serviceId: string): Promise<getServiceVariablesResult> => {
  return getAuthApiInstance().get("services/" + serviceId + "/variables").then((result) => {
    return result.data as getServiceVariablesResult;
  });
}

export const apiGetServiceVariable = (params: getServiceVariableParams): Promise<getServiceVariableResult> => {
  return getAuthApiInstance().get("services/" + params.service_id + "/variables/" + params.id).then((result) => {
    return result.data as getServiceVariableResult;
  });
}

export const apiCreateServiceVariable = (params: createServiceVariableParams): Promise<getServiceVariableResult> => {
  return getAuthApiInstance().put("services/" + params.service_id + "/variables", params).then((result) => {
    return result.data as getServiceVariableResult;
  });
}

export const apiUpdateServiceVariable = (params: updateServiceVariableParams): Promise<getServiceVariableResult> => {
  const {id, service_id, ...restParams} = params;
  return getAuthApiInstance().post("services/" + service_id + "/variables/" + id, restParams).then((result) => {
    return result.data as getServiceVariableResult;
  });
}

export const apiDeleteServiceVariable = (params: getServiceVariableParams): Promise<void> => {
  return getAuthApiInstance().delete("services/" + params.service_id + "/variables/" + params.id);
}
