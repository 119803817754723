import {General} from "./General";
import {Errors} from "./Errors";
import {Countries} from "./Countries";
import {Settings} from "./Settings";

export const nl = {
  ...General,
  ...Errors,
  ...Countries,
  ...Settings
};