import React, {useEffect, useState} from 'react';
import {useAppContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {Box, Button, Card, CardContent, FormGroup, TextField} from "@mui/material";
import {apiUpdatePassword} from "../../api/user/api";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";

export default function SettingsScene() {
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    appContext?.setTitle(t("SETTINGS"));
  }, [appContext, t]);

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    await apiUpdatePassword({
      password,
      new_password: newPassword,
      password_confirm: confirmPassword
    }).then(() => {
      toast.success(t("PASSWORD_UPDATED"));
      setPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  };

  return (
      <Box className={"settingsBox"}>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit} className={"settingsForm"}>
              <FormGroup>
                <TextField label={t("PASSWORD")} type={"password"} value={password} onChange={e => setPassword(e.target.value)} />
                <TextField label={t("NEW_PASSWORD")} type={"password"} value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                <TextField label={t("CONFIRM_PASSWORD")} type={"password"} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
              </FormGroup>
              <Button className={"submit"} variant={"contained"} type={"submit"}>{t("SUBMIT")}</Button>
            </form>
          </CardContent>
        </Card>
      </Box>
  );
}
