import React, {FC, useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {SettingInterface} from "../../services/setting/interface";
import {apiUpdateSetting} from "../../api/settings/api";
import {CountrySelect} from "../../components/form/CountrySelect";

export interface Props {
  setting: SettingInterface|null,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}

export const EditSettingDialog: FC<Props> = ({setting, open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [value, setValue] = useState(setting?.value ?? "");
  const [key, setKey] = useState(setting?.key ?? "");
  const [hidden, setHidden] = useState(setting?.protected ?? false);

  useEffect(() => {
    setValue(setting?.protected ? "" : (setting?.value ?? ""));
    setKey(setting?.key ?? "");
    setHidden(setting?.protected ?? false);
  }, [setting]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    apiUpdateSetting({
      key,
      value
    }).then(() => {
      toast.success(t("SETTING_EDITED"));
      handleClose();
      if (onSubmit) {
        onSubmit();
      }
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("EDIT_SETTING")}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            {
              key === "provider_country_code" ?
                <CountrySelect label={t(key)} identifier={"country_code"} value={value} onChange={setValue} /> :
                <TextField label={t(key)} type={hidden ? "password" : "text"} value={value} onChange={e => setValue(e.target.value)} />
            }
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
