import {useQuery} from "@tanstack/react-query";
import {FC} from "react";
import {SelectInput} from "./SelectInput";
import {FormGroup} from "@mui/material";
import {apiGetCustomers} from "../../api/customers/api";
import {useTranslation} from "react-i18next";

export interface Props {
  onChange: (value: string) => void,
  value?: string,
  label: string,
  identifier: string,
  optional?: boolean,
}

export const CustomersSelect: FC<Props> = ({onChange, value, label, identifier, optional}) => {
  const customers = useQuery(['customers'], apiGetCustomers);
  const {t} = useTranslation();

  const options = (): Array<{value: string, label: string}> => {
    let optionsList: Array<{value: string, label: string}> = [];
    if (optional) {
      optionsList.push({value: "", label: t("NONE")});
    }

    customers.data?.items.forEach((row) => {
      optionsList.push({value: row.id, label: row.name});
    });

    return optionsList;
  };

  return ( !customers.isLoading ?
    <SelectInput identifier={identifier} label={label} onChange={onChange} value={value} options={options()}/> : <FormGroup>Loading...</FormGroup>
  );
}
