import {getAuthApiInstance} from "../api";
import {getProductBundleResult, getProductBundlesResult} from "./results";
import {
  attachProductToBundleParams,
  createProductBundleParams, detachProductFromBundleParams,
  getProductBundleParams,
  updateProductBundleParams
} from "./params";

/**
 * ProductBundles
 */
export const apiGetProductBundles = (): Promise<getProductBundlesResult> => {
  return getAuthApiInstance().get("product_bundles").then((result) => {
    return result.data as getProductBundlesResult;
  });
}

export const apiGetProductBundle = (params: getProductBundleParams): Promise<getProductBundleResult> => {
  return getAuthApiInstance().get("product_bundles/" + params.id).then((result) => {
    return result.data as getProductBundleResult;
  });
}

export const apiCreateProductBundle = (params: createProductBundleParams): Promise<getProductBundleResult> => {
  return getAuthApiInstance().put("product_bundles", params).then((result) => {
    return result.data as getProductBundleResult;
  });
}

export const apiUpdateProductBundle = (params: updateProductBundleParams): Promise<getProductBundleResult> => {
  const {id, ...restParams} = params;
  return getAuthApiInstance().post("product_bundles/" + id, restParams).then((result) => {
    return result.data as getProductBundleResult;
  });
}

export const apiDeleteProductBundle = (params: getProductBundleParams): Promise<void> => {
  return getAuthApiInstance().delete("product_bundles/" + params.id);
}

export const apiDetachProductFromBundle = (params: detachProductFromBundleParams): Promise<void> => {
  return getAuthApiInstance().delete("product_bundles/" + params.product_bundle_id + "/products/" + params.product_id);
}

export const apiAttachProductToBundle = (params: attachProductToBundleParams): Promise<void> => {
  return getAuthApiInstance().post("product_bundles/" + params.product_bundle_id + "/products/" + params.product_id);
}
