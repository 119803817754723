import React, {createContext, useContext, useState} from "react";
import {AppContextInterface, ConfirmDialogContextInterface} from "./interface";
import {ConfirmDialog} from "../../components/layout/ConfirmDialog";

export const AppContext = createContext<AppContextInterface | null>(null);
export const useAppContext = () => useContext(AppContext);

export const ConfirmDialogContext = createContext<ConfirmDialogContextInterface | null>(null);
export const useConfirmDialogContext = () => useContext(ConfirmDialogContext);

export const ConfirmDialogContextProvider = (props: { children: any }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [onAccept, setOnAccept] = useState<() => void>(() => {});
  const [onDeny, setOnDeny] = useState<() => void>(() => {});

  const confirm = (
    title: string,
    description?: string,
    onAccept?: () => void,
    onDeny?: () => void
  ) => {
    setTitle(title);
    setDescription(description ?? "");
    setOnAccept(onAccept ?? (() => {}));
    setOnDeny(onDeny ?? (() => {}));
    setOpen(true);
  };

  return (
    <ConfirmDialogContext.Provider value={{confirm}}>
      {props.children}
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        title={title}
        description={description}
        onAccept={onAccept}
        onDeny={onDeny}
      />
    </ConfirmDialogContext.Provider>
  )
};