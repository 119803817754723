import * as React from "react";
import {Pagination, Typography} from "@mui/material";

interface Props {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => {};
  totalItems: number;
  itemsPerPage: number;
}
export const Pager: React.FC<any> = (props: Props) => {
  return (
    <>
      <Typography>Showing {(props.itemsPerPage * (props.currentPage - 1)) + 1} - {props.totalPages !== props.currentPage ? props.itemsPerPage * props.currentPage : props.totalItems} of {props.totalItems}</Typography>
      <Pagination count={props.totalPages} page={props.currentPage} onChange={(event: React.ChangeEvent<unknown>, value: number) => {props.setCurrentPage(value)}}/>
    </>
  );
}