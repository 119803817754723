import React, {FC, useEffect, useState} from 'react';
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControlLabel,
  FormGroup, InputAdornment,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {LedgerEntryInterface} from "../../services/ledger/interface";
import {apiUpdateLedgerEntry} from "../../api/ledger_entries/api";
import {CustomersSelect} from "../../components/form/CustomersSelect";
import {ProductsSelect} from "../../components/form/ProductsSelect";
import {ProductInterface} from "../../services/product/interface";

export interface Props {
  entry: LedgerEntryInterface|null,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}

export const EditLedgerEntryDialog: FC<Props> = ({entry, open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [customerId, setCustomerId] = useState(entry?.customer_id ?? "");
  const [productId, setProductId] = useState(entry?.product_id ?? "");
  const [quantity, setQuantity] = useState(entry?.quantity ?? 1);
  const [name, setName] = useState(entry?.name ?? "");
  const [isDiscount, setIsDiscount] = useState(entry?.is_discount ?? false);
  const [priceIsFinal, setPriceIsFinal] = useState(entry?.price_is_final ?? false);
  const [basePrice, setBasePrice] = useState(entry?.base_price ?? 0);
  const [priceInclTax, setPriceInclTax] = useState(entry?.price_incl_tax ?? false);
  const [taxPercentage, setTaxPercentage] = useState(entry?.tax_percentage ?? 21);

  useEffect(() => {
    setCustomerId(entry?.customer_id ?? "");
    setProductId(entry?.product_id ?? "");
    setQuantity(entry?.quantity ?? 1);
    setName(entry?.name ?? "");
    setIsDiscount(entry?.is_discount ?? false);
    setPriceIsFinal(entry?.price_is_final ?? false);
    setBasePrice(entry?.base_price ?? 0);
    setPriceInclTax(entry?.price_incl_tax ?? false);
    setTaxPercentage(entry?.tax_percentage ?? 21);
  }, [entry]);

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeProduct = (productId: string | Array<string>) => {
    const val = Array.isArray(productId) ? productId[0] : productId;
    setProductId(val);
  }

  const handleSubmit = () => {
    apiUpdateLedgerEntry({
      id: entry?.id ?? "",
      customer_id: customerId,
      product_id: productId,
      quantity,
      name,
      is_discount: isDiscount,
      price_is_final: priceIsFinal,
      base_price: basePrice,
      price_incl_tax: priceInclTax,
      tax_percentage: taxPercentage,
    }).then(() => {
      toast.success(t("LEDGER_ENTRY_EDITED"));
      handleClose();
      if (onSubmit) {
        onSubmit();
      }
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  const onSelectProduct = (row: ProductInterface) => {
    setBasePrice(row.base_price ?? basePrice);
    setPriceInclTax(row.price_incl_tax);
    setTaxPercentage(row.tax_percentage);
    setIsDiscount(false);
    setPriceIsFinal(false);
    setName(row.name);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("EDIT_LEDGER_ENTRY")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("EDIT_LEDGER_ENTRY_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <CustomersSelect onChange={setCustomerId} label={t("CUSTOMER")} identifier={"customer"} value={customerId} />
            <ProductsSelect onChange={onChangeProduct} label={t("PRODUCT")} identifier={"product"} value={productId} onSelect={onSelectProduct} optional />
            <TextField label={t("QUANTITY")} type={"number"} value={quantity} onChange={e => setQuantity(parseInt(e.target.value))} />
            <TextField label={t("NAME")} type={"text"} value={name} onChange={e => setName(e.target.value)} />
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
              label={t("PRICE")} type={"number"} value={basePrice} onChange={e => setBasePrice(parseFloat(e.target.value))} />
            <TextField InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} label={t("TAX_PERCENTAGE")} type={"number"} value={taxPercentage} onChange={e => setTaxPercentage(parseFloat(e.target.value))} />
            <FormControlLabel control={<Checkbox
              checked={priceInclTax}
              onChange={e => setPriceInclTax(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t("PRICE_INCL_TAX")} />
            <FormControlLabel control={<Checkbox
              checked={priceIsFinal}
              onChange={e => setPriceIsFinal(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t("PRICE_IS_FINAL")} />
            <FormControlLabel control={<Checkbox
              checked={isDiscount}
              onChange={e => setIsDiscount(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t("IS_DISCOUNT")} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
