export const General = {
  LOGIN_TITLE: "Login",
  USERNAME: "Username",
  PASSWORD: "Password",
  LOGIN_SUBMIT: "Submit",
  FORGOT_PASSWORD: "Forgot your password?",
  LOGOUT: "Logout",
  SETTINGS: "Settings",
  DASHBOARD: "Dashboard",
  NAME: "Name",
  DESCRIPTION: "Description",
  CREATED_AT: "Created at",
  UPDATED_AT: "Last update",
  ACTIONS: "Actions",
  EDIT: "Edit",
  DELETE: "Delete",
  VALUES: "Values",
  OPTIONS: "Options",
  TYPE: "Type",
  VALUE: "Value",
  USERS: "Users",
  FIRST_NAME: "First name",
  INSERTION: "Insertion",
  LAST_NAME: "Last name",
  EMAIL: "Email",
  SUBMIT: "Submit",
  NEW_PASSWORD: "New password",
  CONFIRM_PASSWORD: "Confirm password",
  PASSWORD_UPDATED: "Password updated",
  CANCEL: "Cancel",
  CREATE_USER: "Create user",
  USER_CREATED: "User created",
  CREATE_USER_EXPLANATION: "After the user is created, an email will be send with instructions on how to setup a password.",
  VALIDATE_TITLE: "Validate and setup password",
  USER_EDITED: "User edited",
  EDIT_USER: "Edit user",
  EDIT_USER_EXPLANATION: "Here you edit the basic info of a user account.",
  YES: "Yes",
  NO: "No",
  DELETE_USER_QUESTION: "Are you sure you want to delete this user?",
  DELETE_USER_QUESTION_EXPLANATION: "When deleting a user, you permanently deny them access to this system. In order to grant them access again, the user has to be recreated.",
  USER_DELETED: "User deleted",
  CUSTOMERS: "Customers",
  CUSTOMER: "Customer",
  LEDGER: "Ledger",
  FOR: "for",
  ADDRESS: "Address",
  HOUSE_NUMBER: "House number",
  POSTAL_CODE: "Postal code",
  CITY: "City",
  COUNTRY_CODE: "Country code",
  KVK: "KvK",
  TAX_NUMBER: "Tax number",
  PHONE_NUMBER: "Phone number",
  CREATE_CUSTOMER: "Create customer",
  CREATE_CUSTOMER_EXPLANATION: "In this screen you can create a new customer.",
  CUSTOMER_CREATED: "Customer created",
  EDIT_CUSTOMER: "Edit customer",
  EDIT_CUSTOMER_EXPLANATION: "Here you can edit an existing customer.",
  CUSTOMER_EDITED: "Customer edited",
  DELETE_CUSTOMER_QUESTION: "Are you sure you want to delete this customer?",
  DELETE_CUSTOMER_QUESTION_EXPLANATION: "When a customer is deleted, they will no longer receive any payment updates.",
  CUSTOMER_DELETED: "Customer deleted",
  SYSTEM_SETTINGS: "System settings",
  SETTING_EDITED: "Setting edited",
  EDIT_SETTING: "Edit setting",
  NOT_SET: "Not set",
  LEDGER_ENTRIES: "Ledger entries",
  PRODUCTS: "Products",
  BASE_PRICE: "Base price",
  PRICE_INCL_TAX: "Price incl. tax",
  TAX_PERCENTAGE: "Tax percentage",
  CREATE_PRODUCT: "Create product",
  CREATE_PRODUCT_EXPLANATION: "Here you can create new products which can be sold to customers.",
  PRODUCT_CREATED: "Product created",
  PRODUCT_EDITED: "Product edited",
  PRODUCT_DELETED: "Product deleted",
  EDIT_PRODUCT: "Edit product",
  EDIT_PRODUCT_EXPLANATION: "Here you can edit an existing product.",
  DELETE_PRODUCT_QUESTION: "Are you sure you want to delete this product?",
  DELETE_PRODUCT_QUESTION_EXPLANATION: "If a product is deleted, it will be deleted system wide, including the references in the ledger.",
  SERVICES: "Services",
  CREATE_SERVICE: "Create service",
  CREATE_SERVICE_EXPLANATION: "Here you can create new services",
  SERVICE_CREATED: "Service created",
  SERVICE_EDITED: "Service edited",
  SERVICE_DELETED: "Service deleted",
  EDIT_SERVICE: "Edit service",
  EDIT_SERVICE_EXPLANATION: "Here you can edit an existing service.",
  DELETE_SERVICE_QUESTION: "Are you sure you want to delete this service?",
  DELETE_SERVICE_QUESTION_EXPLANATION: "If a service is deleted, it will be deleted system wide, including the references in the ledger.",
  CHARGE_INTERVAL: "Charge interval",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  ANNUALLY: "Annually",
  CUSTOMER_SERVICES_FOR: "Services connected to ",
  EXTERNAL_REFERENCE: "External reference",
  QUANTITY: "Quantity",
  DISCOUNT: "Discount",
  DISCOUNT_INCL_TAX: "Discount incl. tax",
  DISCOUNT_TYPE: "Discount type",
  CUSTOMER_SERVICES: "Connected services",
  CONNECT_CUSTOMER_SERVICE: "Connect service to customer",
  CONNECT_CUSTOMER_SERVICE_EXPLANATION: "Here you can connect a new service to a customer, based on the variables and products, entries will be added to the ledger.",
  SERVICE: "Service",
  FIXED_PRICE: "Fixed price",
  PERCENTAGE: "Percentage",
  FIXED_PER_QUANTITY: "Fixed price per quantity",
  CUSTOMER_SERVICE_CONNECTED: "Service connected",
  UPDATE_CUSTOMER_SERVICE: "Edit connected service",
  UPDATE_CUSTOMER_SERVICE_EXPLANATION: "Here you can edit an existing connection of a service to a customer.",
  CUSTOMER_SERVICE_EDITED: "Connection edited",
  DISCONNECT_CUSTOMER_SERVICE_QUESTION: "Are you sure you want to disconnect this service from the customer?",
  DISCONNECT_CUSTOMER_SERVICE_QUESTION_EXPLANATION: "When a service is disconnected from the client, no more ledger entries will be added.",
  CUSTOMER_SERVICE_DISCONNECTED: "Service disconnected",
  VARIABLES: "Variables",
  SERVICE_VARIABLES: "Service variables",
  SERVICE_VARIABLES_FOR: "Service variables for",
  CREATE_SERVICE_VARIABLE: "Create service variable",
  CREATE_SERVICE_VARIABLE_EXPLANATION: "Here you can create new variable which affect the price of the service.",
  FIRST_QUANTITY_FREE: "First quantity free",
  PRODUCT: "Product",
  SERVICE_VARIABLE_CREATED: "Variable created",
  EDIT_SERVICE_VARIABLE: "Edit variable",
  EDIT_SERVICE_VARIABLE_EXPLANATION: "Here you can edit the configuration of a service variable.",
  SERVICE_VARIABLE_EDITED: "Variable edited",
  DELETE_SERVICE_VARIABLE_QUESTION: "Are you sure you want to delete this variable?",
  DELETE_SERVICE_VARIABLE_QUESTION_EXPLANATION: "When a variable is deleted, the calculations in the ledger might change.",
  SERVICE_VARIABLE_DELETED: "Variable deleted",
  CONFIGURE: "Configure",
  CONFIGURE_CUSTOMER_SERVICE: "Configure service",
  CONFIGURE_CUSTOMER_SERVICE_EXPLANATION: "Here you can configure the service variables, this influences the prices in the ledger.",
  CUSTOMER_SERVICE_CONFIGURED: "Service configured",
  NO_ITEMS_FOUND: "No items found...",
  CREATE_LEDGER_ENTRY: "Create a ledger entry",
  CREATE_LEDGER_ENTRY_EXPLANATION: "Here you can add a manual entry to the ledger entry for a customer.",
  EDIT_LEDGER_ENTRY: "Edit ledger entry",
  EDIT_LEDGER_ENTRY_EXPLANATION: "Here you can edit an existing ledger entry for a customer.",
  PRICE_IS_FINAL: "Price is final",
  IS_DISCOUNT: "Is discount",
  LEDGER_ENTRY_CREATED: "Ledger entry created",
  INCL: "incl.",
  EXCL: "excl.",
  TAX: "tax",
  PRICE: "Price",
  TOTAL_PRICE: "Total price",
  NONE: "None",
  FILTERS: "Filters",
  LEDGER_ENTRY_EDITED: "Ledger entry edited",
  DELETE_LEDGER_ENTRY_QUESTION: "Are you sure you want to delete this ledger entry?",
  DELETE_LEDGER_ENTRY_QUESTION_EXPLANATION: "When a ledger entry is deleted, it will no longer be added billed to the customer.",
  LEDGER_ENTRY_DELETED: "Ledger entry deleted",
  INVOICE: "Invoice",
  INVOICES: "Invoices",
  START_PERIOD: "Start of period",
  END_PERIOD: "End of period",
  CREATE_INVOICE: "Create invoice",
  CREATE_INVOICE_EXPLANATION: "Through this form, an invoice can be generated by providing the start and end date of a period.",
  INVOICE_CREATED: "Invoice created",
  DELETE_INVOICE_QUESTION: "Are you sure you want to delete this invoice?",
  DELETE_INVOICE_QUESTION_EXPLANATION: "When an invoice is deleted, all invoice rows will be returned to the ledger as unprocessed entries.",
  PROVIDER: "Provider",
  TAX_AMOUNT: "VAT amount",
  FINAL_PRICE: "Final price",
  EX_VAT: "Ex. VAT",
  INVOICE_DELETED: "Invoice deleted",
  PAID: "Paid",
  DETAILS: "Details",
  PAYMENTS: "Payments",
  INVOICE_LINES: "Invoice lines",
  PAID_AT: "Paid at",
  CREATE_PAYMENT: "Create payment",
  AMOUNT: "Amount",
  CREATE_PAYMENT_EXPLANATION: "Here you can add a payment to the invoice. Once enough has been added, the invoice will turn into a paid state.",
  PAYMENT_CREATED: "Payment created",
  EDIT_PAYMENT: "Edit payment",
  EDIT_PAYMENT_EXPLANATION: "Here you can edit an existing payment.",
  PAYMENT_EDITED: "Payment edited",
  DELETE_PAYMENT_QUESTION: "Are you sure you want to delete this payment?",
  DELETE_PAYMENT_QUESTION_EXPLANATION: "When a payment is deleted, the invoice payment status is redetermined.",
  PAYMENT_DELETED: "Payment deleted",
  ON: "on",
  BOTH: "Both",
  OVERVIEW: "Overzicht",
  AMOUNT_OF_USERS: "Amount of users",
  AMOUNT_OF_INTEGRATIONS: "Amount of integrations",
  LEDGER_ENTRIES_TOTAL: "Total Ledger entries ",
  MONTH_OVERVIEW: "Month overview",
  JANUARY: "January",
  FEBRUARY: "February",
  MARCH: "March",
  APRIL: "April",
  MAY: "Mai",
  JUNE: "June",
  JULY: "July",
  AUGUST: "August",
  SEPTEMBER: "September",
  OCTOBER: "October",
  NOVEMBER: "November",
  DECEMBER: "December",
  MONTH: "Month",
  YEAR: "Year",
  LOADING: "Loading...",
  ACCOUNT: "Account",
  MICRO_TRANSACTION: "Micro transaction",
  INTEGRATION: "Integration",
  SUPPORT_SERVICE: "Additional service",
  SUPPORT_SERVICE_TOTAL: "Total additional service",
  PRODUCT_BUNDLES: "Product bundles",
  BUNDLE: "Bundle",
  CREATE_PRODUCT_BUNDLE: "Create product bundle",
  CREATE_PRODUCT_BUNDLE_EXPLANATION: "Here you can create new product bundles.",
  PRODUCT_BUNDLE_CREATED: "Product bundle created",
  PRODUCT_BUNDLE_EDITED: "Product bundle edited",
  PRODUCT_BUNDLE_DELETED: "Product bundle deleted",
  EDIT_PRODUCT_BUNDLE: "Edit product bundle",
  EDIT_PRODUCT_BUNDLE_EXPLANATION: "Here you can edit an existing product bundles.",
  DELETE_PRODUCT_BUNDLE_QUESTION: "Are you sure you want to delete this product bundle?",
  DELETE_PRODUCT_BUNDLE_QUESTION_EXPLANATION: "If a product bundle is deleted, it will be deleted system wide.",
  PRODUCTS_FOR: "Products for bundle",
  ATTACH_PRODUCT: "Attach product to bundle",
  ATTACH_PRODUCT_EXPLANATION: "When you attach a product to bundle the bundle price will be applied if the customer has all products from a bundle.",
  PRODUCT_ADDED: "Product added",
  DISCONNECT_PRODUCT_QUESTION: "Are you sure you want to remove this product from the bundle?",
  DISCONNECT_PRODUCT_EXPLANATION: "Removing a product from a bundle does not affect the ledgers that are already created with this product.",
  PRODUCT_DISCONNECTED: "Product removed from bundle",
  BUNDLE_PRICE: "Bundle price"
}
