export class StorageHelper {
  static setItem(key: string, item: any) {
    item = JSON.stringify(item);
    localStorage.setItem(key, item);
  }

  static getItem<T extends any>(key: string): T | null {
    const item = localStorage.getItem(key);
    if (!item) return null;
    try {
      return JSON.parse(item);
    } catch {
      return null;
    }
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  static clear() {
    localStorage.clear();
  }
}