import React, {FC, useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {ServiceVariableInterface} from "../../services/service/interface";
import {apiUpdateServiceVariable} from "../../api/services/api";
import {ProductsSelect} from "../../components/form/ProductsSelect";

export interface Props {
  serviceVariable: ServiceVariableInterface|null,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}

export const EditServiceVariableDialog: FC<Props> = ({serviceVariable, open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [name, setName] = useState(serviceVariable?.name ?? "");
  const [productId, setProductId] = useState(serviceVariable?.product_id ?? "");
  const [firstQuantityFree, setFirstQuantityFree] = useState(serviceVariable?.first_quantity_free ?? 0);
  const [type, setType] = useState(serviceVariable?.type ?? "");

  useEffect(() => {
    setName(serviceVariable?.name ?? "");
    setProductId(serviceVariable?.product_id ?? "");
    setFirstQuantityFree(serviceVariable?.first_quantity_free ?? 0);
    setType(serviceVariable?.type ?? "");
  }, [serviceVariable]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    apiUpdateServiceVariable({
      id: serviceVariable?.id ?? "",
      service_id: serviceVariable?.service_id ?? "",
      name,
      type,
      product_id: productId.length > 0 ? productId : undefined,
      first_quantity_free: firstQuantityFree,
    }).then(() => {
      toast.success(t("SERVICE_VARIABLE_EDITED"));
      handleClose();
      if (onSubmit) {
        onSubmit();
      }
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  const onChangeProduct = (productId: string | Array<string>) => {
    const val = Array.isArray(productId) ? productId[0] : productId;
    setProductId(val);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("EDIT_SERVICE_VARIABLE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("EDIT_SERVICE_VARIABLE_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <TextField label={t("NAME")} type={"text"} value={name} onChange={e => setName(e.target.value)} />
            <TextField label={t("TYPE")} type={"text"} value={type} onChange={e => setType(e.target.value)} />
            <TextField label={t("FIRST_QUANTITY_FREE")} type={"number"} value={firstQuantityFree} onChange={e => setFirstQuantityFree(parseInt(e.target.value))} />
            <ProductsSelect onChange={onChangeProduct} label={t("PRODUCT")} identifier={"product"} value={productId} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
