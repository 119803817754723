import React, {useEffect, useState} from 'react';
import {useAppContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {EditSettingDialog} from "./EditSettingDialog";
import {apiGetSettings} from "../../api/settings/api";
import {SettingInterface} from "../../services/setting/interface";
import EditIcon from '@mui/icons-material/Edit';
import {Box, CircularProgress, Divider, Paper, Typography} from "@mui/material";

export default function SystemSettingsScene() {
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [currentEditSetting, setCurrentEditSetting] = useState<SettingInterface|null>(null);
  const settings = useQuery(['settings'], apiGetSettings);
  const [openSettingEdit, setOpenSettingEdit] = useState(false);

  useEffect(() => {
    appContext?.setTitle(t("SYSTEM_SETTINGS"));
  }, [appContext, t]);

  const handleEdit = (row: SettingInterface) => {
    setCurrentEditSetting(row);
    setOpenSettingEdit(true);
  };

  return (
    <>
      <h1>
        {t("SYSTEM_SETTINGS")}
      </h1>
      <Paper>
        {settings.isLoading ? (
          <CircularProgress />
        ) : (
          <Box className={"paperContent"}>
            {settings.data?.items.map((row) => (
              <span key={row.key}>
                <Typography className={"systemSettingEntry"} variant={"body1"}>
                  <b>{t(row.key)}:</b>{row.value ? (row.encrypted ? "***" : row.value) : (<i>{t("NOT_SET")}</i>)}
                  <span className={"editIconButton"}>
                  <EditIcon fontSize={"small"} onClick={() => {handleEdit(row)}} />
                </span>
                </Typography>
                <Divider/>
              </span>
            ))}
          </Box>
        )}
      </Paper>
      <EditSettingDialog open={openSettingEdit} setOpen={setOpenSettingEdit} setting={currentEditSetting} onSubmit={() => settings.refetch()}/>
    </>
  );
}
