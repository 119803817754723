import React, {FC, useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {apiUpdateUser} from "../../api/users/api";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {UserInterface} from "../../services/user/interface";

export interface Props {
  user: UserInterface|null,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}

export const EditUserDialog: FC<Props> = ({user, open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [firstName, setFirstName] = useState<string>(user?.first_name ?? "");
  const [insertion, setInsertion] = useState<string>(user?.insertion ?? "");
  const [lastName, setLastName] = useState<string>(user?.last_name ?? "");

  useEffect(() => {
    setFirstName(user?.first_name ?? "");
    setInsertion(user?.insertion ?? "");
    setLastName(user?.last_name ?? "");
  }, [user]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    apiUpdateUser({
      id: user?.id ?? "",
      first_name: firstName ?? "",
      insertion,
      last_name: lastName ?? ""
    }).then(() => {
      toast.success(t("USER_EDITED"));
      handleClose();
      if (onSubmit) {
        onSubmit();
      }
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("EDIT_USER")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("EDIT_USER_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <TextField label={t("FIRST_NAME")} type={"text"} value={firstName} onChange={e => setFirstName(e.target.value)} />
            <TextField label={t("INSERTION")} type={"text"} value={insertion} onChange={e => setInsertion(e.target.value)} />
            <TextField label={t("LAST_NAME")} type={"text"} value={lastName} onChange={e => setLastName(e.target.value)} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
