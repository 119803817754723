import React, {FC, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {ProductsSelect} from "../../components/form/ProductsSelect";
import {ProductInterface} from "../../services/product/interface";
import {ProductBundleInterface} from "../../services/product_bundles/interface";
import {apiAttachProductToBundle} from "../../api/product_bundles/api";
import {attachProductToBundleParams} from "../../api/product_bundles/params";

export interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
  productBundleId?: string;
}
export const AttachProductToBundleDialog: FC<Props> = ({open, setOpen, onSubmit, productBundleId}) => {
  const {t} = useTranslation();
  const [productId, setProductId] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const resetForm = () => {
    setProductId("");
  };

  const handleSubmit = () => {
    if (!productBundleId) {
      return;
    }
    apiAttachProductToBundle({
      product_id: productId,
      product_bundle_id: productBundleId
    } as attachProductToBundleParams).then(() => {
      if (onSubmit) {
        onSubmit();
      }

      toast.success(t("PRODUCT_ADDED"));
      resetForm();
      handleClose();
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  const onSelectProduct = (row: ProductInterface) => {
    setProductId(row.id);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("ATTACH_PRODUCT")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("ATTACH_PRODUCT_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <ProductsSelect onChange={setProductId} label={t("PRODUCT")} identifier={"product"} value={productId} onSelect={onSelectProduct} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
