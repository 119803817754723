import {getAuthApiInstance} from "../api";
import {RequestCriteria, UrlHelper} from "../../helper/url";
import {getInvoiceResult, getInvoicesResult} from "./results";
import {createInvoiceParams, getInvoiceParams, getInvoicesParams} from "./params";

/**
 * Invoices
 */
export const apiGetInvoices = (params: getInvoicesParams): Promise<getInvoicesResult> => {
  let criteria: Array<RequestCriteria> = [];
  if (params.customer_id) {
    criteria.push({field: 'customer_id', comp: 'eq', value: params.customer_id});
  }

  if (params.paid && (params.paid === "yes" || params.paid === "no")) {
    criteria.push({field: 'paid_at', comp: params.paid === "no" ? 'is_null' : 'not_null'});
  }

  return getAuthApiInstance().get(`invoices${UrlHelper.parseSearchCriteria(criteria, params.page, params.limit, 'created_at', 'desc')}`).then((result) => {
    return result.data as getInvoicesResult;
  });
}

export const apiGetInvoice = (params: getInvoiceParams): Promise<getInvoiceResult> => {
  return getAuthApiInstance().get("invoices/" + params.id).then((result) => {
    return result.data as getInvoiceResult;
  });
}

export const apiCreateInvoice = (params: createInvoiceParams): Promise<getInvoiceResult> => {
  return getAuthApiInstance().put("invoices", params).then((result) => {
    return result.data as getInvoiceResult;
  });
}

export const apiDeleteInvoice = (params: getInvoiceParams): Promise<void> => {
  return getAuthApiInstance().delete("invoices/" + params.id);
}
