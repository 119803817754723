import {FC} from "react";
import {SelectInput} from "./SelectInput";
import {useTranslation} from "react-i18next";

export interface Props {
  onChange: (value: string) => void,
  value?: string,
  label: string,
  identifier: string,
}

export const DiscountTypeSelect: FC<Props> = ({onChange, value, label, identifier}) => {
  const { t } = useTranslation();
  const options = [
    {
      value: "percentage",
      label: t("PERCENTAGE")
    },
    {
      value: "fixed_price",
      label: t("FIXED_PRICE")
    },
    {
      value: "fixed_per_quantity",
      label: t("FIXED_PER_QUANTITY")
    },
  ];

  return (
    <SelectInput identifier={identifier} label={label} onChange={onChange} value={value} options={options}/>
  );
}
