export class UrlHelper {
  static parseSearchCriteria(
    filters: Array<RequestCriteria>,
    page?: number,
    limit?: number,
    orderBy?: string,
    orderByDirection?: string,
  ): string {
    let urlParts: Array<string> = [];
    filters.forEach((filter, index) => {
      let stringFilter = `filters[${index}][field]=${filter.field}`;
      if (filter.comp) {
        stringFilter += `&filters[${index}][comp]=${filter.comp}`;
      }

      if (filter.value) {
        if (Array.isArray(filter.value)) {
          filter.value.forEach((value) => {
            stringFilter += `&filters[${index}][value][]=${value}`;
          });
        } else {
          stringFilter += `&filters[${index}][value]=${filter.value}`;
        }
      }

      urlParts.push(stringFilter);
    });

    if (page && limit) {
      urlParts.push(`pagination[page]=${page}&pagination[limit]=${limit}`);
    }

    if (orderBy && orderByDirection) {
      urlParts.push(`sort[field]=${orderBy}&sort[direction]=${orderByDirection}`)
    }

    if (urlParts.length > 0) {
      return "?" + urlParts.join('&');
    }

    return "";
  }
}

export interface RequestCriteria {
  field: string,
  comp?: string,
  value?: string|Array<any>
}
