import {FC} from "react";
import {SelectInput} from "./SelectInput";
import {useTranslation} from "react-i18next";
import {ProductType} from "../../enums/productTypeEnum";

export interface Props {
  onChange: (value: ProductType) => void,
  value?: string,
  label: string,
  identifier: string,
}

export const ProductTypeSelect: FC<Props> = ({onChange, value, label, identifier}) => {
  const { t } = useTranslation();
  const options = [
    {
      value: ProductType.ACCOUNT,
      label: t("ACCOUNT")
    },
    {
      value: ProductType.MICRO_TRANSACTION,
      label: t("MICRO_TRANSACTION")
    },
    {
      value: ProductType.INTEGRATION,
      label: t("INTEGRATION")
    },
    {
      value: ProductType.SUPPORT_SERVICE,
      label: t("SUPPORT_SERVICE")
    },
  ];

  function handleChange(value: string) {
    onChange(value as ProductType);
  }

  return (
    <SelectInput identifier={identifier} label={label} onChange={handleChange} value={value} options={options}/>
  );
}
