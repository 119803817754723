import React, {FC, useState} from 'react';
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControlLabel,
  FormGroup, InputAdornment,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {apiCreateLedgerEntry} from "../../api/ledger_entries/api";
import {ProductsSelect} from "../../components/form/ProductsSelect";
import {CustomersSelect} from "../../components/form/CustomersSelect";
import {ProductInterface} from "../../services/product/interface";
import {createLedgerEntryParams} from "../../api/ledger_entries/params";
import {getCurrentMonth, getCurrentYear} from "../../helper/constants";
import {YearSelect} from "../../components/form/YearSelect";
import {MonthSelect} from "../../components/form/MonthSelect";

export interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}
export const CreateLedgerEntryDialog: FC<Props> = ({open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [customerId, setCustomerId] = useState("");
  const [productId, setProductId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [name, setName] = useState("");
  const [isDiscount, setIsDiscount] = useState(false);
  const [priceIsFinal, setPriceIsFinal] = useState(false);
  const [basePrice, setBasePrice] = useState(0);
  const [priceInclTax, setPriceInclTax] = useState(false);
  const [taxPercentage, setTaxPercentage] = useState(21);
  const [year, setYear] = useState(getCurrentYear() + "");
  const [month, setMonth] = useState(getCurrentMonth() + "");

  const handleClose = () => {
    setOpen(false);
  };

  const resetForm = () => {
    setCustomerId("");
    setProductId("");
    setQuantity(1);
    setName("");
    setIsDiscount(false);
    setPriceIsFinal(false);
    setBasePrice(0);
    setPriceInclTax(false);
    setTaxPercentage(21);
    setMonth(getCurrentMonth() + "");
    setYear(getCurrentYear() + "");
  };

  const handleSubmit = () => {
    apiCreateLedgerEntry({
      customer_id: customerId,
      product_id: productId,
      quantity,
      name,
      is_discount: isDiscount,
      price_is_final: priceIsFinal,
      base_price: basePrice,
      price_incl_tax: priceInclTax,
      tax_percentage: taxPercentage,
      year: year,
      month: month
    } as createLedgerEntryParams).then(() => {
      if (onSubmit) {
        onSubmit();
      }

      toast.success(t("LEDGER_ENTRY_CREATED"));
      resetForm();
      handleClose();
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  const onSelectProduct = (row: ProductInterface) => {
    setBasePrice(row.base_price ?? basePrice);
    setPriceInclTax(row.price_incl_tax);
    setTaxPercentage(row.tax_percentage);
    setIsDiscount(false);
    setPriceIsFinal(false);
    setName(row.name);
  };

  const selectMonth = (month: string | Array<string>) => {
    const val = Array.isArray(month) ? month[0] : month;
    setMonth(val);
  };

  const selectYear = (year: string | Array<string>) => {
    const val = Array.isArray(year) ? year[0] : year;
    setYear(val);
  };

  const onChangeProduct = (productId: string | Array<string>) => {
    const val = Array.isArray(productId) ? productId[0] : productId;
    setProductId(val);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("CREATE_LEDGER_ENTRY")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("CREATE_LEDGER_ENTRY_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <CustomersSelect onChange={setCustomerId} label={t("CUSTOMER")} identifier={"customer"} value={customerId} />
            <ProductsSelect onChange={onChangeProduct} label={t("PRODUCT")} identifier={"product"} value={productId} onSelect={onSelectProduct} optional />
            <MonthSelect value={month + ""} onChange={selectMonth} identifier={"month"}/>
            <YearSelect value={year + ""} onChange={selectYear} identifier={"year"}/>
            <TextField label={t("QUANTITY")} type={"number"} value={quantity} onChange={e => setQuantity(parseInt(e.target.value))} />
            <TextField label={t("NAME")} type={"text"} value={name} onChange={e => setName(e.target.value)} />
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
              label={t("PRICE")} type={"number"} value={basePrice} onChange={e => setBasePrice(parseFloat(e.target.value))} />
            <TextField InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} label={t("TAX_PERCENTAGE")} type={"number"} value={taxPercentage} onChange={e => setTaxPercentage(parseFloat(e.target.value))} />
            <FormControlLabel control={<Checkbox
              checked={priceInclTax}
              onChange={e => setPriceInclTax(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t("PRICE_INCL_TAX")} />
            <FormControlLabel control={<Checkbox
              checked={priceIsFinal}
              onChange={e => setPriceIsFinal(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t("PRICE_IS_FINAL")} />
            <FormControlLabel control={<Checkbox
              checked={isDiscount}
              onChange={e => setIsDiscount(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t("IS_DISCOUNT")} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
