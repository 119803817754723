import axios, {AxiosRequestConfig} from "axios"
import {StorageHelper} from "../services/storage/helper";
import {ACCESS_TOKEN_STORAGE_KEY} from "../services/authentication/constant";

const defaultConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_URL + "/api/v1/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  responseType: "json"
};

export const apiInstance = axios.create(defaultConfig);

export const getAuthApiInstance = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + "/api/v1/",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + StorageHelper.getItem(ACCESS_TOKEN_STORAGE_KEY)
    },
    responseType: "json"
  });
}
