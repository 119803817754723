import React, {useEffect, useState} from 'react';
import {useAppContext, useConfirmDialogContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import AddCircle from '@mui/icons-material/AddCircle';
import {
  IconButton,
  MenuItem
} from '@mui/material';
import moment from "moment/moment";
import {MenuButton} from "../../components/layout/MenuButton";
import {LoadingRow} from "../../components/layout/LoadingRow";
import {ErrorHelper} from "../../helper/errors";
import {toast} from "react-toastify";
import {ServiceVariableInterface} from "../../services/service/interface";
import {apiDeleteServiceVariable, apiGetService, apiGetServiceVariables} from "../../api/services/api";
import {useParams} from "react-router-dom";
import {CreateServiceVariableDialog} from "./CreateServiceVariableDialog";
import {EditServiceVariableDialog} from "./EditServiceVariableDialog";
import {Table, TableCell, TableRow, TableHead, TableBody} from "../../components/layout/Table";

export default function ServiceVariableScene() {
  let { serviceId } = useParams();
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [currentEditServiceVariable, setCurrentEditServiceVariable] = useState<ServiceVariableInterface|null>(null);
  const serviceVariables = useQuery(['service_variables'], () => {return apiGetServiceVariables(serviceId as string);});
  const service = useQuery(['service'], () => {return apiGetService({id: serviceId ?? ""});});
  const [openServiceVariableCreate, setOpenServiceVariableCreate] = useState(false);
  const [openServiceVariableEdit, setOpenServiceVariableEdit] = useState(false);
  const confirmDialog = useConfirmDialogContext();

  useEffect(() => {
    appContext?.setTitle(t("SERVICE_VARIABLES"));
  }, [appContext, t]);

  const handleEdit = (row: ServiceVariableInterface) => {
    setCurrentEditServiceVariable(row);
    setOpenServiceVariableEdit(true);
  };

  const handleDelete = (row: ServiceVariableInterface) => {
    confirmDialog?.confirm(
      t("DELETE_SERVICE_VARIABLE_QUESTION"),
      t("DELETE_SERVICE_VARIABLE_QUESTION_EXPLANATION"),
      () => () => {
        apiDeleteServiceVariable({id: row.id, service_id: row.service_id}).then(() => {
          toast.success(t("SERVICE_VARIABLE_DELETED"));
          serviceVariables.refetch();
        }).catch((result) => {
          ErrorHelper.toastLaraFail(result);
        })
      }
    );
  };

  return (
    <>
      <h1>
        {t("SERVICE_VARIABLES_FOR") + " " + service.data?.name}
        <IconButton aria-label="create" color="success" onClick={() => {setOpenServiceVariableCreate(true)}}>
          <AddCircle />
        </IconButton>
      </h1>
      <Table sx={{ minWidth: 650 }} aria-label="service table">
        <TableHead>
          <TableRow>
            <TableCell>{t("NAME")}</TableCell>
            <TableCell align="right">{t("TYPE")}</TableCell>
            <TableCell align="right">{t("FIRST_QUANTITY_FREE")}</TableCell>
            <TableCell align="right">{t("PRODUCT")}</TableCell>
            <TableCell align="right">{t("CREATED_AT")}</TableCell>
            <TableCell align="right">{t("UPDATED_AT")}</TableCell>
            <TableCell align="right">{t("ACTIONS")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {serviceVariables.isLoading ? (
            <LoadingRow colspan={7} />
          ) : (serviceVariables.data?.items.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="right">{row.type}</TableCell>
              <TableCell align="right">{row.first_quantity_free}</TableCell>
              <TableCell align="right">{row.product ? row.product?.name : t("NA")}</TableCell>
              <TableCell align="right">{moment.unix(row.created_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">{moment.unix(row.updated_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">
                <MenuButton>
                  <MenuItem key={'edit-' + row.id} onClick={() => handleEdit(row)}>
                    {t("EDIT")}
                  </MenuItem>
                  <MenuItem key={'delete-' + row.id} onClick={() => handleDelete(row)}>
                    {t("DELETE")}
                  </MenuItem>
                </MenuButton>
              </TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
      <CreateServiceVariableDialog open={openServiceVariableCreate} setOpen={setOpenServiceVariableCreate} onSubmit={() => serviceVariables.refetch()} serviceId={serviceId as string}/>
      <EditServiceVariableDialog open={openServiceVariableEdit} setOpen={setOpenServiceVariableEdit} serviceVariable={currentEditServiceVariable} onSubmit={() => serviceVariables.refetch()}/>
    </>
  );
}
