import moment from "moment";

export const monthTranslationKeys = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER"
];

export const getCurrentMonth = () => {
  return moment().month();
};

export const getCurrentYear = () => {
  return moment().year();
};

export const getYears = (): Array<number>  => {
  const min  = 2017
  const max = getCurrentYear() + 1;;

  const years = [];

  for(let i=min;i<max;i++) {
    years.push(i);
  }

  return years;
};
