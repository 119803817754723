import React, {useEffect, useState} from 'react';
import {useAppContext, useConfirmDialogContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {
  IconButton, MenuItem,
  Typography
} from "@mui/material";
import {LoadingCard} from "../../components/layout/LoadingCard";
import {CardArea} from "../../components/layout/CardArea";
import {CardWrapper} from "../../components/layout/Card";
import {apiGetInvoice} from "../../api/invoices/api";
import moment from "moment/moment";
import {DividerRow} from "../../components/layout/DividerRow";
import {useParams} from "react-router-dom";
import AddCircle from "@mui/icons-material/AddCircle";
import {LoadingRow} from "../../components/layout/LoadingRow";
import {MenuButton} from "../../components/layout/MenuButton";
import {apiDeletePayment, apiGetPayments} from "../../api/payments/api";
import {getPaymentResult} from "../../api/payments/results";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {CreatePaymentDialog} from "./CreatePaymentDialog";
import {EditPaymentDialog} from "./EditPaymentDialog";
import {Table, TableBody, TableCell, TableHead, TableRow} from "../../components/layout/Table";

export default function InvoiceDetailScene() {
  let { invoiceId } = useParams();
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentEdit, setCurrentEdit] = useState<getPaymentResult|null>(null);
  const payments = useQuery(['payments'], () => {return apiGetPayments({invoice_id: invoiceId as string});});
  const invoice = useQuery(['invoice'], () => {return apiGetInvoice({id: invoiceId as string});});
  const confirmDialog = useConfirmDialogContext();

  useEffect(() => {
    appContext?.setTitle(t("INVOICE"));
  }, [appContext, t]);

  const handleEdit = (row: getPaymentResult) => {
    setCurrentEdit(row);
    setOpenEdit(true);
  };

  const handleDelete = (row: getPaymentResult) => {
    confirmDialog?.confirm(
      t("DELETE_PAYMENT_QUESTION"),
      t("DELETE_PAYMENT_QUESTION_EXPLANATION"),
      () => () => {
        apiDeletePayment({id: row.id}).then(() => {
          toast.success(t("PAYMENT_DELETED"));
          payments.refetch();
        }).catch((result) => {
          ErrorHelper.toastLaraFail(result);
        })
      }
    );
  };

  const currencyFormatter = new Intl.NumberFormat(
      "nl-NL",
      {style: "currency", currency: "EUR"}
  );

  return (
    <>
      <h1>
        {t("INVOICE")} {invoice.data?.invoice_number} {t("FOR")} {invoice.data?.customer_name} ({invoice.data?.customer_kvk})
      </h1>
      <CardArea>
        {invoice.isLoading ? (
          <LoadingCard />
        ) : (invoice.data ? (
          <CardWrapper size={"fullCard"} key={invoice.data.id}>
            <Table className={"noBorderTable compact"}>
              <TableBody>
                <TableRow>
                  <TableCell/>
                  <TableCell colSpan={3}><b>{t("PROVIDER")}</b></TableCell>
                  <TableCell><b>{t("CUSTOMER")}</b></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{t("NAME")}</b></TableCell>
                  <TableCell>{invoice.data.provider_name}</TableCell>
                  <TableCell/>
                  <TableCell><b>{t("NAME")}</b></TableCell>
                  <TableCell>{invoice.data.customer_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{t("KVK")}</b></TableCell>
                  <TableCell>{invoice.data.provider_kvk}</TableCell>
                  <TableCell/>
                  <TableCell><b>{t("KVK")}</b></TableCell>
                  <TableCell>{invoice.data.customer_kvk}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{t("TAX_NUMBER")}</b></TableCell>
                  <TableCell>{invoice.data.provider_tax_number}</TableCell>
                  <TableCell/>
                  <TableCell><b>{t("TAX_NUMBER")}</b></TableCell>
                  <TableCell>{invoice.data.customer_tax_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{t("ADDRESS")}</b></TableCell>
                  <TableCell>
                    {invoice.data.provider_address} {invoice.data.provider_house_number}
                    <br/>
                    {invoice.data.provider_postal_code} {invoice.data.provider_city}
                    <br/>
                    {invoice.data.provider_country_code}
                  </TableCell>
                  <TableCell/>
                  <TableCell><b>{t("ADDRESS")}</b></TableCell>
                  <TableCell>
                    {invoice.data.customer_address} {invoice.data.customer_house_number}
                    <br/>
                    {invoice.data.customer_postal_code} {invoice.data.customer_city}
                    <br/>
                    {invoice.data.customer_country_code}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{t("PHONE_NUMBER")}</b></TableCell>
                  <TableCell>{invoice.data.provider_phone_number}</TableCell>
                  <TableCell/>
                  <TableCell><b>{t("PHONE_NUMBER")}</b></TableCell>
                  <TableCell>{invoice.data.customer_phone_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{t("EMAIL")}</b></TableCell>
                  <TableCell>{invoice.data.provider_email}</TableCell>
                  <TableCell/>
                  <TableCell><b>{t("EMAIL")}</b></TableCell>
                  <TableCell>{invoice.data.customer_email}</TableCell>
                </TableRow>
                <DividerRow />
                <TableRow>
                  <TableCell colSpan={5}><Typography variant={"h6"} sx={{marginTop: 0, marginBottom: "6px"}}>{t("INVOICE_LINES")}</Typography></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{width: "50%"}}><b>{t("NAME")}</b></TableCell>
                  <TableCell/>
                  <TableCell><b>{t("QUANTITY")}</b></TableCell>
                  <TableCell colSpan={2} sx={{width: "33%"}}><b>{t("PRICE")}</b></TableCell>
                </TableRow>
                {
                  (invoice.data.ledger_entries && invoice.data.ledger_entries.length > 0 ? invoice.data.ledger_entries.map((cell) => (
                    <TableRow key={cell.id}>
                      <TableCell colSpan={2}>{cell.name} {cell.product_bundle  && "(" + cell.product_bundle?.name + ")"}</TableCell>
                      <TableCell/>
                      <TableCell>{cell.quantity}</TableCell>
                      <TableCell colSpan={2}>{currencyFormatter.format(cell.price_is_final ? cell.base_price : (cell.base_price * (cell.quantity ?? 1)))} ({cell.price_incl_tax ? t("INCL") : t("EXCL")} {cell.tax_percentage}% {t("TAX")})</TableCell>
                    </TableRow>
                  )) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        {t("NO_ITEMS_FOUND")}
                      </TableCell>
                    </TableRow>
                  ))
                }
                <DividerRow/>
                <TableRow>
                  <TableCell colSpan={3}/>
                  <TableCell><b>{t("TOTAL_PRICE")}</b></TableCell>
                  <TableCell>{currencyFormatter.format(invoice.data.total_price)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}/>
                  <TableCell><b>{t("DISCOUNT")}</b></TableCell>
                  <TableCell>-{currencyFormatter.format(invoice.data.discount_price)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}/>
                  <TableCell><b>{t("TAX_AMOUNT")}</b></TableCell>
                  <TableCell>{currencyFormatter.format(invoice.data.tax_amount)}</TableCell>
                </TableRow>
                <DividerRow/>
                <TableRow>
                  <TableCell colSpan={3}/>
                  <TableCell><b>{t("FINAL_PRICE")}</b></TableCell>
                  <TableCell>{currencyFormatter.format(invoice.data.final_price)} ({t("EX_VAT")})</TableCell>
                </TableRow>
                <DividerRow />
                <TableRow>
                  <TableCell><b>{t("CREATED_AT")}</b></TableCell>
                  <TableCell>{moment.unix(invoice.data.created_at).format("DD-MM-YYYY HH:mm")}</TableCell>
                  <TableCell><b>{t("UPDATED_AT")}</b></TableCell>
                  <TableCell>{moment.unix(invoice.data.updated_at).format("DD-MM-YYYY HH:mm")}</TableCell>
                  <TableCell><b>{t("PAID")}</b></TableCell>
                  <TableCell>{invoice.data.paid_at ? t("YES") + " " + t("ON") + " " + moment.unix(invoice.data.paid_at).format("DD-MM-YYYY HH:mm") : t("NO")}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardWrapper>
        ) : (
          <CardWrapper className={"textCenter"} size={"fullCard"}>
            <Typography gutterBottom variant={"caption"}>
              {t("NO_ITEMS_FOUND")}
            </Typography>
          </CardWrapper>
        ))}
      </CardArea>

      <h2>
        {t("PAYMENTS")}
        <IconButton aria-label="create" color="success" onClick={() => {setOpenCreate(true)}}>
          <AddCircle />
        </IconButton>
      </h2>
      <Table sx={{ minWidth: 650 }} aria-label="payment table">
        <TableHead>
          <TableRow>
            <TableCell>{t("NAME")}</TableCell>
            <TableCell align="right">{t("DESCRIPTION")}</TableCell>
            <TableCell align="right">{t("AMOUNT")}</TableCell>
            <TableCell align="right">{t("PAID_AT")}</TableCell>
            <TableCell align="right">{t("CREATED_AT")}</TableCell>
            <TableCell align="right">{t("UPDATED_AT")}</TableCell>
            <TableCell align="right">{t("ACTIONS")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.isLoading ? (
            <LoadingRow colspan={7} />
          ) : (payments.data?.items.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="right">{row.description}</TableCell>
              <TableCell align="right">{currencyFormatter.format(row.amount)}</TableCell>
              <TableCell align="right">{moment.unix(row.paid_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">{moment.unix(row.created_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">{moment.unix(row.updated_at).format("DD-MM-YYYY HH:mm")}</TableCell>
              <TableCell align="right">
                <MenuButton>
                  <MenuItem key={'edit-' + row.id} onClick={() => handleEdit(row)}>
                    {t("EDIT")}
                  </MenuItem>
                  <MenuItem key={'delete-' + row.id} onClick={() => handleDelete(row)}>
                    {t("DELETE")}
                  </MenuItem>
                </MenuButton>
              </TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
      <CreatePaymentDialog open={openCreate} setOpen={setOpenCreate} invoiceId={invoiceId as string} onSubmit={() => payments.refetch()}/>
      <EditPaymentDialog open={openEdit} setOpen={setOpenEdit} payment={currentEdit} onSubmit={() => payments.refetch()}/>
    </>
  );
}
