import * as React from "react";
import {ListItemButtonProps} from "@mui/material/ListItemButton/ListItemButton";
import ListItemButton from "@mui/material/ListItemButton";
import {Link} from "react-router-dom";

export interface ListItemLinkProps extends ListItemButtonProps {
  to: string;
}

export const ListItemLink: React.FC<any> = (props: ListItemLinkProps) => {
  const { to, ...restProps } = props;

  return (
    <Link to={to}>
      <ListItemButton {...restProps}>{props.children}</ListItemButton>
    </Link>
  );
}