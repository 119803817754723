import {FC} from "react";
import {SelectInput} from "./SelectInput";
import {useTranslation} from "react-i18next";

export interface Props {
  onChange: (value: string) => void,
  value?: string,
  label: string,
  identifier: string,
}

export const PaidSelect: FC<Props> = ({onChange, value, label, identifier}) => {
  const { t } = useTranslation();
  const options = [
    {
      value: "both",
      label: t("BOTH")
    },
    {
      value: "yes",
      label: t("YES")
    },
    {
      value: "no",
      label: t("NO")
    },
  ];

  return (
    <SelectInput identifier={identifier} label={label} onChange={onChange} value={value} options={options}/>
  );
}
