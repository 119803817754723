import * as React from "react";
import {TableHead as TableHeadBase, TableHeadProps} from "@mui/material";

interface IProps extends TableHeadProps{

}

export const TableHead: React.FC<any> = (props: React.PropsWithChildren<IProps>) => {
  return (
    <TableHeadBase className={"customTableHead"} {...props}>
      {props.children}
    </TableHeadBase>
  );
}
