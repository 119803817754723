import React, {FC, useEffect, useState} from 'react';
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControlLabel,
  FormGroup,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {CustomerServiceInterface} from "../../services/customer/interface";
import {apiConnectCustomerService} from "../../api/customers/api";
import {ServicesSelect} from "../../components/form/ServicesSelect";
import {DiscountTypeSelect} from "../../components/form/DiscountTypeSelect";

export interface Props {
  customerService: CustomerServiceInterface|null,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
  customerId: string,
}

export const ConnectCustomerServiceDialog: FC<Props> = ({customerId, customerService, open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [serviceId, setServiceId] = useState(customerService?.service_id ?? "");
  const [externalReference, setExternalReference] = useState(customerService?.external_reference ?? "");
  const [quantity, setQuantity] = useState(customerService?.quantity ?? 1);
  const [discount, setDiscount] = useState(customerService?.discount ?? 0);
  const [discountInclTax, setDiscountInclTax] = useState(customerService?.discount_incl_tax ?? false);
  const [discountType, setDiscountType] = useState(customerService?.discount_type ?? "fixed_price");

  useEffect(() => {
    setServiceId(customerService?.service_id ?? "");
    setExternalReference(customerService?.external_reference ?? "");
    setQuantity(customerService?.quantity ?? 1);
    setDiscount(customerService?.discount ?? 0);
    setDiscountInclTax(customerService?.discount_incl_tax ?? false);
    setDiscountType(customerService?.discount_type ?? "fixed_price");
  }, [customerService]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    apiConnectCustomerService({
      customer_id: customerId,
      service_id: serviceId,
      external_reference: externalReference,
      quantity,
      discount,
      discount_incl_tax: discountInclTax,
      discount_type: discountType,
    }).then(() => {
      if (customerService) {
        toast.success(t("CUSTOMER_SERVICE_EDITED"));
      } else {
        toast.success(t("CUSTOMER_SERVICE_CONNECTED"));
      }

      handleClose();
      if (onSubmit) {
        onSubmit();
      }
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{ customerService ? t("UPDATE_CUSTOMER_SERVICE") : t("CONNECT_CUSTOMER_SERVICE") }</DialogTitle>
      <DialogContent>
        <DialogContentText>{ customerService ? t("UPDATE_CUSTOMER_SERVICE_EXPLANATION") : t("CONNECT_CUSTOMER_SERVICE_EXPLANATION") }</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            {!customerService && (
              <ServicesSelect onChange={setServiceId} label={t("SERVICE")} identifier={"service_select"} value={serviceId} />
            )}
            <TextField label={t("EXTERNAL_REFERENCE")} type={"text"} value={externalReference} onChange={e => setExternalReference(e.target.value)} />
            <TextField label={t("QUANTITY")} type={"number"} value={quantity} onChange={e => setQuantity(parseInt(e.target.value))} />
            <TextField label={t("DISCOUNT")} type={"text"} value={discount} onChange={e => setDiscount(parseFloat(e.target.value))} />
            <DiscountTypeSelect onChange={setDiscountType} label={t("DISCOUNT_TYPE")} identifier={"discount_type"} value={discountType} />
            <FormControlLabel control={<Checkbox
              checked={discountInclTax}
              onChange={e => setDiscountInclTax(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t("DISCOUNT_INCL_TAX")} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
