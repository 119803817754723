import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { nl } from "./translations/nl/default";
import { en } from "./translations/en/default";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      nl: { translation: nl },
      en: { translation: en }
    },
    lng: "nl",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;