import * as React from "react";
import {TableCell, TableRow} from "@mui/material";

export const DividerRow: React.FC<any> = () => {
  return (
      <>
        <TableRow className={"dividerRow"}><TableCell>&nbsp;</TableCell></TableRow>
        <TableRow><TableCell>&nbsp;</TableCell></TableRow>
      </>
  );
}