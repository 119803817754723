import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import {ListItemLink} from "./ListItemLink";
import {ConfirmDialogContextProvider, useAppContext} from "../../services/app/context";
import {UserHelper} from "../../helper/user";
import {apiMe} from "../../api/user/api";
import {useQuery} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";
import {AccountCircle} from "@mui/icons-material";
import {Menu, MenuItem} from "@mui/material";
import {MenuItemLink} from "./MenuItemLink";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const drawerWidth = 240;

interface Props {
  title: string;
}

export const AppLayout: React.FC<any> = (props: React.PropsWithChildren<Props>) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const appContext = useAppContext();
  const { t } = useTranslation();
  const me = useQuery(['me'], apiMe);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getName = () => {
    if (!me.isSuccess) {
      return 'Loading...';
    }

    return me.data?.first_name + (me.data?.insertion ? " " + me.data?.insertion : "") + " " + me.data?.last_name;
  };

  const drawer = (
    <div>
      <Toolbar className={"mainToolBar"}>
        <Typography flex={1} variant={"h6"} sx={{fontSize: "1rem"}}>{getName()}</Typography>
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItemLink onClick={handleClose} to={"/settings"}>{t("SETTINGS")}</MenuItemLink>
            <MenuItem onClick={UserHelper.logout}>{t("LOGOUT")}</MenuItem>
          </Menu>
        </div>
      </Toolbar>
      <Divider />
      <List disablePadding>
        <ListItem key={"overview"} disablePadding>
          <ListItemLink to={"/"}>
            <ListItemText primary={t("MONTH_OVERVIEW")} />
          </ListItemLink>
        </ListItem>
        <Divider/>
        <ListItem key={"invoices"} disablePadding>
          <ListItemLink to={"/invoices"}>
            <ListItemText primary={t("INVOICES")} />
          </ListItemLink>
        </ListItem>
        <Divider/>
        <ListItem key={"ledger"} disablePadding>
          <ListItemLink to={"/ledger"}>
            <ListItemText primary={t("LEDGER")} />
          </ListItemLink>
        </ListItem>
        <Divider/>
        <ListItem key={"customers"} disablePadding>
          <ListItemLink to={"/customers"}>
            <ListItemText primary={t("CUSTOMERS")} />
          </ListItemLink>
        </ListItem>
        <Divider/>
        <ListItem key={"products"} disablePadding>
          <ListItemLink to={"/products"}>
            <ListItemText primary={t("PRODUCTS")} />
          </ListItemLink>
        </ListItem>
        <Divider/>
        <ListItem key={"product_bundles"} disablePadding>
          <ListItemLink to={"/product_bundles"}>
            <ListItemText primary={t("PRODUCT_BUNDLES")} />
          </ListItemLink>
        </ListItem>
        <Divider/>
        <ListItem key={"services"} disablePadding>
          <ListItemLink to={"/services"}>
            <ListItemText primary={t("SERVICES")} />
          </ListItemLink>
        </ListItem>
        <Divider/>
        <ListItem key={"users"} disablePadding>
          <ListItemLink to={"/users"}>
            <ListItemText primary={t("USERS")} />
          </ListItemLink>
        </ListItem>
        <Divider/>
        <ListItem key={"system_settings"} disablePadding>
          <ListItemLink to={"/system_settings"}>
            <ListItemText primary={t("SYSTEM_SETTINGS")} />
          </ListItemLink>
        </ListItem>
        <Divider/>
      </List>
    </div>
  );

  return (
    <ConfirmDialogContextProvider>
      <Box sx={{ display: 'flex' }}>
        <AppBar
          className={"mainToolBar"}
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {appContext?.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar/>
          {props.children}
        </Box>
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </ConfirmDialogContextProvider>
  );
}
