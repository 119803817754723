import {FC} from "react";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";

export interface DefaultSelectInputProps {
  onChange: (value: string) => void,
  value?: string,
  label?: string,
  identifier: string,
  filled?: boolean;
  small?: boolean;
}

export interface Props extends DefaultSelectInputProps {
  options: Array<{label: string, value: string}>
}

export const SelectInput: FC<Props> = ({onChange, value, label, identifier, options, filled, small}) => {
  const {t} = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl size={small ? "small" : "medium"} fullWidth variant={filled ? "filled" : "standard"}>
      <InputLabel id={identifier + "-select-label"}>{label}</InputLabel>
      <Select
        labelId={identifier + "-select-label"}
        id={identifier + "-select"}
        value={value}
        label={label ? label : t("SELECT")}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
