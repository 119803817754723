import {FC} from "react";
import {DefaultSelectInputProps, SelectInput} from "./SelectInput";
import {useTranslation} from "react-i18next";
import {getYears} from "../../helper/constants";

export interface Props extends DefaultSelectInputProps {
}

export const YearSelect: FC<Props> = ({onChange, value, label, identifier}) => {
  const { t } = useTranslation();

  function getOptions(): Array<{value: string, label: string}> {
    const options:Array<{value: string, label: string}> = [];

    getYears().map((value, index) => {
      options.push({value: value + "", label: value + ""})
    })

    return options;
  }
  return (
    <SelectInput small identifier={identifier} label={label ? label : t("YEAR")} onChange={onChange} value={value} options={getOptions()}/>
  );
}
