import React from 'react';
import './App.scss';
import {Outlet} from "react-router-dom";
import {AppLayout} from "../components/layout/AppLayout";

function Dashboard() {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
}

export default Dashboard;
