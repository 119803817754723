import {FC} from "react";
import {DefaultSelectInputProps, SelectInput} from "./SelectInput";
import {useTranslation} from "react-i18next";
import {monthTranslationKeys} from "../../helper/constants";

export interface Props extends DefaultSelectInputProps {

}

export const MonthSelect: FC<Props> = ({onChange, value, label, identifier}) => {
  const { t } = useTranslation();

  function getOptions(): Array<{value: string, label: string}> {
    const options:Array<{value: string, label: string}> = [];
    monthTranslationKeys.map((value, index) => {
      options.push({value: index + "", label: t(value) })
    })

    return options;
  }
  return (
    <SelectInput small identifier={identifier} label={label ? label : t("MONTH")} onChange={onChange} value={value} options={getOptions()}/>
  );
}
