import * as React from "react";
import {Card, CardContent} from "@mui/material";

interface Props {
  size: string;
  className?: string;
}

export const CardWrapper: React.FC<any> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={"cardWrapper " + props.size}>
      <Card className={"card"}>
        <CardContent className={props.className ?? ""}>
          {props.children}
        </CardContent>
      </Card>
    </div>
  );
}