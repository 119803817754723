import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import "./i18n";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./AppRouter";
import {AuthenticationProvider} from "./services/authentication/provider";
import {AppProvider} from "./services/app/provider";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <AppProvider>
        <QueryClientProvider client={queryClient}>
            <AuthenticationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <AppRouter />
                </LocalizationProvider>
            </AuthenticationProvider>
        </QueryClientProvider>
      </AppProvider>
    </React.StrictMode>
  </BrowserRouter>
);
