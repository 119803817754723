import * as React from "react";
import {CustomersSelect} from "../form/CustomersSelect";
import {useTranslation} from "react-i18next";
import {ProductsSelect} from "../form/ProductsSelect";
import {TextField} from "@mui/material";
import {PaidSelect} from "../form/PaidSelect";

export interface Props {
  customer?: string;
  setCustomer?: (value: string) => void;
  product?: string;
  setProduct?: (value: string) => void;
  name?: string;
  setName?: (value: string) => void;
  paid?: string;
  setPaid?: (value: string) => void;
  setFilter: (key: string, value?: string) => void;
}

export const FilterBar: React.FC<any> = (props: React.PropsWithChildren<Props>) => {
  const [t] = useTranslation();

  function handleProductChange(value: string | Array<string>) {
    const val = Array.isArray(value) ? value[0] : value;
    props.setFilter('product_id', val); props.setProduct && props.setProduct(val);
  }

  return (
    <div className={"filterBar"}>
      {typeof props.customer !== "undefined" && (
        <CustomersSelect value={props.customer} onChange={(value: string) => {props.setFilter('customer_id', value); props.setCustomer && props.setCustomer(value);}} label={t("CUSTOMER")} identifier={"customer"} optional/>
      )}
      {typeof props.product !== "undefined" && (
        <ProductsSelect value={props.product} onChange={handleProductChange} label={t("PRODUCT")} identifier={"product"} optional/>
      )}
      {typeof props.name !== "undefined" && (
        <TextField label={t("NAME")} type={"text"} value={props.name} onChange={e => {props.setFilter('name', e.target.value); props.setName && props.setName(e.target.value);}} />
      )}
      {typeof props.paid !== "undefined" && (
        <PaidSelect value={props.paid} onChange={(value: string) => {props.setFilter('paid', value); props.setPaid && props.setPaid(value);}} label={t("PAID")} identifier={"paid"}/>
      )}
    </div>
  );
}
