import React, {FC, useEffect, useState} from 'react';
import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControlLabel,
  FormGroup, InputAdornment,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {ProductBundleInterface} from "../../services/product_bundles/interface";
import {apiUpdateProductBundle} from "../../api/product_bundles/api";

export interface Props {
  productBundle: ProductBundleInterface|null,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}

export const EditProductBundleDialog: FC<Props> = ({productBundle, open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [name, setName] = useState(productBundle?.name ?? "");
  const [price, setPrice] = useState(productBundle?.base_price ?? null);
  const [priceInclTax, setPriceInclTax] = useState(productBundle?.price_incl_tax ?? false);
  const [taxPercentage, setTaxPercentage] = useState(productBundle?.tax_percentage ?? 21);
  const [products, setProducts] = useState(productBundle?.products);

  useEffect(() => {
    setName(productBundle?.name ?? "");
    setPrice(productBundle?.base_price ?? null);
    setPriceInclTax(productBundle?.price_incl_tax ?? false);
    setTaxPercentage(productBundle?.tax_percentage ?? 21);
  }, [productBundle]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    apiUpdateProductBundle({
      id: productBundle?.id ?? "",
      name,
      base_price: price ?? undefined,
      price_incl_tax: priceInclTax,
      tax_percentage: taxPercentage,
    }).then(() => {
      toast.success(t("PRODUCT_BUNDLE_EDITED"));
      handleClose();
      if (onSubmit) {
        onSubmit();
      }
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  function onSelectProducts(products: []) {
    console.log(products);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("EDIT_PRODUCT_BUNDLE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("EDIT_PRODUCT_BUNDLE_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <TextField label={t("NAME")} type={"text"} value={name} onChange={e => setName(e.target.value)} />
            <TextField InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
            }} label={t("PRICE")} type={"number"} value={price} onChange={e => setPrice(parseFloat(e.target.value))} />
            <TextField InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} label={t("TAX_PERCENTAGE")} type={"number"} value={taxPercentage} onChange={e => setTaxPercentage(parseFloat(e.target.value))} />
            <FormControlLabel control={<Checkbox
              checked={priceInclTax}
              onChange={e => setPriceInclTax(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t("PRICE_INCL_TAX")} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
