import {StorageHelper} from "../services/storage/helper";
import {ACCESS_TOKEN_STORAGE_KEY} from "../services/authentication/constant";
import {apiLogoutUser} from "../api/user/api";
import {Buffer} from 'buffer';

export class UserHelper {
  static logout(): Promise<void> {
    const token = StorageHelper.getItem(ACCESS_TOKEN_STORAGE_KEY) as string;
    let tokenExp = JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
    return apiLogoutUser({token_id: tokenExp.jti}).then(() => {
      StorageHelper.clear();
      window.location.reload();
    }).catch(() => {
      StorageHelper.clear();
      window.location.reload();
    });
  }
}