import * as React from "react";
import {IconButton} from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";

interface Props {
  onClick: () => void;
}

export const CreateButton: React.FC<any> = (props: React.PropsWithChildren<Props>) => {

  return (
    <IconButton aria-label="create" color="success" onClick={props.onClick}>
      <AddCircle />
    </IconButton>
)
  ;
}
