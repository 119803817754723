import * as React from "react";
import {TableRow as TableRowBase, TableRowProps} from "@mui/material";

interface IProps extends TableRowProps {

}

export const TableRow: React.FC<any> = (props: React.PropsWithChildren<IProps>) => {
  return (
    <TableRowBase hover {...props}>
      {props.children}
    </TableRowBase>
  );
}
