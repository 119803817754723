import {getAuthApiInstance} from "../api";
import {
  getCustomerResult,
  getCustomerServiceResult,
  getCustomerServicesResult,
  getCustomersResult,
  getCustomersResultPaginated, getCustomerUsageResultPaginated
} from "./results";
import {
  configureCustomerServiceParams,
  connectCustomerServiceParams,
  createCustomerParams,
  deleteCustomerServiceParams,
  getCustomerParams, GetCustomerUsageParams,
  updateCustomerParams
} from "./params";
import {RequestCriteria, UrlHelper} from "../../helper/url";

/**
 * Customers
 */
export const apiGetCustomers = (): Promise<getCustomersResult> => {
  return getAuthApiInstance().get("customers?sort[field]=name&sort[direction]=asc").then((result) => {
    return result.data as getCustomersResult;
  });
}

export const apiGetCustomersPaginated = (page: number): Promise<getCustomersResultPaginated> => {
  return getAuthApiInstance().get("customers?pagination[limit]=50&pagination[page]=" + page + "&sort[field]=name&sort[direction]=asc").then((result) => {
    return result.data as getCustomersResultPaginated;
  });
}

export const apiGetCustomer = (params: getCustomerParams): Promise<getCustomerResult> => {
  return getAuthApiInstance().get("customers/" + params.id).then((result) => {
    return result.data as getCustomerResult;
  });
}

export const apiCreateCustomer = (params: createCustomerParams): Promise<getCustomerResult> => {
  return getAuthApiInstance().put("customers", params).then((result) => {
    return result.data as getCustomerResult;
  });
}

export const apiUpdateCustomer = (params: updateCustomerParams): Promise<getCustomerResult> => {
  const {id, ...restParams} = params;
  return getAuthApiInstance().post("customers/" + id, restParams).then((result) => {
    return result.data as getCustomerResult;
  });
}

export const apiDeleteCustomer = (params: getCustomerParams): Promise<void> => {
  return getAuthApiInstance().delete("customers/" + params.id);
}

export const apiGetCustomersUsagePaginated = (params: GetCustomerUsageParams): Promise<getCustomerUsageResultPaginated> => {
  let criteria: Array<RequestCriteria> = [];

  if (params.customer_id) {
    criteria.push({field: 'customer_id', comp: 'eq', value: params.customer_id});
  }

  if (params.name) {
    criteria.push({field: 'name', comp: 'like', value: params.name});
  }

  return getAuthApiInstance().get(`customers/usage?${UrlHelper.parseSearchCriteria(criteria, params.page, params.limit)}&month=${params.month}&year=${params.year}`).then((result) => {
    return result.data as getCustomerUsageResultPaginated;
  });
}

/**
 * Customer services
 */

export const apiGetCustomerServices = (customerId: string): Promise<getCustomerServicesResult> => {
  return getAuthApiInstance().get("customers/" + customerId + "/services").then((result) => {
    return result.data as getCustomerServicesResult;
  });
}

export const apiConnectCustomerService = (params: connectCustomerServiceParams): Promise<getCustomerServiceResult> => {
  const {customer_id, service_id, ...restParams} = params;
  return getAuthApiInstance().put("customers/" + params.customer_id + "/services/" + params.service_id, restParams);
}

export const apiDisconnectCustomerService = (params: deleteCustomerServiceParams): Promise<void> => {
  return getAuthApiInstance().delete("customers/" + params.customer_id + "/services/" + params.service_id);
}

export const apiConfigureCustomerService = (params: configureCustomerServiceParams): Promise<getCustomerServiceResult> => {
  const {customer_id, service_id, ...restParams} = params;
  return getAuthApiInstance().post("customers/" + params.customer_id + "/services/" + params.service_id, restParams);
}
