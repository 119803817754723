import React, {useEffect, useState} from 'react';
import {useAppContext, useConfirmDialogContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {IconButton, MenuItem, Typography} from "@mui/material";
import {LoadingCard} from "../../components/layout/LoadingCard";
import {CardArea} from "../../components/layout/CardArea";
import {CardWrapper} from "../../components/layout/Card";
import {CreateInvoiceDialog} from "./CreateInvoiceDialog";
import {MenuButton} from "../../components/layout/MenuButton";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {apiDeleteInvoice, apiGetInvoices} from "../../api/invoices/api";
import {getInvoiceResult} from "../../api/invoices/results";
import {MenuItemLink} from "../../components/layout/MenuItemLink";
import {Link} from "react-router-dom";
import moment from "moment";
import {Pager} from "../../components/layout/Pager";
import {CreateButton} from "../../components/layout/CreateButton";
import {FilterCard} from "../../components/layout/FilterCard";

export default function InvoiceScene() {
  const appContext = useAppContext();
  const {t} = useTranslation();
  const invoices = useQuery(['invoices'], () => {return apiGetInvoices(filters);});
  const [openCreate, setOpenCreate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({paid: "no", limit: 50, page: 1});
  const confirmDialog = useConfirmDialogContext();
  const [customer, setCustomer] = useState("");
  const [paid, setPaid] = useState("no");

  useEffect(() => {
    appContext?.setTitle(t("INVOICES"));
  }, [appContext, t]);

  const handleDelete = (row: getInvoiceResult) => {
    confirmDialog?.confirm(
      t("DELETE_INVOICE_QUESTION"),
      t("DELETE_INVOICE_QUESTION_EXPLANATION"),
      () => () => {
        apiDeleteInvoice({id: row.id}).then(() => {
          toast.success(t("INVOICE_DELETED"));
          invoices.refetch();
        }).catch((result) => {
          ErrorHelper.toastLaraFail(result);
        })
      }
    );
  };

  const setFilter = async (key: string, value?: string|number) => {
    if (value === "") {
      value = undefined;
    }

    await setFilters(
      Object.assign(Object.assign({}, filters), {[key]: value})
    );

    invoices.refetch();
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setFilter('page', page);
  };

  return (
    <>
      <h1>
        {t("INVOICE")}
        <CreateButton onClick={() => {setOpenCreate(true)}} />
      </h1>
      <CardArea>
        <FilterCard setFilter={setFilter} customer={customer} setCustomer={setCustomer} paid={paid} setPaid={setPaid}/>
        {invoices.isLoading ? (
          <LoadingCard />
        ) : (invoices.data?.items && invoices.data.items.length > 0 ? invoices.data?.items.map((row) => (
          <CardWrapper size={"fullCard"} key={row.id}>
            <div className={"cardHeader"} >
              <Link to={"/invoices/" + row.id}>
                <Typography className={"cardTitle"} gutterBottom variant={"h6"}>
                  {t("INVOICE")} {row.invoice_number} {t("FOR")} {row.customer_name} ({row.customer_kvk}) ({t("PAID")}: {row.paid_at ? t("YES") + " " + t("ON") + " " + moment.unix(row.paid_at).format("DD-MM-YYYY HH:mm") : t("NO")})
                </Typography>
              </Link>
              <MenuButton>
                <MenuItemLink key={"detail-" + row.id} to={"/invoices/" + row.id}>{t("DETAILS")}</MenuItemLink>
                <MenuItem key={'delete-' + row.id} onClick={() => handleDelete(row)}>
                  {t("DELETE")}
                </MenuItem>
              </MenuButton>
            </div>
          </CardWrapper>
        )) : (
          <CardWrapper className={"textCenter"} size={"fullCard"}>
            <Typography gutterBottom variant={"caption"}>
              {t("NO_ITEMS_FOUND")}
            </Typography>
          </CardWrapper>
        ))}
      </CardArea>
      {!invoices.isLoading && (
        <Pager currentPage={currentPage} totalPages={invoices.data?.metadata.total_pages} setCurrentPage={handlePageChange} totalItems={invoices.data?.metadata.total} itemsPerPage={invoices.data?.metadata.count}/>
      )}
      <CreateInvoiceDialog open={openCreate} setOpen={setOpenCreate} onSubmit={() => {invoices.refetch()}} />
    </>
  );
}
