import {apiInstance, getAuthApiInstance} from "../api";
import {genericSuccessResult, loginUserResult, meResult} from "./results";
import {loginUserParams, logoutUserParams, refreshUserParams, updatePasswordParams} from "./params";
import {StorageHelper} from "../../services/storage/helper";

export const apiLoginUser = (params: loginUserParams): Promise<loginUserResult> => {
  return apiInstance.postForm("oauth/token", {
    username: params.username,
    password: params.password,
    grant_type: "password",
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET
  }).then((result) => {
    return {
      access_token: result.data.access_token,
      refresh_token: result.data.refresh_token,
      expires_in: result.data.expires_in,
    } as loginUserResult;
  });
}

export const apiRefreshUser = (params: refreshUserParams): Promise<loginUserResult> => {
  return apiInstance.postForm("oauth/token", {
    refresh_token: params.refresh_token,
    grant_type: "refresh_token",
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET
  }).then((result) => {
    return {
      access_token: result.data.access_token,
      refresh_token: result.data.refresh_token,
      expires_in: result.data.expires_in,
    } as loginUserResult;
  });
}

export const apiLogoutUser = (params: logoutUserParams): Promise<void> => {
  return getAuthApiInstance().delete("oauth/tokens/" + params.token_id);
}

export const apiMe = (): Promise<void | meResult> => {
  return getAuthApiInstance().get('me').then((result) => {
    return result.data as meResult;
  }).catch((result) => {
    if (result.response.status === 401) {
      StorageHelper.clear();
      window.location.reload();
    }
  });
};

export const apiUpdatePassword = (params: updatePasswordParams): Promise<genericSuccessResult> => {
  return getAuthApiInstance().post("password", params).then((result) => {
    return result.data as genericSuccessResult;
  });
}