import React, {FC, useEffect, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";
import {CustomerInterface} from "../../services/customer/interface";
import {apiUpdateCustomer} from "../../api/customers/api";
import {CountrySelect} from "../../components/form/CountrySelect";

export interface Props {
  customer: CustomerInterface|null,
  open: boolean,
  setOpen: (open: boolean) => void,
  onSubmit?: () => void,
}

export const EditCustomerDialog: FC<Props> = ({customer, open, setOpen, onSubmit}) => {
  const {t} = useTranslation();
  const [name, setName] = useState(customer?.name ?? "");
  const [address, setAddress] = useState(customer?.address ?? "");
  const [houseNumber, setHouseNumber] = useState(customer?.house_number ?? "");
  const [postalCode, setPostalCode] = useState(customer?.postal_code ?? "");
  const [city, setCity] = useState(customer?.city ?? "");
  const [countryCode, setCountryCode] = useState(customer?.country_code ?? "");
  const [kvk, setKvk] = useState(customer?.kvk ?? "");
  const [taxNumber, setTaxNumber] = useState(customer?.tax_number ?? "");
  const [phoneNumber, setPhoneNumber] = useState(customer?.phone_number ?? "");
  const [email, setEmail] = useState(customer?.email ?? "");

  useEffect(() => {
    setName(customer?.name ?? "");
    setAddress(customer?.address ?? "");
    setHouseNumber(customer?.house_number ?? "");
    setPostalCode(customer?.postal_code ?? "");
    setCity(customer?.city ?? "");
    setCountryCode(customer?.country_code ?? "");
    setKvk(customer?.kvk ?? "");
    setTaxNumber(customer?.tax_number ?? "");
    setPhoneNumber(customer?.phone_number ?? "");
    setEmail(customer?.email ?? "");
  }, [customer]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    apiUpdateCustomer({
      id: customer?.id ?? "",
      name,
      address,
      house_number: houseNumber,
      postal_code: postalCode,
      city,
      country_code: countryCode,
      kvk,
      tax_number: taxNumber,
      phone_number: phoneNumber,
      email,
    }).then(() => {
      toast.success(t("CUSTOMER_EDITED"));
      handleClose();
      if (onSubmit) {
        onSubmit();
      }
    }).catch((result) => {
      ErrorHelper.toastLaraFail(result);
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("EDIT_CUSTOMER")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("EDIT_CUSTOMER_EXPLANATION")}</DialogContentText>
        <form onSubmit={handleSubmit} className={"settingsForm"}>
          <FormGroup>
            <TextField label={t("NAME")} type={"text"} value={name} onChange={e => setName(e.target.value)} />
            <TextField label={t("ADDRESS")} type={"text"} value={address} onChange={e => setAddress(e.target.value)} />
            <TextField label={t("HOUSE_NUMBER")} type={"text"} value={houseNumber} onChange={e => setHouseNumber(e.target.value)} />
            <TextField label={t("POSTAL_CODE")} type={"text"} value={postalCode} onChange={e => setPostalCode(e.target.value)} />
            <TextField label={t("CITY")} type={"text"} value={city} onChange={e => setCity(e.target.value)} />
            <CountrySelect label={t("COUNTRY_CODE")} identifier={"country_code"} value={countryCode} onChange={setCountryCode} />
            <TextField label={t("KVK")} type={"text"} value={kvk} onChange={e => setKvk(e.target.value)} />
            <TextField label={t("TAX_NUMBER")} type={"text"} value={taxNumber} onChange={e => setTaxNumber(e.target.value)} />
            <TextField label={t("PHONE_NUMBER")} type={"text"} value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
            <TextField label={t("EMAIL")} type={"text"} value={email} onChange={e => setEmail(e.target.value)} />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={"submit"} variant={"contained"} type={"submit"} onClick={handleSubmit}>{t("SUBMIT")}</Button>
        <Button onClick={handleClose}>{t("CANCEL")}</Button>
      </DialogActions>
    </Dialog>
  );
}
