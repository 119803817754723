import * as React from "react";
import {Grid as GridBase, GridProps} from "@mui/material";

interface IProps extends GridProps {

}

export const EuroSign: React.FC<any> = (props: React.PropsWithChildren<IProps>) => {
  return (
    <>{"\u20AC"}</>
  );
}
