import React, {useEffect, useState} from 'react';
import {useAppContext, useConfirmDialogContext} from "../../services/app/context";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import AddCircle from '@mui/icons-material/AddCircle';
import {
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {MenuButton} from "../../components/layout/MenuButton";
import {LoadingRow} from "../../components/layout/LoadingRow";
import {useParams} from "react-router-dom";
import {ProductBundleInterface} from "../../services/product_bundles/interface";
import {apiDetachProductFromBundle, apiGetProductBundle} from "../../api/product_bundles/api";
import {apiGetProducts} from "../../api/products/api";
import {ProductInterface} from "../../services/product/interface";
import {AttachProductToBundleDialog} from "./AttachProductToBundleDialog";
import {toast} from "react-toastify";
import {ErrorHelper} from "../../helper/errors";

export default function ProductBundleProductScene() {
  let { productBundleId } = useParams();
  const appContext = useAppContext();
  const {t} = useTranslation();
  const [currentBundle, setCurrentBundle] = useState<ProductBundleInterface|null>(null);
  const [openProductSelect, setOpenProductSelect] = useState<boolean>(false);
  const productBundle = useQuery(['product_bundle'], () => {return apiGetProductBundle({id: productBundleId as string});});
  const confirmDialog = useConfirmDialogContext();

  useEffect(() => {
    appContext?.setTitle(t("PRODUCTS"));
  }, [appContext, t, currentBundle]);

  const handleDelete = (row: ProductInterface) => {
    confirmDialog?.confirm(
      t("DISCONNECT_PRODUCT_QUESTION"),
      t("DISCONNECT_PRODUCT_EXPLANATION"),
      () => () => {
        if (!productBundleId) {
          return;
        }
        apiDetachProductFromBundle({product_bundle_id: productBundleId, product_id: row.id}).then(() => {
          toast.success(t("PRODUCT_DISCONNECTED"));
          productBundle.refetch();
        }).catch((result) => {
          ErrorHelper.toastLaraFail(result);
        })
      }
    );
  };

  return (
    <>
      <h1>
        {t("PRODUCTS_FOR") + " " + productBundle.data?.name}
        <IconButton aria-label="create" color="success" onClick={() => {setOpenProductSelect(true);}}>
          <AddCircle />
        </IconButton>
      </h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="product_bundle_products table">
          <TableHead>
            <TableRow>
              <TableCell>{t("NAME")}</TableCell>
              <TableCell align="right">{t("ACTIONS")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productBundle.isLoading ? (
              <LoadingRow colspan={7} />
            ) : (productBundle.data?.products?.map((product) => (
              <TableRow
                key={product.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{product.name}</TableCell>
                <TableCell align="right">
                  <MenuButton>
                    <MenuItem key={'delete-' + product.id} onClick={() => handleDelete(product)}>
                      {t("DELETE")}
                    </MenuItem>
                  </MenuButton>
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
      <AttachProductToBundleDialog open={openProductSelect} setOpen={setOpenProductSelect} productBundleId={productBundleId} onSubmit={() => productBundle.refetch()}/>
    </>
  );
}
