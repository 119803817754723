import React, {useState} from 'react';
import {Box, Button, Card, CardContent, Container, TextField, Typography} from "@mui/material";
import '../login/Login.scss';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {apiValidateUser} from "../../api/users/api";

export default function ValidateUserScene() {
  let { token } = useParams();
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    apiValidateUser({token: token ?? "", password}).then(() => {
      navigate('/', {replace: true});
    }).catch((error) => {
      if (error.response.data.message) {
        setError(error.response.data.message);
      }

      if (error.response?.data && typeof error.response.data === "object") {
        let responseData = error.response.data;
        let errors = new Array<string>();
        Object.keys(responseData).map((item: string) => {
          responseData[item].map((entry: string) => {
            errors.push(entry as string);

            return entry;
          });

          return item;
        });

        setError(errors.join("\n"));
      }
    });
  }

  return(
    <Container className={"loginContainer"} sx={{display: "flex"}}>
      <Box className={"loginBox"}>
        <Card>
          <CardContent>
            <Typography className={"loginTitle"} gutterBottom variant={"h4"}>
              {t("VALIDATE_TITLE")}
            </Typography>
            <form onSubmit={handleSubmit} className={"loginForm"}>
              {error && (
                <Typography className={"error"} gutterBottom variant={"caption"}>
                  {t(error)}
                </Typography>
              )}
              <TextField label={t("PASSWORD")} type={"password"} onChange={e => setPassword(e.target.value)} />
              <Button className={"loginSubmit"} variant={"contained"} type={"submit"}>{t("SUBMIT")}</Button>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}